import React from "react";

import useField from "../../lib/useField";
import * as UI from "./ui";

const CheckboxField = ({id, label, ...rest}) => {
  const { value, skeleton, setValue, touch } = useField(id, "checked");

  return (
    <UI.FieldLayout>
      <UI.Checkbox
        id={id}
        label={label}
        onChange={setValue}
        name={id}
        onBlur={touch}
        checked={value}
        disabled={skeleton}
        data-intercom-target={`${id}*`}
        {...rest}
      />
      <UI.CheckboxLabel htmlFor={id}>{label}</UI.CheckboxLabel>
    </UI.FieldLayout>
  );
};

export default CheckboxField;
