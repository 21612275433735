import styled from "styled-components";

export const List = styled.ul`
  padding: 12px 5px;
  list-style-type: disc;
  margin-left: 20px;
`

export const Item = styled.li`
  font-size: 0.875em;
`;

export const Resource = styled.strong`
  font-weight: 700;
`;
