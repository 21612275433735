import React from "react";
import { isNil, pipe, pick, evolve } from "ramda";

import Form from "./Form";
import * as UI from "./ui";

const evolveImageType = value => isNil(value) ? value : pipe(
  pick(['cloudName', 'publicId', 'version']),
  JSON.stringify,
)(value);

const buildInitialInputObject = pipe(
  pick(['id', 'logo','website']),
  evolve({
    logo: evolveImageType,
  })
);

const EditForm = ({ onSelect, onDone, item }) => {
  if(isNil(item)) {
    return null;
  }

  return (
    <UI.EditorLayout>
      <Form
        initialInputObject={buildInitialInputObject(item)}
        onCancel={() => onSelect(null)}
        onSubmit={onDone}
      />
    </UI.EditorLayout>
  );
}

export default EditForm;
