import React from "react";

import { NEW_ITEM, useMasterDetailContext } from "../../../lib/MasterDetail";
import ModeSwitch from "../../../lib/ModeSwitch";

import New from "./New";
import Show from "./Show";
import Edit from "./Edit";

const Detail = ({ item: teamMember, onSelect, onCancel, showCancel }) => {
  const { nonprofit, setLocked } = useMasterDetailContext();

  if(!teamMember) {
    return null;
  }

  const props = { teamMember, nonprofit, onSelect, onCancel, showCancel, setLocked };

  if(teamMember === NEW_ITEM) {
    return <New {...props} />;
  }

  return (
    <ModeSwitch initialMode="show">
      <Show mode="show" {...props} />
      <Edit mode="edit" {...props} />
    </ModeSwitch>
  );
}

export default Detail;
