import React from "react";
import SignPost from "../design/SignPost";
import Button from "../design/Button";

import useLogout from "../../lib/useLogout";

const SessionPrompt = ({ accountApplication }) => {
  const logout = useLogout();

  const handleLogout = () => {
    logout({ returnPath: window.location.pathname })
  };

  return (
    <SignPost heading={`Logout and Sign In As ${accountApplication.email} To Complete Your Nonprofit Account`}>
      <Button type="submit" onClick={handleLogout}>Log out</Button>
    </SignPost>
  )
};

export default SessionPrompt;
