import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 12px;
`;

export const Header = styled.header`
  display: flex;
  padding: 0 6px;
`;

export const HeaderActions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  > :not(:last-child) {
    margin-right: 18px;
  }
`;

export const Body = styled.div`
  flex: 1 1 0;
  overflow-y: auto;
  background-color: #ffffff;
  padding: 12px;
  border-radius: 6px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 6px;
`;

export const FooterActions = styled.div`
  display: flex;
  gap: 12px;
`;

export const Heading = styled.div`
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: 1fr auto;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export const HeadingAside = styled.div`
  text-align: right;

  @media (max-width: 1024px) {  
    text-align: unset;
  }
`;

export const Name = styled.div`
  font-size: 1.75em;
  font-weight: bold;
  color: #333333;
  font-family: tondo, sans-serif;
`;

export const Date = styled.div`
  font-size: 0.875em;
`;

export const Field = styled.div`
  margin-bottom: 24px;
  padding: 0 6px;
`;

export const Label = styled.div`
  color: #666666;
  font-size: 0.75em;
`

export const ContactEmail = styled.a`
  margin-right: 12px;
`;

export const Actions= styled.div`
  margin: 12px -6px;
`;

export const Value = styled.div`
  color: #333333;
  padding-left: 6px;
`;

export const Insights = styled.div`
  margin-bottom: 12px;

  & > div {
    height: 100%;
  }
`;
