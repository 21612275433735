import React from "react";
import { path } from "ramda";
import { useQuery } from "@apollo/client";

import { isBlank } from "../../util/presence";
import ExportButton from "../ExportButton";
import { sweepstakesCalendarQuery } from "./gql";
import Agenda from "./Agenda";
import * as UI from "./ui";

const SweepstakesMarketingPage = ({ sweepstakes }) => {
  const { data, loading } = useQuery(sweepstakesCalendarQuery, {
    variables: {
      id: sweepstakes.id,
    },
  });

  const events = path(["sweepstakes", "calendar", "events"], data)

  if (loading || isBlank(events)) {
    return null;
  }

  return (
    <UI.PageLayout>
      <Agenda sweepstakes={sweepstakes} events={events} />
      <UI.ExportSection>
        <ExportButton url={`/exports/${sweepstakes.id}/calendar.ics`} resource="sweepstakes">Export Calendar</ExportButton>
      </UI.ExportSection>
    </UI.PageLayout>
  )
};

export default SweepstakesMarketingPage;
