import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

import Foyer from "../Foyer";
import SignPost from "../design/SignPost";
import Button from "../design/Button";

const Root = () => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  if(isLoading) {
    return null;
  }

  if(isAuthenticated) {
    return <Foyer />
  }

  return (
    <React.Fragment>
      <SignPost heading="Sign In to Your Nonprofit Dashboard">
        <Button type="submit" onClick={loginWithRedirect}>Sign In</Button>
      </SignPost>
      <SignPost heading="Need a TapKat nonprofit account?">
        <Button size="small" as={Link} to="/application">Sign Up Here</Button>
      </SignPost>
    </React.Fragment>
  );
}

export default Root;
