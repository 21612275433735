import styled from "styled-components";

export const Layout = styled.div`
  padding: 24px;
`;

export const Title = styled.h1`
  font-size: 1.25em;
`;

export const Heading = styled.h2`
  font-size: 1em;
  margin: 0;
`;

export const Paragraph = styled.p`
  font-size: 0.875em;
  margin: 0 0 24px 0;
`;

export const List = styled.ul`
  margin: 6px 0;
  list-style-type: none;
  padding-left: 12px;
  font-size: 0.875em;
`;

export const ListItem = styled.li`
`;

export const Table = styled.table`
  margin: 6px 12px;
`;

export const TH = styled.th`
  color: #333333;
  font-size: 0.75em;
  display: inline-block;
  padding: 3px 12px;
  cursor: pointer;
  border-radius: 6px;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 1);
  margin-bottom: 3px;
`;

export const TD = styled.td`
  padding: 0;
  padding-left: 6px;
`;
