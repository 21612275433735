import styled from "styled-components";

export const Layout = styled.div`
  height: 100%;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(6, 1fr);

  @media screen and (max-width: 660px) {
    grid-template-columns: 1fr;
    & > *:nth-child(1) { grid-column: span 1; }
    & > *:nth-child(2) { grid-column: span 1; }
    & > *:nth-child(3) { display: none; }
    & > *:nth-child(4) { display: none; }
  }

  @media screen and (max-width: 780px) {
    & > *:nth-child(1) { grid-column: span 3; }
    & > *:nth-child(2) { grid-column: span 3; }
    & > *:nth-child(3) { grid-column: span 6; }
    & > *:nth-child(4) { display: none; }
  }

  @media screen and (min-width: 781px) {
    & > *:nth-child(1) { grid-column: span 3; }
    & > *:nth-child(2) { grid-column: span 3; }
    & > *:nth-child(3) { grid-column: span 4; }
    & > *:nth-child(4) { grid-column: span 2; }
  }
`;

export const Controls = styled.div`
  display: flex;
  gap: 12px;

  & > *:last-child {
    margin-left: auto;
  }
`;
