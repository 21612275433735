import React from "react";
import { Routes, Route, useParams } from "react-router-dom";

import TeamPage from "../TeamPage";
import InvoicesPage from "../InvoicesPage";
import EmailPreferencesPage from "../EmailPreferencesPage";
import Header from "./Header";
import * as UI from "./ui";

const NonprofitPages = () => {
  const { nonprofitId } = useParams();

  return (
    <UI.Layout>
      <Header nonprofitId={nonprofitId} />
      <UI.Content>
        <Routes>
          <Route index element={<TeamPage />} />
          <Route path="invoices" element={<InvoicesPage />} />
          <Route path="/preferences" element={<EmailPreferencesPage />} />
        </Routes>
      </UI.Content>
    </UI.Layout>
  );
}

export default NonprofitPages;
