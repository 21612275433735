import React, { useEffect } from "react";

import { ScheduleGuard } from "../../../lib/schedule";
import Button from "../../design/Button";
import EditForm from "./EditForm";
import * as UI from "./ui";


const Edit = ({ flashContest, sweepstakes, setMode, setLocked }) => {
  useEffect(() => {
    setLocked(true);
    return () => { setLocked(false) };
  }, [ setLocked ]);

  return (
    <UI.Layout>
      <UI.Header>
        <Button size="small" onClick={() => setMode("show")}>❮ Cancel</Button>
      </UI.Header>
      <UI.Body>
        <UI.Heading>
          <UI.Name>Edit Flash Contest</UI.Name>
        </UI.Heading>
        <ScheduleGuard enabledPeriods={["review", "open", "closing", "grace", "closed"]}>
          <EditForm flashContest={flashContest} sweepstakes={sweepstakes} setMode={setMode} />
        </ScheduleGuard>
      </UI.Body>
    </UI.Layout>
  );
};

export default Edit;
