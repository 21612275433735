import React from "react";
import styled from "styled-components";

const Header = styled.header`
  display: flex;
`;

const Heading = styled.h2`
  font-family: tondo, sans-serif;
  font-size: 1.5em;
  margin: 0 0 24px;
  color: #333333;

  @media screen and (max-width: 768px) {
    font-weight: bold;
    font-size: 1.125em;
    margin: 0 0 12px;
  }
`;

const Addons = styled.div`
  margin-left: 12px;
`;

const SectionHeader = ({ heading, children }) => (
  <Header>
    <Heading>{heading}</Heading>
    {children && <Addons>{children}</Addons>}
  </Header>
);

export default SectionHeader;
