import React from "react";

import ResultsSection from "./ResultsSection";
import * as UI from "./ui";

const SearchResults = ({ results, loading, error, visible }) => {
  if(loading || error || !visible) {
    return null;
  }

  return (
    <UI.Results>
      <ResultsSection title="Sweepstakes" results={results.sweepstakes} />
      <ResultsSection title="Nonprofits" results={results.nonprofits} />
    </UI.Results>
  );
}

export default SearchResults;

