import React from "react";
import { useParams } from "react-router-dom";

import { withAccessControl } from "../../lib/permissions";
import TeamMemberList from "../TeamMemberList";

const TeamPage = () => {
  const { nonprofitId } = useParams();

  return <TeamMemberList nonprofitId={nonprofitId} />;
}

export default withAccessControl({ resource: "team" })(TeamPage);
