import React from "react";

import Item from "./Item";
import * as UI from "./ui";

const Agenda = ({ sweepstakes, events }) => {
  return (
    <UI.Agenda>
      <div>
        {events.map((event, i) => <Item key={`AgendaItem-${i}`} sweepstakes={sweepstakes} event={event} />)}
      </div>
    </UI.Agenda>
  )
};

export default Agenda;
