import React, { useEffect, useRef } from "react";

import Form from "../forms/Form";
import ColorField from "../forms/ColorField";
import RadioField from "../forms/RadioField";
import CheckboxField from "../forms/CheckboxField";
import TextField from "../forms/TextField";
import Button from "../design/Button";

import * as UI from "./ui";

const DISPLAY_MODE_OPTIONS = [
  { id: 'light', label: "Dark text on light background" },
  { id: 'dark', label: "Light text on dark background" },
];

const PluginConfigurationForm = ({ nonprofitColor, configuration, setConfiguration, pluginUrl, onComplete }) => {
  const pluginRef = useRef(null);
  const pluginPreviewUrl = `${pluginUrl}&preview=true`;

  useEffect(() => {
    if (pluginRef.current) {
      pluginRef.current.src = pluginPreviewUrl;
    }
  }, [pluginPreviewUrl]);

  return (
    <UI.PluginConfigurationLayout>
      <UI.PluginControls>
        <Form onSubmit={onComplete} onChange={setConfiguration} initialInputObject={configuration}>
          <RadioField id="displayMode" label="Your Website Colors" options={DISPLAY_MODE_OPTIONS} />
          <ColorField id="primaryColor" colors={[nonprofitColor]} label="Primary Color" />
          <CheckboxField id="facebook" label="We use a Meta (Facebook) Pixel" />
          <CheckboxField id="googleAnalytics" label="We use Google Analytics" />
          <CheckboxField id="googleAds" label="We use Google Ads" />
          <TextField id="googleAdsCampaign" disabled={!configuration.googleAds} label="Google Ads Campaign ID" />
          <Button type="submit">View Code</Button>
        </Form>
      </UI.PluginControls>
      <UI.PluginPreview displayMode={configuration.displayMode}>
        <iframe
          title="preview"
          ref={pluginRef}
          src={pluginPreviewUrl}
          style={{ border: "none", minHeight: "500px", minWidth: "320px", width: "100%" }}
          frameBorder="0"
        />
      </UI.PluginPreview>
    </UI.PluginConfigurationLayout>
  );
};

export default PluginConfigurationForm;
