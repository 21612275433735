import { gql } from "@apollo/client";

export const getAccountApplicationQuery = gql`
  query GetAccountApplication($id: ID!) {
    accountApplication(id: $id) {
      id
      email
      requiresSession
      nonprofitLegalName
      phone
      billingEmail
      contactEmail
      address {
        line1
        line2
        city
        state
        postalCode
      }
    }
  }
`;

export const applyForAccountMutation = gql`
  mutation ApplyForAccount($attributes: AccountApplicationInput!) {
    applyForAccount(attributes: $attributes) {
      accountApplication {
        id
      }
      error {
        code
        message
      }
    }
  }
`;

export const createAccountMutation = gql`
  mutation CreateAccount($attributes: AccountInput!) {
    createAccount(attributes: $attributes) {
      nonprofit {
        id
      }
      error {
        code
        message
      }
    }
  }
`;
