import React, { useCallback, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { Context as SnackbarContext } from "../../lib/Snackbar";

import * as UI from "./ui";

const CopyWinnerPresentationUrl = ({ sweepstakes }) => {
  const { addMessage } = useContext(SnackbarContext);
  const { winnerPresentationUrl } = sweepstakes;

  const handleCopy = useCallback(() => {
    addMessage({ type: "success", message: "Copied to clipboard!" });
  }, [addMessage]);

  return (
    <CopyToClipboard text={winnerPresentationUrl} onCopy={handleCopy}>
      <UI.CopyPresentationUrl>
        <FontAwesomeIcon icon={faVideo} />
        Copy Winner Presentation URL
      </UI.CopyPresentationUrl>
    </CopyToClipboard>
  )
};

export default CopyWinnerPresentationUrl;
