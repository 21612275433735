import React, { useState } from "react";

import Button from "../../design/Button";
import AccessControlledButton from "../../../lib/AccessControlledButton";
import RemoveTeamMemberButton from "./RemoveTeamMemberButton";
import ResendInvitationButton from "./ResendInvitationButton";
import RoleDescription from "../../RoleDescription";
import * as UI from "./ui";

const Show = ({ teamMember, showCancel, onCancel, setMode }) => {
  const [ removingTeamMember, setRemovingTeamMember ] = useState(false);

  return (
    <UI.Layout>
      <UI.Header>
        { showCancel && (
          <Button size="small" onClick={onCancel}>❮ Close</Button>
        )}
        <UI.HeaderActions>
          <AccessControlledButton disabled={removingTeamMember} size="small" onClick={() => setMode("edit")} resource="team" permission="U">
            Edit Team Member
          </AccessControlledButton>
        </UI.HeaderActions>
      </UI.Header>

      <UI.Body>
         <UI.Heading>
          <UI.Name>{teamMember.name}</UI.Name>
        </UI.Heading>

        <UI.Field>
          <UI.Label>Email</UI.Label>
          <UI.Value>{teamMember.email}</UI.Value>
        </UI.Field>

        <UI.Field>
          <UI.Label>Role</UI.Label>
          <UI.Value>{teamMember.role.name}</UI.Value>
        </UI.Field>

        <UI.Field>
          <UI.Label>Permissions</UI.Label>
          <UI.Value>
            <RoleDescription subject={teamMember.name} role={teamMember.role} />
          </UI.Value>
        </UI.Field>
      </UI.Body>

      <UI.Footer>
        <UI.FooterActions>
          {teamMember.invitationStatus === "EXPIRED" && (
            <ResendInvitationButton teamMember={teamMember} />
          )}
          <RemoveTeamMemberButton teamMember={teamMember} setLoading={setRemovingTeamMember} />
        </UI.FooterActions>
      </UI.Footer>
    </UI.Layout>
  );
}

export default Show;
