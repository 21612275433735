import React, { useContext } from "react";

import Formation from "../../../forms/Form";
import TextField from "../../../forms/TextField";
import ImageUploadField from "../../../forms/ImageUploadField";
import Button from "../../../design/Button";
import { Context } from "./";

const logoPreview = {
  aspectRatio: "5:3",
  crop: "pad",
  width: "180",
  style: {
    maxWidth: '220px',
    width: '100%',
  },
};

const Form = ({ initialInputObject, onCancel, onSubmit, loading }) => {
  const { sweepstakes } = useContext(Context);

  return (
    <Formation initialInputObject={initialInputObject} onSubmit={onSubmit}>
      <ImageUploadField
        id="logo"
        label="Logo"
        owner={sweepstakes.id}
        directory="sponsors"
        previewProps={logoPreview}
      />
      <TextField id="website" label="Website" />
      <Button size="small" onClick={onCancel}>Cancel</Button>
      <Button size="small" type="submit" disabled={loading}>Done</Button>
    </Formation>
  );
}

export default Form;
