import React, { useCallback, useContext, useState } from "react";
import { pathOr } from "ramda";
import { useMutation } from "@apollo/client";
import { faRedo, faCheckDouble } from "@fortawesome/free-solid-svg-icons";

import { Context as SnackbarContext } from "../../lib/Snackbar";
import { confirmWinnerMutation, redrawWinnerMutation } from "./gql";
import WinnerCard from "../WinnerCard";
import Button from "../design/Button";

import * as UI from "./ui";

const ConfirmWinnerButton = ({ sweepstakes, next }) => {
  const { addMessage } = useContext(SnackbarContext);
  const [confirmWinner, { loading }] = useMutation(confirmWinnerMutation, {
    variables: { sweepstakesId: sweepstakes.id }
  });

  const confirm = useCallback(() => {
    if(!window.confirm("Are you sure you want to confirm & post this winner? This cannot be undone.")) {
      return;
    }

    confirmWinner()
      .then(({ data, error }) => {
        const mutationError = pathOr(null, ["confirmWinner", "error"], data);

        if (error || mutationError !== null) {
          addMessage({ type: "error", message: mutationError.message });
          return;
        }

        next();
      });
  }, [confirmWinner, addMessage, next]);

  return <Button type="submit" onClick={confirm} disabled={loading} icon={faCheckDouble}>Confirm Your Winner</Button>;
};

const RedrawWinnerButton = ({ sweepstakes, cancel }) => {
  const { addMessage } = useContext(SnackbarContext);
  const [redrawWinner, { loading }] = useMutation(redrawWinnerMutation, {
    variables: { sweepstakesId: sweepstakes.id }
  })

  const redraw = useCallback(() => {
    if(!window.confirm("Are you sure you want to redraw the winner? This cannot be undone.")) {
      return;
    }

    redrawWinner()
      .then(({ data, error }) => {
        const mutationError = pathOr(null, ["redrawWinner", "error"], data);

        if (error || mutationError !== null) {
          addMessage({ type: "error", message: mutationError.message });
          return;
        }

        cancel();
      });
  }, [redrawWinner, addMessage, cancel]);

  return (
    <React.Fragment>
      <Button onClick={cancel} disabled={loading}>Cancel</Button>
      <Button intent="help" onClick={redraw} disabled={loading} icon={faRedo}>Redraw Your Winner</Button>
    </React.Fragment>
  );
};

const ConfirmWinnerScreen = ({ sweepstakes, next, setResumeExpiry }) => {
  const [displayRedraw, setDisplayRedraw] = useState(false);

  const handleNext = useCallback(() => {
    setResumeExpiry();
    next()
  }, [setResumeExpiry, next]);

  const controls = displayRedraw ? (
    <RedrawWinnerButton sweepstakes={sweepstakes} cancel={() => setDisplayRedraw(false)} />
  ) : (
    <ConfirmWinnerButton sweepstakes={sweepstakes} next={handleNext} />
  );

  const redrawLink = <Button size="tiny" intent="help" onClick={() => setDisplayRedraw(true)} disabled={displayRedraw} icon={faRedo}>Redraw</Button>;

  return (
    <React.Fragment>
      <UI.StageContent>
        <UI.StageHeading>Confirm Your Winner</UI.StageHeading>
        <WinnerCard prize={sweepstakes.prize} title="The Grand Prize" grand />
        <UI.InstructionsLayout>
          <p>You can {redrawLink} if the name displayed is a person within your organization is ineligible to win.</p>
          <p>Otherwise, confirm your winner and your sweepstakes site will automatically update. Contact your winner to give them the good news!</p>
        </UI.InstructionsLayout>
      </UI.StageContent>
      <UI.Controls>
        {controls}
      </UI.Controls>
    </React.Fragment>
  );
};

export default ConfirmWinnerScreen;
