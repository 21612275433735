import React from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

import { getLogoutReturnPath } from "../lib/useLogout";

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const config = {
    authorizationParams: {
      audience: process.env.REACT_APP_AUTH0_AUD,
      redirectUri: window.location.origin,
    },
    domain:      process.env.REACT_APP_AUTH0_DOMAIN,
    clientId:    process.env.REACT_APP_AUTH0_CLIENT_ID,
    skipRedirectCallback: window.location.pathname === '/stripe',
    onRedirectCallback: appState => {
      if(appState && appState.returnTo) {
        navigate(appState.returnTo, { replace: true });
      }
    },
  };

  const logoutReturnPath = getLogoutReturnPath();

  if(logoutReturnPath) {
    return <Navigate to={logoutReturnPath} replace />
  }

  return (
    <Auth0Provider {...config}>
      {children}
    </Auth0Provider>
  );
}

export default AuthProvider;
