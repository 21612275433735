import React from "react";

import Button from "../../design/Button";
import EditForm from "./EditForm";
import * as UI from "./ui";


const Edit = ({ teamMember, nonprofit, setMode }) => (
  <UI.Layout>
    <UI.Header>
      <Button size="small" onClick={() => setMode("show")}>❮ Cancel</Button>
    </UI.Header>
    <UI.Body>
      <UI.Heading>
        <UI.Name>Edit Team Member</UI.Name>
      </UI.Heading>
      <EditForm teamMember={teamMember} nonprofit={nonprofit} setMode={setMode} />
    </UI.Body>
  </UI.Layout>
);

export default Edit;
