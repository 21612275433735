/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import useQueryString from "../../lib/useQueryString";
import { isNil } from "ramda";

import { Context } from "../../lib/Snackbar";
import useLocalStorage from "../../lib/useLocalStorage";
import { connectStripeMutation } from "./gql";

export const RETURN_PATH_KEY = "tapkat.stripeConnection.returnPath";

const ConnectStripe = () => {
  const { addMessage } = useContext(Context);
  const { code, state } = useQueryString();
  const [ connectStripe ] = useMutation(connectStripeMutation);
  const [ returnPath, setReturnPath ] = useLocalStorage('/', RETURN_PATH_KEY);
  const navigate = useNavigate();

  useEffect(() => {
    if(isNil(code) || isNil(state)) {
      navigate("/", { replace: true });
    }

    connectStripe({
      variables: {
        code,
        state,
      }
    }).then(({ data }) => {
      if(isNil(data.connectStripe.error)) {
        addMessage({ message: "Stripe successfully connected!", type: "success" });
      } else {
        addMessage({ message: data.connectStripe.error, type: "error" });
      }
    }).catch(({ error }) => {
      addMessage({ message: error, type: "error" });
    }).finally(() => {
      setReturnPath(null);
      navigate(returnPath, { replace: true });
    });
  }, []);

  return null;
}

export default ConnectStripe;
