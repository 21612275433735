import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const Header = styled.header`
  padding: 0 6px;
`;

export const Heading = styled.h1`
  font-family: tondo;
  font-weight: bold;
  color: #333333;
  white-space: nowrap;
  flex: 1;

  @media (min-width: 1024px) {
    font-size: 1.25em;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 12px;
`;

export const TableBackground = styled.div`
  background-color: #ffffff;
  border-radius: 6px;
  padding: 12px;
`;

export const Empty = styled.div`
  text-align: center;
  padding: 24px 12px;
  font-size: 0.875em;
  color: #666;
`;
