import styled from "styled-components";

export const Layout = styled.div`
  padding: 12px 0;
  max-width: 1000px;
`;

export const Section = styled.div`
  margin-bottom: 24px;
`;

export const SummaryLayout = styled.div`
  p {
    margin-bottom: 12px;
    line-height: 1.25em;
  }

  strong {
    font-weight: 700;
  }
`;

export const PluginConfigurationLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

export const PluginControls = styled.div`
  flex: 1;
  max-width: 600px;
`;

export const IntegrationFieldGroup = styled.div`
  padding: 3px;

  & > label {
    font-weight: bold;
    font-size: 0.9em;
    padding: 5px;
  }
`;

export const PluginPreview = styled.div`
  background-color: ${props => props.displayMode === "dark" ? "#4e4e4e" : "#fff"};
  padding: 10px;
  border-radius: 5px;
`;

export const CodeLayout = styled.div`
`;
