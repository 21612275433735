import React from "react";
import { useQuery } from "@apollo/client";
import { pathOr } from "ramda";

import MasterDetail from "../../lib/MasterDetail";
import { teamMembersQuery } from "./gql";
import Item from "./Item";
import Detail from "./Detail";

const TeamMemberList = ({ nonprofitId }) => {
  const { loading, data } = useQuery(teamMembersQuery, {
    variables: {
      id: nonprofitId,
    }
  })

  const teamMembers = pathOr([], ["nonprofit", "teamMembers"], data);
  const nonprofit = pathOr({}, ["nonprofit"], data);

  const newItem = {
    label: "Invite Team Member",
    resource: "team",
    permission: "C"
  };

  return (
    <MasterDetail
      items={teamMembers}
      loading={loading}
      ListItemComponent={Item}
      DetailComponent={Detail}
      newItem={newItem}
      nonprofit={nonprofit}
    />
  );
}

export default TeamMemberList;
