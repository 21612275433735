import React, { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { isNil } from "ramda";

import { Context as SnackbarContext } from "../../lib/Snackbar";
import { getInvitationQuery } from "./gql";
import AcceptWithAuth from "./AcceptWithAuth";
import AcceptWithoutAuth from "./AcceptWithoutAuth";
import AcceptWithRequiredAuth from "./AcceptWithRequiredAuth";

const InvitationPage = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const { invitationToken } = useParams();
  const navigate = useNavigate();
  const { addMessage } = useContext(SnackbarContext);
  const { data, loading } = useQuery(getInvitationQuery, {
    variables: {
      invitationToken,
    },
    onCompleted: data => {
      if(isNil(data.invitation)) {
        addMessage({ message: "Your invitation could not be found or has expired.", type: "error" });
        navigate("/");
      }
    }
  });

  if(isLoading || loading) {
    return null;
  }

  const invitation = data?.invitation;

  if(isNil(invitation)) {
    return null;
  }

  if(isAuthenticated) {
    return <AcceptWithAuth invitation={invitation} invitationToken={invitationToken} />
  }

  if(invitation.requiresSession) {
    return <AcceptWithRequiredAuth />
  }

  return <AcceptWithoutAuth invitation={invitation} invitationToken={invitationToken} />
};

export default InvitationPage;
