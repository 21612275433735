import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { path } from "ramda";

import Snackbar from "../Snackbar";
import EditForm from "./EditForm";
import * as UI from "./ui";
import { getMyself } from "./gql";

const EmailPreferencesPage = () => {
  const { nonprofitId } = useParams();
  const { data, loading, error } = useQuery(getMyself, {
    variables: {
      id: nonprofitId,
    }
  });

  if(loading) {
    return <p>Loading...</p>
  }

  if(error) {
    return <Snackbar type="error" message="Failed to load information about your nonprofit." />;
  }

  const teamMember = path(["nonprofit", "myself"], data);

  return (
    <React.Fragment>
      <UI.FormLayout>
        <EditForm teamMember={teamMember} />
      </UI.FormLayout>
    </React.Fragment>
  );
}

export default EmailPreferencesPage;
