import React from "react";
import { useMutation } from "@apollo/client";
import { faRedo } from "@fortawesome/free-solid-svg-icons";

import AccessControlledButton from "../../lib/AccessControlledButton";
import { drawFlashContestWinnerMutation } from "./gql";

const RedrawButton = ({ flashContest }) => {
  const [ drawFlashContestWinner, { loading } ] = useMutation(drawFlashContestWinnerMutation, {
    variables: { id: flashContest.id },
  });

  return (
    <AccessControlledButton intent="help" size="small" onClick={drawFlashContestWinner} icon={faRedo} disabled={loading} resource="winners" permission="U">
      Redraw the Winner
    </AccessControlledButton>
  );
}

export default RedrawButton;
