import React from "react";
import { faPlus, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";

import * as UI from "./ui";
import { NEW_ITEM } from "./";
import Button from "../../components/design/Button";

const Header = ({ newItemLabel, newItemEnabled, submitLabel, draftNotice, discardDraftLabel, onSubmit, setSelectedItemId, draft }) => {

  const handleClick = () => { setSelectedItemId(NEW_ITEM.id) };

  return (
    <React.Fragment>
      <UI.Header>
        <Button size="tiny" onClick={handleClick} disabled={!newItemEnabled} icon={faPlus}>{newItemLabel}</Button>
        <UI.DraftInfo enabled={draft.loaded}>
          <React.Fragment>
            <UI.DraftNotice>{draftNotice}</UI.DraftNotice>
            <Button size="tiny" onClick={draft.discard} icon={faTrash}>{discardDraftLabel}</Button>
          </React.Fragment>
        </UI.DraftInfo>
        <Button size="tiny" type="submit" onClick={onSubmit} icon={faSave}>{submitLabel}</Button>
      </UI.Header>
    </React.Fragment>
  );
};

export default Header;
