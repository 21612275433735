import styled from "styled-components";

export const Summary = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  color: rgba(0,0,0,0.8);
  font-size: 0.875em;

  ${({ $past }) => $past && `
    color: rgba(0,0,0,0.5);
    text-decoration: line-through;
  `}
`;

export const Date = styled.span`
  width: 100px;
  padding-left: 10px;
  margin-right: 25px;
  font-weight: bold;
  color: rgba(0,0,0,0.8);
`;

export const Description = styled.div`
  color: #545454;
  font-size: 0.875em;
`;

export const ItemLayout = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 5px 10px;
  margin-bottom: 10px;

  background-color: ${({ past }) => past ? "rgba(255,255,255,0.5)" : "#ffffff"};
`;

