import React from "react";
import { useQuery } from "@apollo/client";
import { pathOr } from "ramda";

import MasterDetail from "../../lib/MasterDetail";
import { nonprofitInvoiceQuery } from "./gql";
import Item from "./Item";
import Detail from "./Detail";

const InvoiceList = ({ nonprofitId }) => {
  const { loading, data } = useQuery(nonprofitInvoiceQuery, {
    variables: {
      nonprofitId,
    }
  });

  const invoices = pathOr([], ["nonprofit","invoices"], data);

  return (
    <MasterDetail
      items={invoices}
      loading={loading}
      ListItemComponent={Item}
      DetailComponent={Detail}
    />
  );
}

export default InvoiceList;
