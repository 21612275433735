import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { isPresent } from "../../util/presence";

const Pre = styled.pre`
  position: relative;

  font-family: monospace, monospace;
  text-wrap: wrap;
  white-space: pre-wrap;

  margin: 5px 0;
  padding: 2.25rem 0 1.75rem 1rem;
  border-radius: 10px;

  color: #fff;
  background-color: #3e3e3e;
`;

const Code = styled.code`
`;

const Button = styled.button`
  position: absolute;
  top: 7px;
  right: 7px;

  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.2rem;
  cursor: pointer;

  color: #fff;
  background-color: #828282;
  border: ridge 1px #828282;
  border-radius: 5px;

  &:hover {
    background-color: #929292;
  }
`;

const BUTTON_LABEL = "Copy";
const CLICKED_LABEL = "Copied";

const CodeBlock = ({ code }) => {
  const [buttonText, setButtonText] = useState(BUTTON_LABEL);
  const timeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  const clipboardPresent = isPresent(window.navigator.clipboard);

  const copyContentsToClipboard = async (event) => {
    event.preventDefault();

    await window.navigator.clipboard.writeText(code);

    setButtonText(CLICKED_LABEL);

    timeoutRef.current = setTimeout(() => {
      setButtonText(BUTTON_LABEL);
    }, 1000)
  };

  return (
    <Pre>
      <Code className="language-html">
        {code}
      </Code>
      {clipboardPresent && <Button onClick={copyContentsToClipboard}>{buttonText}</Button>}
    </Pre>
  )
};

export default CodeBlock;
