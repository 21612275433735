import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { isNil, evolve, map, pipe, pick } from "ramda";

import CollectionEditor from "../../../../lib/CollectionEditor";
import { Context as SnackbarContext } from "../../../../lib/Snackbar";
import Card from "./Card";
import EditForm from "./EditForm";
import { setSponsorsMutation } from "./gql";
import * as UI from "./ui";

export const Context = React.createContext(); 

const buildPayload = map(
  pipe(
    pick(['website', 'logo']),
    evolve({
      logo: pick(['cloudName', 'publicId', 'version']),
    }),
  )
);

const SponsorEditor = ({ sweepstakes, onStepFinish }) => {
  const { addMessage } = useContext(SnackbarContext);
  const { sponsors } = sweepstakes;
  const [ setSponsors ] = useMutation(setSponsorsMutation);

  const handleSubmit = sponsors => {
    const payload = buildPayload(sponsors);
    setSponsors({
      variables: {
        sweepstakesId: sweepstakes.id,
        sponsors: payload,
      }
    }).then(({ data }) => {
      if(isNil(data.setSponsors.error)) {
        addMessage({ message: "Your sponsors have been updated.", type: "success" });
        onStepFinish();
      }
    });
  };

  return (
    <UI.Layout>
      <UI.Instructions>
        <p>Add <strong>up to 15 partner/sponsor logos images</strong> (.jpg or .png). Click the “Add New Sponsor” button and upload, or drag and drop your image into the box. The image will be sized and cropped automatically. Copy and paste the sponsor’s website link into the Website box. <strong>Click the “Done” button to save each logo.</strong> Change the order by dragging a logo into position. <strong>Click the “Publish Changes” button to save your images to your TapKat site.</strong></p>
        <p><strong>Note:</strong> You can update your partners and sponsors at any point during the sweepstakes.</p>
      </UI.Instructions>
      <Context.Provider value={{ sweepstakes }}>
        <CollectionEditor
          items={sponsors}
          onSubmit={handleSubmit}
          newItemLabel="Add New Sponsor"
          submitLabel="Publish Changes"
          draftNotice="You have unpublished changes."
          discardDraftLabel="Discard Changes"
          itemLabel="Sponsor"
          maxItems={15}
          draftKey={`SponsorEditor:${sweepstakes.id}`}
          EditorComponent={EditForm}
          ItemComponent={Card}
        />
      </Context.Provider>
    </UI.Layout>
  );
};

export default SponsorEditor;
