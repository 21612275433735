import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import * as UI from "./ui";

const SearchButton = () => {
  const navigate = useNavigate();
  const navigateToSearch = useCallback(() => navigate("/search"), [navigate]);

  return <UI.SearchButton size="tiny" onClick={navigateToSearch} />;
}

export default SearchButton;

