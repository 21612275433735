import { useCallback } from "react";
import { DateTime } from "luxon";

import useLocalStorage from "../../lib/useLocalStorage";

const useDrawingResume = (key, minutes) => {
  const [resumeExpiry, setResumeExpiry] = useLocalStorage(null, `drawingToolResumeExpiryFor${key}`);
  const canResume = resumeExpiry && DateTime.fromISO(resumeExpiry) > DateTime.now();
  const setResume = useCallback(() => {
    setResumeExpiry(DateTime.now().plus({ minutes }).toISO())
  }, [setResumeExpiry, minutes]);
  const clearResume = useCallback(() => {
    setResumeExpiry(null);
  }, [setResumeExpiry]);

  return [canResume, setResume, clearResume];
};

export default useDrawingResume;
