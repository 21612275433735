import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { path } from "ramda";

import { Context as SnackbarContext } from "../../lib/Snackbar";
import Formation from "../forms/Form";
import TextField from "../forms/TextField";
import Button from "../design/Button";
import * as UI from "./ui";
import { acceptInvitationMutation } from "./gql";
import { isPassword } from "../../lib/validations";

const validations = {
  password: isPassword,
};

const AcceptanceForm = ({ invitationToken }) => {
  const { addMessage } = useContext(SnackbarContext);
  const navigate = useNavigate();
  const [ acceptInvitation, { loading } ] = useMutation(acceptInvitationMutation);

  const onSubmit = attributes => (
    acceptInvitation({
      variables: {
        invitationToken,
        attributes,
      }
    }).then(({ data }) => {
      const error = path(['acceptInvitation','error'], data);

      if(error) {
        addMessage({ message: "Unable to accept the invitation at this time", type: "error" });
      } else {
        addMessage({ message: "Invitation accepted! Please sign in to continue.", type: "success" });
        navigate("/");
      }
    })
  );

  return (
    <Formation initialInputObject={{}} onSubmit={onSubmit} validations={validations}>
      <UI.FormLayout>
        <TextField id="password" type="password" label="Password" />
        <Button type="submit" disabled={loading}>Accept</Button>
      </UI.FormLayout>
    </Formation>
  );
};

export default AcceptanceForm;
