import React, { useCallback, useMemo } from "react";
import { isNil, mergeLeft, path } from "ramda";
import { useMutation } from "@apollo/client";
import { useParams, Navigate } from "react-router-dom";

import SectionHeader from "../design/SectionHeader";
import { createAccountMutation } from "./gql";
import Form from "../forms/Form";
import Button from "../design/Button";
import TextField from "../forms/TextField";
import StateSelectField from "../forms/StateSelectField";
import CountrySelectField from "../forms/CountrySelectField";
import Snackbar from "../Snackbar";

import { presence, isPassword } from "../../lib/validations";
import * as UI from "./ui";

const buildValidations = accountApplication => {
  const defaultValidations = {
    legalName: presence,
    phone: presence,
    email: presence,
    publicEmail: presence,
    "address.line1": presence,
    "address.city": presence,
    "address.state": presence,
    "address.postalCode": presence,
    "address.country": presence,
  };

  return accountApplication.requiresSession ?
    defaultValidations :
    mergeLeft(defaultValidations, { password: isPassword });
};

const CompleteForm = ({ accountApplication }) => {
  const validations = useMemo(() => buildValidations(accountApplication), [accountApplication]);

  const { id } = useParams();
  const [ createAccount, { called, data, loading, error } ] = useMutation(createAccountMutation);

  const onSubmit = useCallback(attributes => (
    createAccount({
      variables: {
        attributes: {
          accountApplicationId: id,
          ...attributes,
        }
      }
    })
  ), [ createAccount, id ]);

  const mutationError = path(['createAccount','error'], data);

  if(called && !loading && isNil(mutationError) && isNil(error)) {
    return <Navigate to="/signin" />
  }

  const showError = !loading && called && (error || !isNil(mutationError));

  const initialInputObject = {
    legalName: accountApplication.nonprofitLegalName,
    phone: accountApplication.phone,
    email: accountApplication.billingEmail,
    publicEmail: accountApplication.contactEmail,
    address: {
      line1: accountApplication.address?.line1,
      line2: accountApplication.address?.line2,
      city: accountApplication.address?.city,
      state: accountApplication.address?.state,
      postalCode: accountApplication.address?.postalCode,
      country: accountApplication.address?.country || "US",
    },
  };

  return (
    <React.Fragment>
      <SectionHeader heading="Complete Your Nonprofit Account" />
      <Form initialInputObject={initialInputObject} onSubmit={onSubmit} validations={validations}>
        <UI.Fieldset>
          <UI.Legend>Your Nonprofit's Information</UI.Legend>
          <UI.FieldGroup>
            <TextField id="legalName" label="Legal Name" />
          </UI.FieldGroup>
          <UI.FieldGroup>
            <TextField id="phone" label="Phone Number" />
            <TextField id="email" type="email" label="Billing Email Address" />
            <TextField id="publicEmail" type="email" label="Contact/Info Email Address" />
          </UI.FieldGroup>
          <UI.FieldGroup>
            <TextField id="address.line1" label="Address 1" />
            <TextField id="address.line2" label="Address 2" />
          </UI.FieldGroup>
          <UI.FieldGroup>
            <TextField id="address.city" label="City" />
            <StateSelectField id="address.state" label="State" />
            <TextField id="address.postalCode" label="Zip" />
            <CountrySelectField id="address.country" label="Country" disabled />
          </UI.FieldGroup>
        </UI.Fieldset>
        {!accountApplication.requiresSession && (
          <UI.Fieldset>
            <UI.Legend>TapKat User Account Sign In</UI.Legend>
            <UI.Hint>Your password must be 8 characters long, contain both uppercase and lowercase letters, and at least one number or special character.</UI.Hint>
            <UI.FieldGroup>
              <TextField value={accountApplication.email} label="Username" readOnly disabled />
              <TextField id="password" type="password" label="Password" />
            </UI.FieldGroup>
          </UI.Fieldset>
        )}
        <UI.Legal>
          By clicking “Complete Application” or signing up you agree to <a href="https://tapkat.com/terms-of-service" target="_blank" rel="noopener noreferrer">TapKat Terms of Service</a>, <a href="https://www.tapkat.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>, <a href="https://tapkat.com/copyright-policy" target="_blank" rel="noopener noreferrer">Copyright Policy</a> and <a href="https://tapkat.com/standards-of-conduct" target="_blank" rel="noopener noreferrer">Standards of Conduct</a>. You also agree to receive information and offers relevant to our services via email.  You can opt-out of these emails anytime by clicking unsubscribe at the bottom of the email.
        </UI.Legal>
        <Button type="submit" disabled={loading}>Complete Application</Button>
      </Form>
      { showError && <Snackbar type="error" message="There was a problem completing your account, please try again later." /> }
    </React.Fragment>
  );
};

export default CompleteForm;
