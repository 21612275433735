import styled from "styled-components";

export const Table = styled.table.attrs({
  cellSpacing: 0,
})`
  width: 100%;
  font-size: 0.875em;
`

export const TH = styled.th`
  text-align: left;
  padding: 6px 12px;
  font-weight: bold;

  &:not(:first-child) {
    text-align: right;
  }
`;

export const TR = styled.tr`
  border-radius: 6px;
  &:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const TD = styled.td`
  text-align: left;
  padding: 6px 12px;

  &:not(:first-child) {
    text-align: right;
  }
`;
