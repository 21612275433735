import React, { useState } from "react";

const ModeSwitch = ({ initialMode, children }) => {
  const [ mode, setMode ] = useState(initialMode);

  let element = null;

  React.Children.forEach(children, child => {
    if (element === null && React.isValidElement(child)) {
      if(child.props.mode === mode) {
        element = child;
      }
    }
  });

  return React.cloneElement(element, { setMode });
}

export default ModeSwitch;
