import React from "react";

import { NEW_ITEM, useMasterDetailContext } from "../../../lib/MasterDetail";
import { ScheduleGuard } from "../../../lib/schedule";
import ModeSwitch from "../../../lib/ModeSwitch";
import New from "./New";
import Show from "./Show";
import Edit from "./Edit";

const Detail = ({ item: entry, onSelect, onCancel, showCancel }) => {
  const { setLocked, sweepstakes } = useMasterDetailContext();

  if(!entry) {
    return null;
  }

  const props = { entry, sweepstakes, onSelect, onCancel, showCancel, setLocked };

  if(entry === NEW_ITEM) {
    return (
      <ScheduleGuard enabledPeriods={["open", "closing", "grace", "closed"]}>
        <New {...props} />
      </ScheduleGuard>
    );
  }

  return (
    <ModeSwitch initialMode="show">
      <Show mode="show" {...props} />
      <Edit mode="edit" {...props} />
    </ModeSwitch>
  );
}

export default Detail;
