import React from "react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import DonationActivityFeed from "../DonationActivityFeed";
import AccessControlledLink from "../../lib/AccessControlledLink";
import * as UI from "./ui";

const RecentDonations = ({ sweepstakes }) => {
  if(!sweepstakes.approved) {
    return null;
  }

  return (
    <UI.Section style={{ gridArea: "recent-donations" }}>
      <UI.SectionHeading>Recent Donations</UI.SectionHeading>
      <DonationActivityFeed sweepstakes={sweepstakes} />
      <AccessControlledLink to="./donations" icon={faArrowRight} size="small" resource="entries">View All Donations</AccessControlledLink>
    </UI.Section>
  );
}

export default RecentDonations;
