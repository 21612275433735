import React, { useContext } from "react";
import { isNil, path } from "ramda"
import { useMutation } from "@apollo/client";

import { Context as SnackbarContext } from "../../../lib/Snackbar";
import AccessControlledButton from "../../../lib/AccessControlledButton";
import { isBlank } from "../../../util/presence";
import { resendReceiptMutation } from "./gql";

const mutationSucceeded = mutation =>
  isNil(mutation.error) && isBlank(path(['data','resendReceipt','error'], mutation));

const ResendReceiptButton = ({ entryId }) => {
  const [ resendReceipt, { loading } ] = useMutation(resendReceiptMutation);
  const { addMessage } = useContext(SnackbarContext);

  const onClick = () => {
    resendReceipt({
      variables: {
        id: entryId,
      }
    }).then(mutation => {
      if(mutationSucceeded(mutation)) {
        addMessage({ type: "success", message: "A copy of the receipt has been sent." });
      } else {
        addMessage({ type: "error", message: "There was a problem resending this receipt." });
      }
    });
  }

  return (
    <AccessControlledButton size="small" type="submit" disabled={loading} onClick={onClick} resource="entries" permission="U">
      Resend Receipt
    </AccessControlledButton>
  );
}

export default ResendReceiptButton;
