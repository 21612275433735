import React from "react";
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'

import Link from "../design/Link";
import * as UI from "./ui";

const Summary = () => (
  <UI.SummaryLayout>
    <p>
      The <strong>Donation Plugin</strong> allows you to accept
      donations directly from your own website. This page allows you to
      generate the source code needed to install the widget in your website.
    </p>
    <p>
      Download our guide for more information on the advantages of
      the <strong>Donation Plugin</strong> and to get a detailed description
      of how to use the source code generated by this setup page.
    </p>
    <Link href="https://docs.google.com/presentation/d/1AHNJ2cWh61COs7vCzok_caaFI9i_fuioCvn5SuRweVw/edit" size="tiny" icon={faFileDownload}>Donation Plugin Guide</Link>
  </UI.SummaryLayout>
);

export default Summary;
