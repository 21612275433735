import React from "react";

import * as UI from "./ui";

const SearchForm = ({ onSearch }) => {
  const handleSubmit = e => {
    e.preventDefault();

    const data = new FormData(e.target);
    onSearch({ value: data.get("value") });
  };

  return (
    <UI.Form onSubmit={handleSubmit}>
      <UI.Input
        id="global-search"
        name="value"
        type="text"
        placeholder="Search for nonprofits or sweepstakes"
        autoFocus
      />
      <UI.Submit>Search</UI.Submit>
    </UI.Form>
  );
}

export default SearchForm;
