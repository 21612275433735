export const insightStatsSummary = series => {
  const total = series.reduce((acc, data) => acc + data.total, 0);
  const count = series.reduce((acc, data) => acc + data.count, 0);
  const average = count === 0 ? 0 : total / count;

  return { total, count, average };
};

export const accumulateSeries = series => (
  series.reduce((accumulated, datum) => {
    const lastDatum = accumulated[accumulated.length - 1];
    const total = lastDatum ? lastDatum.total + datum.total : datum.total;
    const count = lastDatum ? lastDatum.count + datum.count : datum.count;

    return [ ...accumulated, { ...datum, total, count }];
  }, [])
);
