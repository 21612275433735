import React, { useState } from "react";
import { DateTime } from "luxon";

import BarTable from "../../lib/BarTable";
import Chart from "./Chart";
import * as UI from "./ui";

const InsightCard = ({ series }) => {
  const [ selectedIndex, setSelectedIndex ] = useState(null);

  const labeledSeries = series.map(datum => ({
    ...datum,
    label: DateTime.fromISO(datum.bucket).toLocaleString(),
  }));

  return (
    <UI.Layout>
      { labeledSeries.length > 0 && (
        <Chart series={series} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />
      )}
      <UI.Table>
        { labeledSeries.length > 0 ? (
          <BarTable series={labeledSeries} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />
        ) : (
          <UI.Empty>No data available yet.</UI.Empty>
        )}
      </UI.Table>
    </UI.Layout>
  );
};

export default InsightCard;
