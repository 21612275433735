import React from "react";
import styled from "styled-components";

const Layout = styled.span`
  text-align: right;
  display: block;
`;

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const Money = ({ amount, autoAlign }) => {
  const formattedAmount = formatter.format((amount / 100).toFixed(2));

  return autoAlign ? <Layout>{formattedAmount}</Layout> : formattedAmount;
}

export default Money;
