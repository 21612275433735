import { gql } from "@apollo/client";

export const getMyself = gql`
  query GetNonprofit($id: NonprofitSlug!) {
    nonprofit(id: $id) {
      id
      myself {
        id
        emailPreferences {
          sweepstakesApproved
          sweepstakesSchedule
          tapkatWin
          sweepstakesEmailBlastReminder
        }
      }
    }
  }
`;

export const updateEmailPreferencesMutation = gql`
  mutation UpdateTeamMemberMutation($teamMemberId: ID!, $preferences: EmailPreferencesInput!) {
    updateEmailPreferences(teamMemberId: $teamMemberId, preferences: $preferences) {
      emailPreferences {
        id
        sweepstakesApproved
        sweepstakesSchedule
        tapkatWin
        sweepstakesEmailBlastReminder
      }
      error {
        code
        message
      }
    }
  }
`;
