import { useRef, useState, useLayoutEffect, useEffect } from "react";

const useDimensions = () => {
  const ref = useRef(null);
  const [ dimensions, setDimensions ] = useState({ width: 0, height: 0 });

  const measure = () => {
    if(ref.current) {
      const { height, width } = ref.current.getBoundingClientRect();
      setDimensions({ height, width });
    }
  }

  useLayoutEffect(measure, [ref]);

  useEffect(() => {
    window.addEventListener("resize", measure);
    return () => window.removeEventListener("resize", measure);
  }, [ref]);

  return [ref, dimensions];
};

export default useDimensions;
