import { gql } from "@apollo/client";

export const createSweepstakesMutation = gql`
  mutation CreateSweepstakes($nonprofitId: NonprofitSlug!, $attributes: NewSweepstakesInput!) {
    createSweepstakes(nonprofitId: $nonprofitId, attributes: $attributes) {
      sweepstakes {
        id
      }
      error {
        code
        message
      }
    }
  }
`;
