import styled from "styled-components";
import { Link } from "react-router-dom";

export const List = styled.ol`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const ListItem = styled.li`
`;

export const PromoCode = styled(Link)`
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 6px 12px;
  border-radius: 3px;
  align-items: center;
`;

export const Name = styled.div`
  color: #333333;
  font-size: 0.875em;
  font-weight: bold;
  line-height: 1em;
`;

export const Amount = styled.div`
  display: inline-block;
  padding: 3px 6px;
  background-color: #77aa16;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  border-radius: 3px;
  font-size: 0.875em;
  width: 84px;
`;

export const Empty = styled.div`
  font-size: 0.875em;
  text-align: center;
  padding: 24px 12px;
  background-color: #ffffff;
  border-radius: 3px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
