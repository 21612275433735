import axios from "axios";
import { pick, forEachObjIndexed } from "ramda";

const SIGNATURE_URL = `${process.env.REACT_APP_API_URL}/upload_signatures`;

const URL = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`;

const CLOUDINARY_SIGNATURE_PARAMS = [
  'upload_preset',
  'timestamp',
  'folder',
];

export const buildFormData = params => {
  const formData = new FormData();

  forEachObjIndexed((value, key) => {
    formData.append(key, value);
  }, params);

  return formData;
}

export const generateSigningFunction = authToken => async params => {
  const upload_signature_params = pick(CLOUDINARY_SIGNATURE_PARAMS, params);

  return axios({
    url: SIGNATURE_URL,
    method: "POST",
    responseType: "text",
    data: { upload_signature_params },
    headers: { "Authorization": `Bearer ${authToken}` },
  });
}

export const upload = async (file, options) => {
  const {
    config = {},
    signUpload,
    onUploadProgress,
  } = options;

  const params = {
    ...config,
    api_key: process.env.REACT_APP_CLOUDINARY_API_KEY,
    upload_preset: 'assets',
    timestamp: Date.now(),
    file,
  };

  const { data: signature } = await signUpload(params);
  params['signature'] = signature;

  const formData = buildFormData(params);

  return axios({
    url: URL,
    method: 'POST',
    data: formData,
    onUploadProgress,
  });
};
