import React from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useHotkeys } from "react-hotkeys-hook";

import * as UI from "./ui";
import AccessControlledButton from "../../lib/AccessControlledButton";

const MasterFooter = ({ newItem, footerMenu }) => {
  const { label, onClick, resource, permission, disabled } = newItem;

  useHotkeys('n', onClick, { preventDefault: true }, [onClick]);

  return (
    <UI.MasterFooter>
      <AccessControlledButton
        size="small"
        type="submit"
        onClick={onClick}
        icon={faPlus}
        resource={resource}
        permission={permission}
        disabled={disabled}
      >
          {label}
      </AccessControlledButton>
      { footerMenu }
    </UI.MasterFooter>
  );
};

export default MasterFooter;
