import React from "react";
import { useMutation } from "@apollo/client";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";

import AccessControlledButton from "../../lib/AccessControlledButton";
import { confirmFlashContestWinnerMutation } from "./gql";

const ConfirmButton = ({ flashContest }) => {
  const [ confirmFlashContestWinner, { loading } ] = useMutation(confirmFlashContestWinnerMutation, {
    variables: { id: flashContest.id },
  });

  return (
    <AccessControlledButton size="small" type="submit" onClick={confirmFlashContestWinner} icon={faCheckDouble} disabled={loading} resource="winners" permission="U">
      Confirm the Winner
    </AccessControlledButton>
  );
}

export default ConfirmButton;
