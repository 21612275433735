import styled from "styled-components";

export const Label = styled.div``;

export const LabelHeader = styled.strong`
  font-weight: bold;
`;

export const LabelBody = styled.p`
`;

export const FormLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FormContent = styled.div`
  flex: 1;
  max-width: 35%;

  @media screen and (max-width: 1024px) {
    max-width: unset;
    min-width: 100%;
  }
`;

export const Instructions = styled.div`
  padding: 0 24px;
  max-width: 60%;

  @media screen and (max-width: 1024px) {
    max-width: unset;
    min-width: 100%;
    padding: 0;
  }

  h2 {
    margin-bottom: 12px;
    font-size: 1.125em;
    font-weight: bold;
  }

  p {
    margin-bottom: 12px;
    line-height: 1.25em;
  }

  strong {
    font-weight: 700;
  }
`;
