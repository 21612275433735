import React from "react";
import { isNil } from "ramda"

import * as UI from "./ui";

const Editor = ({ item, onDone, onSelect, EditorComponent }) => {
  return (
    <UI.EditorLayout enabled={!isNil(item)}>
      <EditorComponent
        item={item}
        onDone={onDone}
        onSelect={onSelect}
      />
    </UI.EditorLayout>
  );
};

export default Editor;
