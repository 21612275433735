import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 12px;

  background-color: #ffffff;
  border-radius: 6px;
  padding: 3px 12px;
`;

export const Chart = styled.svg`
  width: 100%;
  height: 72px;
  overflow: visible;
`;

export const Table = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 200px;
  overflow: auto;
`;

export const Empty = styled.div`
  font-size: 0.875em;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
`;
