import { useState, useCallback } from "react";
import { DateTime } from "luxon";
import { isNil } from "ramda";

import { isBlank } from "../util/presence";

export const EXPIRY_SUFFIX = "-expiry";
const CYCLE_WAIT =  1000;
const MINIMUM_EXPIRES_IN = CYCLE_WAIT / 1000;

const handleLocalStorageExpiry = () => {
  const keys = Object.keys(window.localStorage).filter(key => key.endsWith(EXPIRY_SUFFIX));
  const expiryThreshold = Date.now() + 1000;

  for (const key of keys) {
    try {
      const expiry = Date.parse(window.localStorage.getItem(key));
      if (expiryThreshold >= expiry) {
        window.localStorage.removeItem(key.replace(EXPIRY_SUFFIX, ''));
        window.localStorage.removeItem(key);
      }
    } catch (error) {}
  }

  setTimeout(handleLocalStorageExpiry, CYCLE_WAIT);
};

handleLocalStorageExpiry();

const handleExpiresInMinimum = (expiresIn) => {
  if (!expiresIn) {
    return null;
  }

  if (expiresIn < MINIMUM_EXPIRES_IN  ) {
    console.warn(`localStorage expiresIn is less than the minimum of ${MINIMUM_EXPIRES_IN} seconds. Setting to minimum.`);
    return MINIMUM_EXPIRES_IN;
  }

  return expiresIn;
}

const DEFAULT_OPTIONS = {
  expiresIn: null
};

const useLocalStorage = (initialValue, key, options = DEFAULT_OPTIONS) => {
  const { expiresIn } = options;

  const [storage, setStorage] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = useCallback((value) => {
    if(isBlank(key)) {
      return null;
    }

    try {
      const nextValue = value instanceof Function ? value(storage) : value;

      if(isNil(nextValue)) {
        window.localStorage.removeItem(key);
      } else {
        if (expiresIn) {
          const expiry = DateTime.local().plus({ seconds: handleExpiresInMinimum(expiresIn) }).toISO();
          window.localStorage.setItem(`${key}${EXPIRY_SUFFIX}`, expiry)
        }
        window.localStorage.setItem(key, JSON.stringify(nextValue));
      }

      setStorage(nextValue);
    } catch (error) { }
  }, [key, storage, expiresIn, setStorage]);

  return [ storage, setValue ];
}

export default useLocalStorage;
