import React from "react";
import { Link } from "react-router-dom";

import * as UI from "./ui";
const DEFAULT_SLIDE = {
  image: {
    cloudName: "tapkat",
    publicId: "under-construction_ubgqwp",
    version: 0
  }
};

const ActiveSweepstakes = ({ sweepstakes }) => {
  const slide = sweepstakes.slides[0] || DEFAULT_SLIDE;

  return (
    <Link size="small" to={`./sweepstakes/${sweepstakes.id}`}>
      <UI.SweepstakesItem>
        <UI.SweepstakesImage {...slide.image} width="300" crop="fill" aspectRatio="16:9" />
        <UI.SweepstakesName>{sweepstakes.prize.name}</UI.SweepstakesName>
      </UI.SweepstakesItem>
    </Link>
  );
};

export default ActiveSweepstakes;
