import styled from "styled-components";

export const MessageList = styled.div`
  display: flex;
  flex-direction: column;
  left: 50%;
  position: fixed;
  transform: translateX(-50%);
  width: calc(50% - 60px);
  bottom: 30px;
  z-index: 2;
`;

export const Layout = styled.div`
  border-radius: 2px;
  color: white;
  display: flex;
  font-family: acumin-pro;
  padding: 12px 24px;
  align-items: center;
  transition: opacity 250ms ease-in-out;
  margin-top: 12px;
  border-radius: 12px;

  ${props => props.type === "error" && `
    background-color: #db3a34;
  `}

  ${props => props.type === "success" && `
    background-color: #77aa16;
  `}

  ${props => props.type === "info" && `
    background-color: #ffae00;
  `}

  ${props => !props.visible && `
    opacity: 0;
  `}
`

export const Message = styled.span`
  line-height: 30px;
  flex: 1;
`

export const Close = styled.div`
  font-size: 1.5em;
  line-height: 30px;
  transform: translateY(-2px);
  cursor: pointer;
`
