import React from "react";
import styled from "styled-components";
import CountUp from "react-countup";

const Layout = styled.div`
  display: flex;
  border-radius: 3px;
  padding: 6px 12px;
  gap: 12px;
  background-color: #ffffff;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Header = styled.div`
`;

const Heading = styled.div`
  font-weight: bold;
  font-size: 0.9375em;
  color: rgba(0, 0, 0, 0.8);
`;

const Subheading = styled.div`
  font-size: 0.875em;
  color: rgba(0, 0, 0, 0.6);
`;

const Gauge = styled.div`
  font-size: 2em;
  font-family: tondo, sans-serif;
  line-height: 1em;
`;

const ProgressGauge = ({ heading, subheading, percentage }) => (
  <Layout>
    <Header>
      <Heading>{heading}</Heading>
      <Subheading>{subheading}</Subheading>
    </Header>
    <Gauge><CountUp start={0} end={percentage} />%</Gauge>
  </Layout>
);

export default ProgressGauge;
