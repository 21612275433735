import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'

import AccessControlledButton from "../../lib/AccessControlledButton";
import { downloadExport } from "./util";

const ExportButton = ({ url, resource, contentType = "text/csv", ...rest }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [ disabled, setDisabled ] = useState(false);

  const onClick = () => {
    setDisabled(true);

    getAccessTokenSilently().then(token => {
      downloadExport(url, token, contentType).then(() => {
        setDisabled(false)
      });
    });
  }

  return (
    <AccessControlledButton
      size="small"
      resource={resource}
      permission="E"
      onClick={onClick}
      disabled={disabled}
      icon={faFileDownload}
      {...rest}
    />
  );
}

export default ExportButton;
