import { gql } from "@apollo/client";

export const getDonationLevels = gql`
  query GetPromoCodes($sweepstakesId: SweepstakesSlug!) {
    sweepstakes(id: $sweepstakesId) {
      id
      donationLevels {
        id
        level
        price
        rewardTickets
      }
    }
  }
`;
