import React, { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { path } from "ramda";

import { Context as SnackbarContext } from "../../lib/Snackbar";
import useLogout from "../../lib/useLogout";
import SignPost from "../design/SignPost";
import Button from "../design/Button";
import { acceptInvitationMutation } from "./gql";

const AcceptWithAuth = ({ invitation, invitationToken }) => {
  const { isLoading, user }  = useAuth0();
  const logout = useLogout();
  const { addMessage } = useContext(SnackbarContext);
  const navigate = useNavigate();
  const [ acceptInvitation, { loading } ] = useMutation(acceptInvitationMutation, {
    variables: {
      invitationToken,
    }
  });

  const onLogout = () => {
    logout({ returnPath: window.location.pathname });
  };

  if(isLoading) {
    return null;
  }

  const handleClick = () => (
    acceptInvitation().then(({ data }) => {
      const error = path(['acceptInvitation','error'], data);

      if(error) {
        addMessage({ message: "Unable to accept the invitation at this time", type: "error" });
      } else {
        addMessage({ message: "Invitation accepted!", type: "success" });
        navigate("/");
      }
    })
  );

  return (
    <React.Fragment>
      <SignPost heading="Accept using my existing account.">
        <Button type="submit" onClick={handleClick} disabled={loading}>
          Accept as {user.email}
        </Button>
      </SignPost>
      <SignPost heading="Accept this invitation with another account.">
        <Button size="small" onClick={onLogout}>Logout</Button>
      </SignPost>
    </React.Fragment>
  );
};

export default AcceptWithAuth;
