import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import SignPost from "../design/SignPost";
import Button from "../design/Button";

const SessionPrompt = ({ accountApplication }) => {
  const { loginWithRedirect } = useAuth0();

  const login = () => {
    loginWithRedirect({ appState: { returnTo: window.location.pathname }});
  };

  return (
    <SignPost heading={`Sign In As ${accountApplication.email} To Complete This Nonprofit Account.`}>
      <Button type="submit" onClick={login}>Sign In</Button>
    </SignPost>
  )
};

export default SessionPrompt;
