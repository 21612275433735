import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { DateTime } from "luxon";

import AccessControlledButton from "../../lib/AccessControlledButton";
import { drawFlashContestWinnerMutation } from "./gql";

// https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/setTimeout#Notes
// Set a safe limit of about 28 days to avoid overflowing setTimeout and executing immediately.
const SAFE_LIMIT = 24 * 24 * 60 * 60 * 1000;

const DrawButton = ({ flashContest, sweepstakes, enabled }) => {
  const [ overrideEnabled, setOverrideEnabled ] = useState(false);
  const [ drawFlashContestWinner, { loading } ] = useMutation(drawFlashContestWinnerMutation, {
    variables: {
      id: flashContest.id,
    },
  });

  useEffect(() => {
    const timeUntilEnabled = Math.min(DateTime.fromISO(flashContest.eligibleRange[1]).diffNow().as('milliseconds'), SAFE_LIMIT);

    if(!enabled) {
      const timerId = setTimeout(() => { setOverrideEnabled(true) }, timeUntilEnabled);
      return () => clearTimeout(timerId);
    }
  }, [ enabled, flashContest.eligibleRange]);

  const disabled = loading || !(overrideEnabled || enabled);

  return (
    <AccessControlledButton size="small" type="submit" onClick={drawFlashContestWinner} disabled={disabled} resource="winners" permission="C">
      Draw the Winner
    </AccessControlledButton>
  );
}

export default DrawButton;
