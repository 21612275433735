export const download = (() => {
  const a = document.createElement("a");
  a.style = "display: none";
  document.body.appendChild(a);
  return (blob, fileName) => {
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();

export { download as default };
