import React from "react";
import { path, prop } from "ramda";

import Telephone from "../design/Telephone";
import Money from "../design/Money";

import * as UI from "./ui";

const WinnerCard = ({ prize, title, grand, detailed }) => {
  const { winningTicket, winningEntry } = prize;

  return (
    <UI.WinnerCardLayout>
      {title && <UI.Title>{title}</UI.Title>}
      <UI.Number $grand={grand}>{prop('number', winningTicket)}</UI.Number>
      <UI.Winner>
        <UI.Name $grand={grand}>
          {winningEntry && `${prop('firstName', winningEntry)} ${prop('lastName', winningEntry)}`}
        </UI.Name>
        <UI.Location>
          {winningEntry && `${path(['address', 'city'], winningEntry)}, ${path(['address', 'state'], winningEntry)}`}
        </UI.Location>
        { detailed && (
          <React.Fragment>
            <UI.Phone><Telephone phoneNumber={prop('phoneNumber', winningEntry)} /></UI.Phone>
            <UI.Email>{prop('email', winningEntry)}</UI.Email>
          </React.Fragment>
        )}
        { detailed && winningEntry?.amount > 0 && (
          <UI.WinnerDetails>
            Winning Donation was for <strong><Money amount={winningEntry.amount} /></strong> {winningEntry.promoCode && (
              <React.Fragment>
                using <strong>{winningEntry.promoCode.name}</strong>
              </React.Fragment>
            )}
          </UI.WinnerDetails>
        )}
        { detailed && winningEntry?.entrantDonationCount > 0 && (
          <UI.WinnerDetails>
            <strong><Money amount={winningEntry.entrantDonationTotal} /></strong> total from <strong>{winningEntry.entrantDonationCount}</strong> Donation{winningEntry.entrantDonationCount > 1 && 's'}
          </UI.WinnerDetails>
        )}
      </UI.Winner>
    </UI.WinnerCardLayout>
  );
}

export default WinnerCard;
