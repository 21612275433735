import React from "react";

import Context from "./Context";

const PermissionsProvider = ({ role, children }) => (
  <Context.Provider value={role}>
    { children }
  </Context.Provider>
);

export default PermissionsProvider;
