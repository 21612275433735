import React, { useContext, useEffect, useCallback } from "react";
import { useMutation } from "@apollo/client";
import { isNil, path } from "ramda";

import { Context as SnackbarContext } from "../../../lib/Snackbar";
import { presence, presenceIf } from "../../../lib/validations";
import { sweepstakesDateTime } from "../../../util/date";
import { isBlank } from "../../../util/presence";
import Button from "../../design/Button";
import EntryForm from "../../EntryForm";
import * as UI from "./ui";

import { updateEntryMutation } from "./gql";

const validations = {
  "firstName": presence,
  "lastName": presence,
  "address.line1": presence,
  "address.country": presence,
  "address.city": presence,
  "address.state": presenceIf(context => context["address.country"] === "US"),
  "address.postalCode": presenceIf(context => context["address.country"] === "US"),
  "email": presence,
  "phoneNumber": presence,
};

const mutationSucceeded = mutation =>
  isNil(mutation.error) && isBlank(path(['data','updateEntry','error'], mutation));

const Edit = ({ entry, sweepstakes, setMode, setLocked }) => {
  const { addMessage } = useContext(SnackbarContext);
  const [ update, mutation ] = useMutation(updateEntryMutation);

  useEffect(() => {
    setLocked(true);
    return () => { setLocked(false) };
  }, [ setLocked ]);

  const onSubmit = useCallback(inputObject => (
    update({
      variables: {
        id: entry.id,
        attributes: inputObject,
      }
    }).then(mutation => {
      if (mutationSucceeded(mutation)) {
        setMode("show");
      } else {
        addMessage({ type: "error", message: "Unable to save changes to the entry." });
      }
    })
  ), [ update, entry, setMode, addMessage ]);

  return (
    <UI.Layout>
      <UI.Header>
        <Button size="small" onClick={() => setMode("show")}>❮ Cancel</Button>
      </UI.Header>
      <UI.Body>
        <UI.Heading>
          <UI.Name>{`${entry.firstName} ${entry.lastName}`}</UI.Name>
          <UI.HeadingAside>
            <UI.Date>{sweepstakesDateTime(sweepstakes, entry.createdAt)}</UI.Date>
            {entry.paymentInfo && <UI.Payment>Payment: {entry.paymentInfo}</UI.Payment>}
          </UI.HeadingAside>
        </UI.Heading>
        <EntryForm
          entry={entry}
          validations={validations}
          sweepstakes={sweepstakes}
          onSubmit={onSubmit}
          loading={mutation.loading}
        />
      </UI.Body>
    </UI.Layout>
  );
}

export default Edit;
