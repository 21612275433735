import React from "react";

import { usePermissions } from "../../lib/permissions";
import CheckboxField from "../forms/CheckboxField";

import * as UI from "./ui";

const EmailPreferenceField = ({ id, email }) => {
  const accessEnabled = usePermissions(email.permission);

  // true if the email is not accessible by default or the user lacks access
  if (!(email.permission === true || accessEnabled)) { return null; }

  return (
    <CheckboxField
      id={id}
      label={
        <UI.Label>
          <UI.LabelHeader>
            {email.label}
          </UI.LabelHeader>
          <UI.LabelBody>
            {email.description}
          </UI.LabelBody>
        </UI.Label>
      }
    />
  )
};

export default EmailPreferenceField;
