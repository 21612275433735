import React from "react";
import { times } from "ramda"

const COLORS = {
  total: "#77aa16",
  count: "#375BA4",
  average: "#7d5ea3",
};

export const CellOverlay = ({ height, width, length, onSelect }) => {
  const cellWidth = width / (length - 1);
  const cells = times(index => ({ x: (index * cellWidth) - (cellWidth / 2), y: 0 }), length);

  return (
    <svg height={height} width={width}>
      { cells.map((cell, index) => (
        <rect
          key={index}
          x={cell.x}
          y={cell.y}
          width={cellWidth}
          height={height}
          onMouseEnter={() => onSelect(index)}
          onTouchStart={() => onSelect(index)}
          fill="transparent"
        />
      ))}
    </svg>
  );
}

const generatePoints = ({ series, attribute, width, height }) => {
  const max = Math.max(...series.map(datum => datum[attribute]));
  const min = Math.min(...series.map(datum => datum[attribute]));

  return series.map((datum, index) => {
    const x = (index / (series.length - 1)) * width;
    const y = (max - min === 0) ?
      height / 2 :
      height - (datum[attribute] - min) / (max - min) * height;

    return { x, y };
  });
}

const Sparkline = ({ series, attribute, selectedIndex, height, width, ...rest }) => {
  if(series.length === 0) {
    return null;
  }

  const points = generatePoints({ series, attribute, width, height });
  const activePoint = points[selectedIndex];
  const polyline = points.map(point => `${point.x},${point.y}`).join(" ");
  const mean = points.reduce((acc, point) => acc + point.y, 0) / points.length;
  const color = COLORS[attribute];

  return (
    <svg height={height} width={width} {...rest} overflow="visible">
      <line x1={0} y1={mean} x2={width} y2={mean} stroke="#888888" strokeWidth="0.5" strokeDasharray="2 2" />
      { activePoint && <circle cx={activePoint.x} cy={activePoint.y} r={3} fill={color} />}
      <polyline points={polyline} fill="none" stroke={color} strokeWidth="1" />
    </svg>
  );
};

export default Sparkline;
