import { gql } from "@apollo/client";

export const teamMemberFragment = gql`
  fragment TeamMemberFragment on TeamMember {
    id
    name
    email
    invitationStatus
    removable
    role {
      id
      name
      nonprofit
      team
      sweepstakes
      winners
      entries
      promoCodes
      insights
      paymentAccount
    }
  }
`

export const teamMembersQuery = gql`
  query TeamMembersQuery($id: NonprofitSlug!) {
    nonprofit(id: $id) {
      id
      teamMembers {
        ...TeamMemberFragment
      }
      roles {
        id
        name
        nonprofit
        team
        sweepstakes
        winners
        entries
        promoCodes
        insights
        paymentAccount
      }
    }
  }
  ${teamMemberFragment}
`;

export const myRoleQuery = gql`
  query MyRoleQuery($id: NonprofitSlug!) {
    nonprofit(id: $id) {
      myRole {
        id
        team
      }
    }
  }
`;

export const updateTeamMemberMutation = gql`
  mutation UpdateTeamMember($id: ID!, $attributes: TeamMemberInput!) {
    updateTeamMember(id: $id, attributes: $attributes) {
      teamMember {
        ...TeamMemberFragment,
      }
      error {
        code
        message
      }
    }
  }
  ${teamMemberFragment}
`;

export const inviteTeamMemberMutation = gql`
  mutation InviteTeamMember($nonprofitId: NonprofitSlug!, $attributes: NewInvitationInput!, $roleId: ID!) {
    inviteTeamMember(nonprofitId: $nonprofitId, attributes: $attributes, roleId: $roleId) {
      teamMember {
        ...TeamMemberFragment,
      }
      error {
        code
        message
      }
    }
  }
  ${teamMemberFragment}
`;

export const resendInvitationMutation = gql`
  mutation ResendInvitation($teamMemberId: ID!) {
    resendInvitation(teamMemberId: $teamMemberId) {
      teamMember {
        ...TeamMemberFragment
      }
      error {
        code
        message
      }
    }
  }
  ${teamMemberFragment}
`;

export const removeTeamMemberMutation = gql`
  mutation RemoveTeamMember($id: ID!) {
    removeTeamMember(id: $id) {
      teamMemberId
      error {
        code
        message
      }
    }
  }
`;
