import React from "react";
import { map, prop, includes } from "ramda";

import NumberSelectField from './NumberSelectField';
import NumberField from "./NumberField";

const BONUS_OPTIONS = [
  { id: 20,  name: "20%" },
  { id: 25,  name: "25%" },
  { id: 30,  name: "30%" },
  { id: 35,  name: "35%" },
  { id: 40,  name: "40%" },
  { id: 50,  name: "50%" },
  { id: 100, name: "100%" },
];

export const isLegacy = promoCode => {
  const values = map(prop('id'), BONUS_OPTIONS);
  const bonusPercent = promoCode.bonusPercent;
  return !includes(bonusPercent, values);
}

const BonusPercentageSelectField = ({ inUse, legacyValue, disabled, ...rest}) =>
  legacyValue ? (
    <NumberField {...rest} disabled />
  ) : (
    <NumberSelectField {...rest} disabled={inUse || disabled} options={BONUS_OPTIONS} />
  );

export default BonusPercentageSelectField;
