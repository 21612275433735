import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import CssReset from "../../lib/CssReset";
import AuthProvider from "../../lib/AuthProvider";
import ErrorHandler from "../../lib/ErrorHandler";
import AuthorizedApolloProvider from "../../lib/AuthorizedApolloProvider";
import Snackbar from "../../lib/Snackbar";

const AppShell = ({ children }) => (
  <Router>
    <CssReset />
    <AuthProvider>
      <ErrorHandler>
        <AuthorizedApolloProvider>
          <Snackbar>
            {children}
          </Snackbar>
        </AuthorizedApolloProvider>
      </ErrorHandler>
    </AuthProvider>
  </Router>
);

export default AppShell;
