import React, { useContext, useEffect } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { isNil, path } from "ramda";
import { useMutation } from "@apollo/client";

import { isPresent } from "../../../util/presence";
import { removeTeamMemberMutation } from "../gql";

import { Context as SnackbarContext } from "../../../lib/Snackbar";
import AccessControlledButton from "../../../lib/AccessControlledButton";

const RemoveTeamMemberButton = ({ teamMember, setLoading }) => {
  const [ removeTeamMember, { loading } ] = useMutation(removeTeamMemberMutation, {
    update: (cache, results) => {
      const teamMemberId = path(['data', 'removeTeamMember', 'teamMemberId'], results);

      if (isNil(teamMemberId)) {
        return
      }

      const cacheId = cache.identify({ id: teamMemberId, __typename: "TeamMember" });
      cache.evict(cacheId);
      cache.gc();
    }
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading])

  const { addMessage } = useContext(SnackbarContext);

  const handleRemoveTeamMember = () => {
    if (!window.confirm(`Are you sure you want to remove ${teamMember.name}? This action cannot be undone.`)) {
      return;
    }

    removeTeamMember({ variables: { id: teamMember.id } }).then(({ data, error }) => {
      if (isPresent(path(['removeTeamMember', 'teamMemberId'], data))) {
        addMessage({ message: "Successfully removed team member", type: "success" });
      } else {
        addMessage({ message: "Failed to remove team member. Contact support if the issue persists", type: "error" });
      }
    }).catch(() => {
      addMessage({ message: "Failed to remove team member. Contact support if the issue persists", type: "error" });
    });
  }

  if (!teamMember.removable) {
    return null
  }

  return (
    <AccessControlledButton
      icon={faTrash}
      intent="destructive"
      size="small"
      onClick={handleRemoveTeamMember}
      disabled={loading}
      resource="team"
      permission="D"
    >
      Remove Team Member
    </AccessControlledButton>
  );
};

export default RemoveTeamMemberButton;
