import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 12px;
`;

export const Header = styled.header`
  display: flex;
  padding: 0 6px;
`;

export const HeaderActions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  > :not(:last-child) {
    margin-right: 18px;
  }
`;

export const Body = styled.div`
  flex: 1 1 0;
  overflow-y: auto;
  background-color: #ffffff;
  padding: 12px;
  border-radius: 6px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 6px;
`;

export const FooterActions = styled.div`
  display: flex;
  gap: 12px;
`;

export const Heading = styled.div`
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: 1fr auto;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export const HeadingAside = styled.div`
  text-align: right;

  @media (max-width: 1024px) {
    text-align: unset;
  }
`;

export const Name = styled.div`
  font-size: 1.75em;
  font-weight: bold;
  color: #333333;
  font-family: tondo, sans-serif;
`;

export const Date = styled.div`
  font-size: 0.75em;
`;

export const Payment = styled.div`
  font-weight: bold;
  color: #666666;
  font-size: 0.875em;
`;

export const Amount = styled.div`
  position: relative;
  background-color: ${props => props.status === 'paid' ? "#77aa16" : "#aaaaaa" };
  color: #ffffff;
  padding: 12px 24px;
  text-align: center;
  font-weight: bold;
  font-size: 1.75em;
  margin-bottom: 12px;
  text-transform: uppercase;
`;

export const ReferenceId = styled.div`
  text-align: right;
  padding: 0 12px 12px;
  color: #aaa;
  font-size: 0.75em;
`;

export const Field = styled.div`
  margin-bottom: 24px;
  padding: 0 6px;
`;

export const Label = styled.div`
  color: #666666;
  font-size: 0.75em;
`

export const Value = styled.div`
  color: #333333;
  padding-left: 6px;
`;
