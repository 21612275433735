import { gql } from "@apollo/client";

export const pagesSetupQuery = gql`
  query SetupSweepstakesPages($sweepstakesId: SweepstakesSlug!) {
    sweepstakes(id: $sweepstakesId) {
      id
      approved
      timezone
      publicUrl
      prize {
        id
        name
      }
    }
  }
`;
