import React from "react";
import { useParams } from "react-router-dom";

import { withAccessControl } from "../../lib/permissions";
import PromoCodeList from "../PromoCodeList";

const PromoCodePage = () => {
  const { sweepstakesId } = useParams();

  return <PromoCodeList sweepstakesId={sweepstakesId} />;
}

export default withAccessControl({ resource: "promoCodes" })(PromoCodePage);
