import { gql } from "@apollo/client";

export const performanceTimelineInsightQuery = gql`
  query($sweepstakesId: SweepstakesSlug!) {
    sweepstakes(id: $sweepstakesId) {
      id
      insights {
        id
        monthly: performanceTimeline(resolution: MONTHLY) {
          data {
            bucket
            total
            count
            average
          }
        }
        weekly: performanceTimeline(resolution: WEEKLY) {
          data {
            bucket
            total
            count
            average
          }
        }
        daily: performanceTimeline(resolution: DAILY) {
          data {
            bucket
            total
            count
            average
          }
        }
        donationLevelBreakdown {
          data {
            total
            count
            donationLevel {
              id
              level
              price
            }
          }
        }
      }
    }
  }
`;
