import { gql } from "@apollo/client";

export const getPromoCodes = gql`
  query($sweepstakesId: SweepstakesSlug!) {
    sweepstakes(id: $sweepstakesId) {
      id
      promoCodes {
        id
        name
        bonusPercent
      }
    }
  }
`;

export const retroApplyPromoCodeMutation = gql`
  mutation RetroApplyPromoCode($entryId: ID!, $promoCodeId: ID!) {
    retroApplyPromoCode(entryId: $entryId, promoCodeId: $promoCodeId) {
      entry {
        id
        createdAt
        amount
        ticketCount

        ticketRanges {
          min
          max
        }

        promoCode {
          id
          name
        }
      }
      error {
        code
        message
      }
    }
  }
`;
