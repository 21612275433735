import React from "react";
import { faLock } from "@fortawesome/free-solid-svg-icons";

import Link from "../components/design/Link";
import { usePermissions } from "./permissions";

const AccessControlledLink = ({ resource, permission = "R", ...rest }) => {
  const accessEnabled = usePermissions({ resource, permission });

  const icon = !accessEnabled ? faLock : rest.icon;
  const disabled = rest.disabled || !accessEnabled;

  return <Link {...rest} disabled={disabled} icon={icon} />
};

export default AccessControlledLink;
