import React from 'react';
import { Navigate, useLocation } from "react-router-dom";

const ApplicationSuccess = () => {
  const { state } = useLocation();

  if(!state?.accountApplicationId) {
    return <Navigate to="/" />;
  }

  return (
    <p>Thank you for applying to join the TapKat community of sweepstakes fundraisers. We will review your application and get in touch with you within 1 business day.</p>
  );
}

export default ApplicationSuccess;
