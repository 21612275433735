import React from "react";
import { Outlet } from "react-router-dom";

import * as UI from "./ui";

const PublicLayout = ({ children }) => (
  <UI.Layout>
    <UI.Header>
      <img src="https://assets.tapkat.org/marketing/v2/tapkat-logo.png" alt="TapKat" style={{ width: "160px" }} />
    </UI.Header>
    <Outlet />
  </UI.Layout>
);

export default PublicLayout;
