import { gql } from "@apollo/client";

export const nonprofitQuery = gql`
  query NonprofitQuery($id: NonprofitSlug!) {
    nonprofit(id: $id) {
      id
      name
    }
  }
`;
