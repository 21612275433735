import { gql } from "@apollo/client";

export const searchQuery = gql`
  query SearchQuery($search: String!) {
    sweepstakeses(search: $search) {
      id
      prize {
        id
        name
      }

      nonprofit {
        id
        name
      }
    }

    nonprofits(search: $search) {
      id
      name
      ein
    }
  }
`;
