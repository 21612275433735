import styled from "styled-components";

export const WinnerCardLayout = styled.div`
  min-width: 280px;
  background-color: #fff;
  border-radius: 6px;
  padding: 12px;

  @media screen and (max-width: 575px) {
    width: 100%;
  }

  @media screen and (min-width: 576px) {
    min-width: 400px;
  }
`;

export const Title = styled.div`
  font-size: 1em;
  font-weight: bold;
  margin: 0 6px 6px;
`;

export const Number = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d4f90;
  border-radius: 6px;
  color: #ffffff;
  font-size: 1.25em;
  padding: 6px;
  line-height: 1em;
  font-family: tondo, sans-serif;

  ${({ $grand }) => $grand && `
    font-size: 3em;
    font-weight: bold;
    padding: 12px;
  `}

  &:before {
    content: "#"
  }

  &:empty {
    &:before {
      content: "#0000";
      color: rgba(255,255,255,0.5);
    }
  }
`;

export const Winner = styled.div`
  font-size: 0.875em;
  margin: 6px 6px 0;
`;

export const Name = styled.div`
  font-weight: bold;

  &:empty {
    height: 1em;
    width: 75%;
    background-color: #eeeeee;
    margin-bottom: 6px;
  }

  ${({ $grand }) => $grand && `
    font-size: 1.125em;
  `}
`;

export const Location = styled.div`
  &:empty {
    height: 1em;
    width: 85%;
    background-color: #eeeeee;
    margin-bottom: 6px;
  }
`;

export const Phone = styled.div`
  &:empty {
    height: 1em;
    width: 60%;
    background-color: #eeeeee;
    margin-bottom: 6px;
  }
`;

export const Email = styled.div`
  &:empty {
    height: 1em;
    width: 50%;
    background-color: #eeeeee;
    margin-bottom: 6px;
  }
`;

export const WinnerDetails = styled.div`
  font-size: 0.875em;

  strong {
    font-weight: bold;
  }
`;
