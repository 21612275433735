import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import { sweepstakesWidgetGuideQuery } from "./gql";
import { isBlank } from "../../util/presence";

import Summary from "./Summary";
import Guide from "./Guide";

import * as UI from "./ui";

const WidgetSetupGuidePage = () => {
  const { sweepstakesId } = useParams();
  const { data, loading } = useQuery(sweepstakesWidgetGuideQuery, {
    variables: {
      id: sweepstakesId,
    },
  });

  if (loading || isBlank(data.sweepstakes)) {
    return null;
  }

  const sweepstakes = data.sweepstakes;

  return (
    <UI.Layout>
      <UI.Section>
        <Summary />
      </UI.Section>
      <UI.Section>
        <Guide sweepstakes={sweepstakes} />
      </UI.Section>
    </UI.Layout>
  );
};

export default WidgetSetupGuidePage;
