import React from "react";

import SelectField from "./SelectField";

const PAYMENT_METHODS = [
  { id: "CASH", name: "Cash" },
  { id: "CHECK", name: "Check" },
  { id: "CARD", name: "Card" },
];

const PaymentMethodSelectField = (props) => <SelectField {...props} options={PAYMENT_METHODS} />

export default PaymentMethodSelectField;
