import styled from "styled-components";

const columnWidths = {
  label: "auto",
  total: "1fr",
  count: "1fr",
  average: "auto",
  percentage: "1fr",
};

const gridTemplateColumns = ({ $columns }) => Object.keys(columnWidths).filter(c => $columns.includes(c)).map(c => columnWidths[c]).join(" ");

export const Table = styled.div`
  width: 100%;
  overflow-y: scroll;
  display: grid;
  gap: 3px;
  font-size: 0.875em;
  grid-template-columns: ${gridTemplateColumns};
`;

const fillBackground = (color, flip) => ({ $fill }) => $fill !== undefined && `
  linear-gradient(${flip ? "270" : "90"}deg, ${color} ${$fill}%, rgba(0,0,0,0) ${$fill}%)
`;

const Cell = styled.div`
  padding: 0 6px;
  border-radius: 6px;
  transition: opacity 100ms ease-in-out;

  ${({ $selected, $anySelection }) => $anySelection && `
    opacity: ${$selected ? "1" : "0.4"};
  `}
`;

export const Label = styled(Cell)`
`;

export const Total = styled(Cell)`
  border: 1px solid #EBF9D1;
  background: ${fillBackground("#EBF9D1", true)};
`;

export const Count = styled(Cell)`
  border: 1px solid #EDF6FB;
  background: ${fillBackground("#EDF6FB")};
`;

export const Average = styled(Cell)`
  color: #7d5ea3;
`;

export const Percentage = styled(Cell)`
  border: 1px solid #cec2dc;
  background: ${fillBackground("#cec2dc")};
`;
