import { gql } from "@apollo/client";

const winningEntryFragment = gql` 
  fragment WinningEntryFragment on Entry {
    id
    amount
    firstName
    lastName
    email
    phoneNumber
    address {
      city
      state
    }
    promoCode {
      id
      name
    }
    entrantDonationTotal
    entrantDonationCount
  }
`


const prizeWinnerFragment = gql`
  fragment PrizeWinnerFragment on Sweepstakes {
    id
    winnerPresentationUrl
    prize {
      id
      name
      win {
        id
        createdAt
        confirmedAt
      }
      winningTicket {
        id
        number
      }
      winningEntry {
        ...WinningEntryFragment
      }
    }
  }
  ${winningEntryFragment}
`;

export const additionalPrizeFragment = gql`
  fragment AdditionalPrizeFragment on AdditionalPrize {
    id
    name
    createdAt
    confirmedAt

    winningTicket {
      id
      number
    }
    winningEntry {
      ...WinningEntryFragment
    }
  }
  ${winningEntryFragment}
`;

export const additionalPrizesFragment = gql`
  fragment AdditionalPrizesFragment on Sweepstakes {
    additionalPrizes {
      ...AdditionalPrizeFragment
    }
  }
  ${additionalPrizeFragment}
`;

export const getWinnersQuery = gql`
  query GetWinner($sweepstakesId: SweepstakesSlug!) {
    sweepstakes(id: $sweepstakesId) {
      id
      timezone
      winningTicketDrawnAt
        ...PrizeWinnerFragment
        ...AdditionalPrizesFragment
    }
  }
  ${additionalPrizesFragment}
  ${prizeWinnerFragment}
`;

export const autoDrawWinnerMutation = gql`
  mutation AutoDrawWinner($sweepstakesId: SweepstakesSlug!) {
    autoDrawWinner(sweepstakesId: $sweepstakesId) {
      sweepstakes {
        ...PrizeWinnerFragment
      }
      error {
        code
        message
      }
    }
  }
  ${prizeWinnerFragment}
`;

export const confirmWinnerMutation = gql`
  mutation ConfirmWinner($sweepstakesId: SweepstakesSlug!) {
    confirmWinner(sweepstakesId: $sweepstakesId) {
      sweepstakes {
        ...PrizeWinnerFragment
      }
    }
  }
  ${prizeWinnerFragment}
`;

export const redrawWinnerMutation = gql`
  mutation RedrawWinner($sweepstakesId: SweepstakesSlug!) {
    redrawWinner(sweepstakesId: $sweepstakesId) {
      sweepstakes {
        ...PrizeWinnerFragment
      }
    }
  }
  ${prizeWinnerFragment}
`;

export const drawAdditionalPrizeMutation = gql`
  mutation DrawAdditionalPrize($sweepstakesId: SweepstakesSlug!, $attributes: AdditionalPrizeInput!) {
    drawAdditionalPrize(sweepstakesId: $sweepstakesId, attributes: $attributes) {
      additionalPrize {
        ...AdditionalPrizeFragment
      }
      error {
        code
        message
      }
    }
  }
  ${additionalPrizeFragment}
`;

export const confirmAdditionalPrizeMutation = gql`
  mutation ConfirmAdditionalPrize($id: ID!) {
    confirmAdditionalPrize(id: $id) {
      additionalPrize {
        ...AdditionalPrizeFragment
      }
      error {
        code
        message
      }
    }
  }
  ${additionalPrizeFragment}
`;

export const redrawAdditionalPrizeMutation = gql`
  mutation RedrawAdditionalPrize($id: ID!) {
    redrawAdditionalPrize(id: $id) {
      additionalPrize {
        ...AdditionalPrizeFragment
      }
      error {
        code
        message
      }
    }
  }
  ${additionalPrizeFragment}
`;
