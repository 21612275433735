import styled from "styled-components";

export const ItemLayout = styled.div`
  display: flex;
`;

export const EntrantLayout = styled.div`
  flex: 1;
`;

export const Name = styled.div`
  font-weight: bold;
`;

export const Email = styled.div`
  font-size: 0.875em;
  color: #aaaaaa;
  display: inline-block;
`;

export const EntryDataLayout = styled.div`
  text-align: right;
`;

export const Date = styled.div`
  color: #aaaaaa;
  font-size: 0.875em;
`;

const amountBackgroundColor = ({ status, amount }) => {
  if(["DISPUTED", "REFUNDED"].includes(status)) {
    return "#db3a34";
  }

  return amount > 0 ? "#77aa16" : "#aaaaaa";
};

export const Amount = styled.div`
  display: inline-block;
  padding: 3px 6px;
  background-color: ${amountBackgroundColor};
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  min-width: 96px;
  text-align: center;
  border-radius: 3px;
  font-size: 0.875em;
`;
