import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { withAccessControl } from "../../lib/permissions";
import Editor from "./Editor";
import { sweepstakesQuery } from "./gql";

const SweepstakesEditPage = () => {
  const { sweepstakesId } = useParams();
  const { loading, error } = useQuery(sweepstakesQuery, {
    variables: {
      id: sweepstakesId,
    }
  });

  if(loading || error) {
    return null;
  }

  return <Editor />;
}

export default withAccessControl({ resource: "sweepstakes", permission: "U" })(SweepstakesEditPage);
