import styled from "styled-components";
import { Image } from "cloudinary-react";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  margin: 12px 0 24px;

  ${({ $fullscreen }) => $fullscreen && `
    flex: 1;
    align-items: center;
  `}
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  overflow-y: auto;

  @media (max-width: 1038px) {
    justify-content: center;
  }
`

export const Card = styled.div`
  border-radius: 6px;
  width: 330px;
  position: relative;
`;

const statusColor = ({ $status }) => {
  switch ($status) {
    case "Confirm Your Winner":
      return "#db3a34";
    case "Draw Your Winner":
      return "#ffae00";
    case "Active":
      return "#77aa16";
    case "Complete":
      return "#1d4f90";
    case "Under Construction":
      return "#7d53a3";
    default:
      return "#ffffff";
  }
}

export const Status = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  right: 0;
  padding: 3px 24px;
  font-size: 0.875em;
  color: #ffffff;
  background-color: ${statusColor};
  border-radius: 3px 0 0 3px;
  font-weight: bold;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
  user-select: none;
  pointer-events: none;
  opacity: 0.9;
`;

export const SweepstakesImage = styled(Image)`
  display: block;
  border-radius: 6px 6px 0 0;
`;

export const SweepstakesImagePlaceholder = styled.div`
  width: 330px;
  height: 186px;
  background-color: #67778d;
  border-radius: 6px 6px 0 0;
`;

export const Details = styled.div`
  padding: 6px;
  background-color: #ffffff;
  border-radius: 0 0 6px 6px;
  display: flex;
  flex-direction: column;
`;

export const PrizeName = styled.div`
  font-weight: bold;
  font-family: tondo, sans-serif;
  font-size: 0.875em;
  color: #333333;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 330px;
`;

export const ScheduleRange = styled.div`
  color: #666666;
  margin-bottom: 12px;
`;

export const NewSweepstakesForm = styled.div`
  padding: 24px;
  background: #ffffff;
  border-radius: 6px;
`;

export const NewSweepstakesHeading = styled.h2`
  font-family: tondo, sans-serif;
  font-size: 1.25em;
  color: #333333;
`;

export const NewSweepstakesFormHint = styled.div`
  font-size: 0.875em;
  margin-bottom: 12px;
`;
