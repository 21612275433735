import { gql } from "@apollo/client";

export const getInvitationQuery = gql`
  query GetInvitation($invitationToken: InvitationToken!) {
    invitation(invitationToken: $invitationToken) {
      id
      email
      name
      requiresSession
    }
  }
`;

export const acceptInvitationMutation = gql`
  mutation AcceptInvitation($invitationToken: InvitationToken!, $attributes: AcceptInvitationInput) {
    acceptInvitation(invitationToken: $invitationToken, attributes: $attributes) {
      error {
        code
        message
      }
    }
  }
`;
