import React, { useState, useMemo } from "react";

import { insightStatsSummary } from "../../../../util/insights";

import RadioControl from "../../../design/RadioControl";
import StatSummary from "../../../design/StatSummary";
import StatBreakdown from "../../../StatBreakdown";
import * as UI from "./ui";

const RESOLUTIONS = [
  { id: "monthly", name: "Monthly", unit: "Month" },
  { id: "weekly",  name: "Weekly",  unit: "Week"  },
  { id: "daily",   name: "Daily",   unit: "Day"   },
];

const DEFAULT_RESOLUTION = "weekly";

const InsightCard = ({ daily, weekly, monthly }) => {
  const [ resolution, setResolution ] = useState(DEFAULT_RESOLUTION);
  const series = { monthly, weekly, daily }[resolution];
  const statSummary = useMemo(() => insightStatsSummary(series), [series]);

  return (
    <UI.Layout>
      <StatSummary {...statSummary} size="large" />
      <StatBreakdown series={series} />
      <RadioControl options={RESOLUTIONS} defaultOption={DEFAULT_RESOLUTION} onChange={setResolution} selectedColor="#DEDEDE" />
    </UI.Layout>
  );
};

export default InsightCard;
