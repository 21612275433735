export const formatPercent = percentString => `${percentString}%`;

const ORDINAL_SUFFIXES = ["th", "st", "nd", "rd"];

export const ordinalize = n => {
  const moduloPosition = n % 100;

  // Subtracing 20 from the modulo makes sure numbers from 20-99 use *0th, *1st, *2nd, *3rd.
  // Numbers 4-19 and *4-*9 all use "th" from the fallback on index out of range
  const suffixIndex = moduloPosition > 20 ? (moduloPosition - 20) % 10 : moduloPosition;
  return `${n}${ORDINAL_SUFFIXES[suffixIndex] || "th"}`;
};

export { formatPercent as default };
