import { gql } from "@apollo/client";

export const connectStripeMutation = gql`
  mutation ConnectStripe($code: String!, $state: String!) {
    connectStripe(code: $code, state: $state) {
      nonprofit {
        id
        paymentAccountConnected
      }
      error {
        code
        message
      }
    }
  }
`;
