const emails = {
  sweepstakesApproved: {
    permission: { resource: "sweepstakes", permission:  "C" },
    label: "Sweepstakes Approved",
    description: `
      Sent when your sweepstakes has been approved by TapKat and is activated (ready to take donations).
    `,
  },
  sweepstakesSchedule: {
    permission: { resource: "sweepstakes", permission:  "R" },
    label: "Sweepstakes Email Smart Schedule",
    description: `
      Sent after your sweepstakes is approved and active. The Sweepstakes Email Smart Schedule is a
      suggested schedule for send promotional emails.
    `,
  },
  sweepstakesEmailBlastReminder: {
    permission: true,
    label: "Sweepstakes Email Smart Schedule Reminders",
    description: `
      Sends a reminder to send a promotional email 3 days before each suggested date on your Email
      Smart Schedule.
    `,
  },
  tapkatWin: {
    permission: { resource: "sweepstakes", permission:  "C" },
    label: "TapKat.win Free Listing Invite",
    description: `
      Invite to list your sweepstakes on the donor-facing site, TapKat.win. The invite is sent when
      your sweepstakes has received $1,000 in donations.
    `,
  },
};

export default emails;
