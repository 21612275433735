import React from "react";
import styled from "styled-components";

const Section = styled.div`
  border-bottom: 1px solid #eeeeee;
  display: flex;
  flex-direction: column;
  padding: 36px 0;

  &:last-child {
    border-bottom: none;
  }
`;

const Heading = styled.h2`
  color: #1d4f90;
  font-family: tondo;
  font-weight: 400;
  margin: 0 0 36px;
`;

const SignPost = ({ heading, children }) => (
  <Section>
    { heading && <Heading>{heading}</Heading> }
    { children }
  </Section>
);

export default SignPost;
