import React, { useContext, useState } from "react";
import { isNil, path } from "ramda"
import { useMutation } from "@apollo/client";

import { Context as SnackbarContext } from "../../../lib/Snackbar";
import AccessControlledButton from "../../../lib/AccessControlledButton";
import { isBlank } from "../../../util/presence";
import { refundManualEntryMutation } from "./gql";
import { totalDonationsQuery } from "../../TotalDonations/gql";

const mutationSucceeded = mutation =>
  isNil(mutation.error) && isBlank(path(['data','refundManualEntry','error'], mutation));

const RefundManualEntryButton = ({ entry }) => {
  const [ refundManualEntry, { called, loading } ] = useMutation(refundManualEntryMutation, {
    refetchQueries: [ totalDonationsQuery ],
  });
  const { addMessage } = useContext(SnackbarContext);
  const [ hidden, setHidden ] = useState(false);

  if(!called && entry.status !== "MANUAL") {
    return null;
  }

  const onClick = () => {
    if(window.confirm("Are you sure you want to mark this entry as refunded?")) {
      refundManualEntry({
        variables: {
          id: entry.id,
        }
      }).then(mutation => {
        if (mutationSucceeded(mutation)) {
          addMessage({ type: "success", message: "The entry was successfully marked as refunded." })
          setHidden(true);
        } else {
          addMessage({ type: "error", message: "There was an issue marking this entry as refunded." })
        }
      });
    }
  }

  if (hidden) {
    return null;
  }

  return (
    <AccessControlledButton size="small" type="submit" disabled={loading} onClick={onClick} resource="entries" permission="U">
      Mark as Refunded
    </AccessControlledButton>
  );
}

export default RefundManualEntryButton;
