import React from "react";

import * as UI from "./ui";

const Hints = () => (
  <UI.Layout>
    <UI.Title>Getting Started</UI.Title>
    <UI.Paragraph>The Flash Contest page allows you to setup and schedule flash contests ahead of time. The flash contest will automatically launch and end on the dates you set. Once your Flash Contest is underway, you’ll be able to track the results here. For more information on Flash Contests and how they work, <a href="https://www.tapkat.com/blog/how-to-use-a-flash-contest-to-boost-donations" target="_blank" rel="noopener noreferrer">see this blog post</a>.</UI.Paragraph>

    <UI.HintList>
      <UI.Hint><strong>Use the Promo Codes page to setup a Flash Contest Promo Code:</strong> This is optional but highly recommended. Set the promo code expiration date to match the end date for your Flash Contest.</UI.Hint>
      <UI.Hint><strong>Click “Add Flash Contest”</strong>: To setup a new Flash Contest, click the Add Flash Contest button.</UI.Hint>
      <UI.Hint><strong>Name your Flash Contest</strong>: This name is for your reference in the list of Flash Contests</UI.Hint>
      <UI.Hint><strong>Create the Announcement Banner</strong>: Add an announcement text banner that will appear above your image slides. Include the name of the prize, the promo code and the end date of the contest. The announcement will automatically appear on the  start date and be removed when it ends.</UI.Hint>
      <UI.Hint><strong>Set Start and End Date</strong>: Start dates begin at 12:00am and the end date will end at 11:59pm of your sweepstakes time zone.</UI.Hint>
      <UI.Hint><strong>Upload Prize Image for Slider</strong>: The image will be placed as slide #1 in your sweepstakes site slider. It will automatically appear on your start date and be removed when your flash contest ends.</UI.Hint>
      <UI.Hint><strong>Write Slide Caption</strong>: The caption should describe the prize and include the promo code.</UI.Hint>
      <UI.Hint><strong>Internal Notes</strong>: Add any notes about the contest that will be useful for you and your team.</UI.Hint>
      <UI.Hint><strong>Draw the Flash Contest Winner</strong>: Draw the winner the day after your flash contest ends. Click the blue Draw the Winner button. The random number generator will pick the winning entry from all entries made between the contest start and end dates. The winning entry and winner’s name will be displayed in the FAQ section of your sweepstakes site.</UI.Hint>
    </UI.HintList>
  </UI.Layout>
);

export default Hints;
