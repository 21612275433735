import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { prop } from "ramda";

import { ScheduleGuard } from "../../lib/schedule";
import { withAccessControl } from "../../lib/permissions";
import DrawingTool from "./DrawingTool";
import { getWinnersQuery } from "./gql";

import * as UI from "./ui";

const WinnerPage = () => {
  const { sweepstakesId } = useParams();
  const { loading, error, data } = useQuery(getWinnersQuery, {
    variables: { sweepstakesId },
  });

  if (loading || error) {
    return null;
  }

  const sweepstakes = prop("sweepstakes", data);

  if (!sweepstakes) {
    return null;
  }

  return (
    <UI.Layout>
      <ScheduleGuard enabledPeriods={["drawn", "archived"]}>
        <DrawingTool sweepstakes={sweepstakes} />
      </ScheduleGuard>
    </UI.Layout>
  );
};

export default withAccessControl({ resource: "winners", permission: "R" })(WinnerPage);
