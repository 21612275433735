import { DateTime } from "luxon";
import { isNil } from "ramda";

const MILLISECONDS_PER_SECOND = 1000;

export const dateFromTimestamp = timestamp =>
  timestamp <= 0 ? null : new Date(timestamp * MILLISECONDS_PER_SECOND);

export const formatDate = timestamp =>
  timestamp <= 0
    ? null
    : dateFromTimestamp(timestamp)
        .toDateString()
        .substring(3);

const currentDateAsInteger = () =>
  Date.now().valueOf() / MILLISECONDS_PER_SECOND;

export const hasDatePassed = dateAsInteger =>
  dateAsInteger && currentDateAsInteger() > dateAsInteger;

export const sweepstakesDateTime = (sweepstakes, dateTime) => (
  isNil(dateTime) ? null : (
    DateTime
      .fromISO(dateTime, { zone: sweepstakes.timezone })
      .toLocaleString(DateTime.DATETIME_FULL)
  )
);

export const shortDateTime = (sweepstakes, dateTime) => (
  isNil(dateTime) ? null : (
    DateTime
      .fromISO(dateTime, { zone: sweepstakes.timezone })
      .toLocaleString(DateTime.DATETIME_SHORT)
  )
);

export const shortDate = (sweepstakes, dateTime) => (
  isNil(dateTime) ? null : (
    DateTime
      .fromISO(dateTime, { zone: sweepstakes.timezone })
      .toLocaleString(DateTime.DATE_SHORT)
  )
);
