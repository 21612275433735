import React from "react";
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'

import Menu from "../../lib/Menu";
import Button from "../design/Button";
import SweepstakesMenu from "../SweepstakesMenu";
import Heading from "./Heading";
import QuickLinks from "./QuickLinks";
import * as UI from "./ui";

const Header = ({ sweepstakes }) => {
  return (
    <UI.Header>
      <Menu label={<Button icon={faEllipsisV} size="medium" type="submit">Menu</Button>} hotkey="m">
        <SweepstakesMenu sweepstakes={sweepstakes} showHotkeys />
      </Menu>
      <Heading title={sweepstakes.prize.name} />
      <QuickLinks sweepstakes={sweepstakes} />
    </UI.Header>
  );
}

export default Header;
