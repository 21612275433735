import React from "react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import ActivePromoCodes from "../ActivePromoCodes";
import Link from "../design/Link";
import * as UI from "./ui";

const MostActivePromoCodes = ({ sweepstakes }) => (
  <UI.Section style={{ gridArea: "promo-codes" }}>
    <UI.SectionHeading>Most Active Promo Codes</UI.SectionHeading>
    <ActivePromoCodes sweepstakes={sweepstakes} />
    <Link to="./promo-codes" icon={faArrowRight} size="small">Manage Promo Codes</Link>
  </UI.Section>
);

export default MostActivePromoCodes;
