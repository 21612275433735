import React from "react";
import { Navigate, useParams } from "react-router-dom";

import usePermissions from "./usePermissions";

const DefaultUnauthorizedComponent = () => {
  const { nonprofitId } = useParams();
  return <Navigate to={`/${nonprofitId}`} replace />;
};

const withAccessControl = ({ resource, permission = "R", UnauthorizedComponent = DefaultUnauthorizedComponent }) => Component => props => {
  const accessEnabled = usePermissions({ resource, permission });

  if(accessEnabled === null) {
    return null;
  }

  if(accessEnabled) {
    return <Component {...props } />;
  }

  return <UnauthorizedComponent {...props } />;
};

export default withAccessControl;
