import React, { useState, useMemo } from 'react';
import { last } from "ramda";

import useDrawingResume from "./useDrawingResume";
import { isBlank, isPresent } from '../../util/presence';
import IntroScreen from './IntroScreen';
import OverviewScreen from './OverviewScreen';
import DrawWinnerScreen from './DrawWinnerScreen';
import ConfirmWinnerScreen from './ConfirmWinnerScreen';
import AdditionalPrizeQueryScreen from './AdditionalPrizeQueryScreen';
import DrawAdditionalPrizeScreen from './DrawAdditionalPrizeScreen';
import ConfirmAdditionalPrizeScreen from './ConfirmAdditionalPrizeScreen';

export const MAX_ADDITIONAL_PRIZES = 5;

const pendingAdditionalPrize = additionalPrizes => {
  if(additionalPrizes.length === 0) {
    return false;
  }

  const lastPrize = last(additionalPrizes);
  return isBlank(lastPrize?.confirmedAt);
}

const findResumeStage = (additionalPrizes, canResume) => {
  const pending = pendingAdditionalPrize(additionalPrizes);

  if (!pending && additionalPrizes.length === MAX_ADDITIONAL_PRIZES) {
    return 'OVERVIEW';
  }

  if (pending) {
    return 'CONFIRM_ADDITIONAL_PRIZE';
  }

  return canResume ? 'ADDITIONAL_PRIZE_QUERY' : 'OVERVIEW';
};

const DrawingTool = ({ sweepstakes, guarded }) => {
  const [canResume, setResume, clearResume] = useDrawingResume(sweepstakes.id, 60);

  const [stage, setStage] = useState('INTRO');
  const STAGES = useMemo(() => ({
    INTRO: {
      Component: IntroScreen,
      next: () => {
        const win = sweepstakes.prize?.win;
        const additionalPrizes = sweepstakes.additionalPrizes;

        if (isPresent(win?.confirmedAt)) {
          return findResumeStage(additionalPrizes, canResume);
        } else if (isPresent(win))  {
          return "CONFIRM_WINNER";
        } else {
          return "DRAW_WINNER";
        }
      }
    },
    OVERVIEW: {
      Component: OverviewScreen,
      next: () => 'DRAW_WINNER',
    },
    DRAW_WINNER: {
      Component: DrawWinnerScreen,
      next: () => 'CONFIRM_WINNER',
    },
    CONFIRM_WINNER: {
      Component: ConfirmWinnerScreen,
      next: () => 'ADDITIONAL_PRIZE_QUERY',
    },
    ADDITIONAL_PRIZE_QUERY: {
      Component: AdditionalPrizeQueryScreen,
      next: ({ answer }) => answer ? 'DRAW_ADDITIONAL_PRIZE' : 'OVERVIEW',
    },
    DRAW_ADDITIONAL_PRIZE: {
      Component: DrawAdditionalPrizeScreen,
      next: () => 'CONFIRM_ADDITIONAL_PRIZE',
    },
    CONFIRM_ADDITIONAL_PRIZE: {
      Component: ConfirmAdditionalPrizeScreen,
      next: () => {
        if (sweepstakes.additionalPrizes.length === MAX_ADDITIONAL_PRIZES || !canResume) {
          return 'OVERVIEW';
        }
        return 'ADDITIONAL_PRIZE_QUERY'
      }
    },
  }), [sweepstakes, canResume]);

  const nextStage = (...rest) => {
    const next = STAGES[stage].next(...rest);
    setStage(next);
  };

  const { Component } = STAGES[stage];
  return <Component sweepstakes={sweepstakes} next={nextStage} guarded={guarded} setResumeExpiry={setResume} clearResumeExpiry={clearResume} />
};

export default DrawingTool;
