import React from "react";

import PermissionSentence from "./PermissionSentence";
import * as UI from "./ui";

const RESOURCES = [
  'nonprofit',
  'sweepstakes',
  'entries',
  'promoCodes',
  'insights',
  'winners',
  'team',
  'paymentAccount',
];

const RoleDescription = ({ role, subject }) => {
  return (
    <UI.List>
      {RESOURCES.map(resource => (
        <PermissionSentence
          key={resource}
          resource={resource}
          permission={role[resource]}
          subject={subject}
        />
      ))}
    </UI.List>
  )
};

export default RoleDescription;
