import React, { useRef, useContext } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { faCopy, faDownload } from "@fortawesome/free-solid-svg-icons";

import { Context as SnackbarContext } from "../../../lib/Snackbar";
import QRCode from "../../../lib/QRCode";
import Button from "../../design/Button";
import * as UI from "./ui";

const AutoApplyControls = ({ name, autoApplyUrl, guarded }) => {
  const qrRef = useRef(null);
  const { addMessage } = useContext(SnackbarContext);

  if(!autoApplyUrl) {
    return null;
  }

  const onCopy = () => {
    addMessage({ message: "Auto-apply URL copied to clipboard.", type: "success" });
  }

  return (
    <UI.Layout>
      <UI.QR>
        <QRCode value={autoApplyUrl} fileName={name} ref={qrRef} />
      </UI.QR>
      <UI.ControlList>
        <UI.Input value={autoApplyUrl} readOnly />
        <CopyToClipboard text={autoApplyUrl} onCopy={onCopy}>
          <Button size="small" icon={faCopy}>Copy URL to Clipboard</Button>
        </CopyToClipboard>
        <Button size="small" icon={faDownload} onClick={() => qrRef.current.click()}>Download QR Code</Button>
      </UI.ControlList>
    </UI.Layout>
  );
}

export default AutoApplyControls;
