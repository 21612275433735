import styled from "styled-components";

export const ItemLayout = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0;
`;

export const Name = styled.span`
  font-weight: bold;
  font-size: 0.875em;
`;

export const Status = styled.span`
  margin-left: auto;
  padding: 3px 6px;
  font-size: 0.75em;
  font-weight: bold;
  min-width: 96px;
  text-align: center;
  border: 1px solid;
  border-radius: 3px;

  ${props => props.status === "PENDING" && `
    background-color: #ffffff;
    border-color: #7d53a3;
    color: #7d53a3;
  `}

  ${props => props.status === "ACCEPTED" && `
    background-color: #7d53a3;
    border-color: #7d53a3;
    color: #ffffff;
  `}

  ${props => props.status === "EXPIRED" && `
    background-color: #666666;
    border-color: #666666;
    color: #ffffff;
  `}
`;
