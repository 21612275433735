import React, { useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { path } from "ramda";

import { Context as SnackbarContext } from "../../../lib/Snackbar";
import { myRoleQuery, updateTeamMemberMutation } from "../gql";
import Formation from "../../forms/Form";
import SelectField from "../../forms/SelectField";
import Button from "../../design/Button";
import CurrentRoleDescription from "./CurrentRoleDescription";
import { presence } from "../../../lib/validations";

const validations = {
  roleId: presence,
};

const EditForm = ({ teamMember, nonprofit, setMode }) => {
  const { addMessage } = useContext(SnackbarContext);
  const [ updateTeamMember, { loading } ] = useMutation(updateTeamMemberMutation);
  const [ fetchMyRole, { loading: myRoleLoading } ] = useLazyQuery(myRoleQuery, {
    fetchPolicy: 'network-only',
    variables: {
      id: nonprofit.id,
    }
  });

  const onSubmit = inputObject => {
    updateTeamMember({
      variables: {
        id: teamMember.id,
        attributes: inputObject,
      },
    }).then(({ data }) => {
      const teamMember = path(['updateTeamMember', 'teamMember'], data);

      if(teamMember) {
        addMessage({ message: "Team member updated successfully.", type: "success" });
        setMode('show');
      } else {
        addMessage({ message: "An error occurred while updating the team member.", type: "error" });
      }
    }).catch(() => {
        fetchMyRole();
        addMessage({ message: "You do not have permission to manage your team.", type: "error" });
    });
  };

  return (
    <React.Fragment>
      <Formation initialInputObject={{ roleId: teamMember.role.id }} onSubmit={onSubmit} validations={validations}>
        <SelectField id="roleId" label="Role" options={nonprofit.roles} />
        <Button type="submit" disabled={loading || myRoleLoading}>Update Team Member</Button>
        <CurrentRoleDescription id="roleId" roles={nonprofit.roles} />
      </Formation>
    </React.Fragment>
  );
};

export default EditForm;
