import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { prop, isNil } from "ramda";
import { faCheckSquare, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from "../../design/Button";
import { Context } from "../../../lib/Snackbar";
import { setDonationLevelsMutation } from "./gql";
import * as UI from "./ui";

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const formatAsDollars = amount => formatter.format((amount / 100));

const MINIMUM_SELECTIONS = 4;
const MAXIMUM_SELECTIONS = 8;

const initialSelections = (sweepstakes, donationLevelPresets) => {
  const currentLevels = sweepstakes.donationLevels.map(prop('price'))

  const selections = donationLevelPresets.filter(preset => currentLevels.includes(preset.price)).map(prop('id'));

  return selections;
};

const DonationLevelsForm = ({ sweepstakes, donationLevelPresets, onStepFinish }) => {
  const { addMessage } = useContext(Context);
  const [ selections, setSelections ] = useState(initialSelections(sweepstakes, donationLevelPresets));
  const [ setDonationLevels, { loading } ] = useMutation(setDonationLevelsMutation);

  const onSubmit = () => {
    setDonationLevels({
      variables: {
        sweepstakesId: sweepstakes.id,
        donationLevelPresetIds: selections,
      }
    }).then(({ data }) => {
      if(isNil(data.setDonationLevels.error)) {
        addMessage({ message: "Your donation levels have been successfully updated.", type: "success" });
        onStepFinish();
      }
    })
  };

  const toggleSelection = id => {
    if(selections.includes(id)) {
      const index = selections.indexOf(id);
      const nextSelections = [...selections];
      nextSelections.splice(index, 1);
      setSelections(nextSelections);
    } else {
      setSelections([...selections, id]);
    }
  };

  const validCount = selections.length >= MINIMUM_SELECTIONS && selections.length <= MAXIMUM_SELECTIONS;
  const interactive = !sweepstakes.approved;
  const disabled =  !interactive || loading || !validCount;
  const options = interactive ? donationLevelPresets : donationLevelPresets.filter(preset => selections.includes(preset.id));

  return (
    <UI.StepLayout>
      <UI.StepContent>
        <UI.DonationLevelPresetList>
          {options.map(preset => (
            <UI.DonationLevelPresetListItem key={preset.id}>
              <UI.DonationLevelPreset
                disabled={!interactive}
                isSelected={selections.includes(preset.id)}
                onClick={() => toggleSelection(preset.id)}
              >
                { interactive && (
                  <UI.Icon>
                    <FontAwesomeIcon icon={selections.includes(preset.id) ? faCheckSquare : faSquare } />
                  </UI.Icon>
                )}
                <UI.Price>{formatAsDollars(preset.price)}</UI.Price>
                <UI.RewardTickets>{preset.rewardTickets} entries</UI.RewardTickets>
              </UI.DonationLevelPreset>
            </UI.DonationLevelPresetListItem>
          ))}
        </UI.DonationLevelPresetList>
        { interactive && (
          <UI.SelectionCountHint>
            <UI.HintIcon validCount={validCount}>
              <FontAwesomeIcon icon={faCheckCircle} />
            </UI.HintIcon>
            { validCount ?
              <UI.HintMessage>You have selected between {MINIMUM_SELECTIONS} and {MAXIMUM_SELECTIONS} donation amounts.</UI.HintMessage> :
              <UI.HintMessage>Please select between {MINIMUM_SELECTIONS} and {MAXIMUM_SELECTIONS} donation amounts to proceed.</UI.HintMessage>
            }
          </UI.SelectionCountHint>
        )}
        <Button type="submit" onClick={onSubmit} disabled={disabled}>Set Donation Amounts</Button>
      </UI.StepContent>
      <UI.Instructions>
        <h2>Setting Your Donation Amounts</h2>
        <p>Donation dollar amounts and the number of entries are standardized for all TapKat Sweepstakes. <strong>Choose at least 4 and up to 8 donation amounts</strong> by checking and/or unchecking the boxes in the form.</p>
        <p>Choose the donation amounts you believe will work for your donors and your type of prize. <strong>We recommend that you start with $25 for most sweepstakes</strong>.</p>
        <p><strong>Note:</strong> Your donation amounts can not be changed once your sweepstakes has been launched.</p>
      </UI.Instructions>
    </UI.StepLayout>
  );
};

export default DonationLevelsForm;
