import React from "react";

import ProgressGauge from "../design/ProgressGauge";
import Money from "../design/Money";

const ThresholdProgress = ({ sweepstakes }) => {
  const { threshold, totalDonations } = sweepstakes;

  if(!threshold) return null;

  const percentage = Math.round(100 * totalDonations / threshold);

  const subheading = (
    <React.Fragment>
      <Money amount={totalDonations} /> of <Money amount={threshold} />
    </React.Fragment>
  );

  return <ProgressGauge heading="Minimum" subheading={subheading} percentage={percentage} />
};

export default ThresholdProgress;
