import { gql } from "@apollo/client";

export const setupDashboardQuery = gql`
  query SetupDashboardQuery($nonprofitId: NonprofitSlug!) {
    nonprofit(id: $nonprofitId) {
      id
      paymentAccountConnected
      name
      sweepstakes {
        id
        approved
        beginsAt
        endsAt

        schedule {
          id
          drawn
        }

        prize {
          id
          name

          win {
            id
            confirmed
          }
        }

        slides {
          id
          image {
            publicId
            cloudName
            version
          }
        }
      }
    }
  }
`;

