import { useAuth0 } from "@auth0/auth0-react";

const LOGOUT_RETURN_PATH_KEY = "AUTH0_LOGOUT_RETURN_PATH";

export const getLogoutReturnPath = () => {
  const returnPath = window.localStorage.getItem(LOGOUT_RETURN_PATH_KEY);

  if(returnPath) {
    window.localStorage.removeItem(LOGOUT_RETURN_PATH_KEY);
  }

  return returnPath;
}

const useLogout = () => {
  const { logout } = useAuth0();

  return ({ returnPath, ...rest }) => {
    if(returnPath) {
      window.localStorage.setItem(LOGOUT_RETURN_PATH_KEY, returnPath);
    }

    return logout(rest);
  }
}

export default useLogout;
