import React from "react";

import * as UI from "./ui";

const Item = ({ item: teamMember }) => (
  <UI.ItemLayout>
    <UI.Name>{teamMember.name}</UI.Name>
    <UI.Status status={teamMember.invitationStatus}>
      {teamMember.invitationStatus}
    </UI.Status>
  </UI.ItemLayout>
);

export default Item;
