import React from 'react';
import { Routes, Route } from "react-router-dom";

import Apply from "./Apply";
import ApplicationSuccess from "./ApplicationSuccess";
import Complete from "./Complete";

const AccountApplicationPage = () => (
  <Routes>
    <Route path=":id" element={<Complete />} />
    <Route path="success" element={<ApplicationSuccess />} />
    <Route index element={<Apply />} />
  </Routes>
);

export default AccountApplicationPage;
