import React from "react";
import { useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { Scanner } from "@yudiel/react-qr-scanner";
import { pipe, pick, evolve } from "ramda";

import { decryptDataMutation } from "./gql";
import * as UI from "./ui";

const parseData = pipe(
  JSON.parse,
  pick(["firstName", "lastName", "email", "phoneNumber", "address"]),
  evolve({
    address: pick(["line1", "line2", "city", "state", "postalCode", "country"]),
  }),
);

const QuickDonationScanner = ({ onSuccess }) => {
  const { user }  = useAuth0();
  const [ decrypt ] = useMutation(decryptDataMutation);

  const onScan = result => {
    const encryptedData = result[0].rawValue;
    decrypt({ variables: { encryptedData } }).then(({ data }) => {
      const entryData = parseData(data.decryptData.data);
      entryData["notes"] = `From a QR code scanned by ${user.name} at ${new Date().toLocaleString()}.`;
      onSuccess(entryData);
    });
  };

  return (
    <React.Fragment>
      <UI.ScannerInstructions>
        Scan the donor's QR code to automatically fill in the donation form.
      </UI.ScannerInstructions>
      <UI.Scanner>
        <Scanner onScan={onScan} />
      </UI.Scanner>
    </React.Fragment>
  );
};

export default QuickDonationScanner;
