import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import SignPost from "../design/SignPost";
import Button from "../design/Button";

const AcceptWithRequiredAuth = () => {
  const { loginWithRedirect, isLoading } = useAuth0();

  if(isLoading) {
    return null;
  }

  const login = () => {
    loginWithRedirect({ appState: { returnTo: window.location.pathname }});
  };

  return (
    <SignPost heading="Sign in to your existing account to accept.">
      <Button size="small" onClick={login} type="submit">Sign In</Button>
    </SignPost>
  );
};

export default AcceptWithRequiredAuth;
