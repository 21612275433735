import React from "react";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import Link from "../design/Link";
import * as UI from "./ui";

const NonprofitSelector = ({ nonprofits }) => {
  return (
    <UI.Layout>
      <UI.List>
        {nonprofits.map(nonprofit => (
          <Link to={`/${nonprofit.id}/`} icon={faAngleRight}>
            {nonprofit.name}
          </Link>
        ))}
      </UI.List>
    </UI.Layout>
  );
}

export default NonprofitSelector;
