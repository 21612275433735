import { gql } from "@apollo/client";

export const nonprofitSweepstakesQuery = gql`
  query NonprofitSweepstakesQuery($nonprofitId: NonprofitSlug!) {
    nonprofit(id: $nonprofitId) {
      id
      invoices {
        id
      }
      sweepstakes {
        id
        approved
        prize {
          id
          name

          win {
            id
          }
        }

        slides {
          id
          image {
            publicId
            cloudName
            version
          }
        }
      }
    }
  }
`;
