import React from "react";

import useField from "../../lib/useField";
import * as UI from "./ui";

const NOOP = () => {};

const ClearFieldCheckbox = ({id, label, onChange = NOOP, disabled, ...rest}) => {
  const { setValue } = useField(id);

  const handleChange = e => {
    const checked = e.target.checked;

    checked && setValue({ target: { value: "" }});
    onChange(checked);
  }

  const localId = `clear-${id}-checkbox`;

  return (
    <UI.FieldLayout>
      <UI.Checkbox
        id={localId}
        onChange={handleChange}
        disabled={disabled}
        {...rest}
      />
      <UI.CheckboxLabel htmlFor={localId}>{label}</UI.CheckboxLabel>
    </UI.FieldLayout>
  );
};

export default ClearFieldCheckbox;
