import React, { useCallback } from "react";
import { isNil, omit, path } from "ramda";
import { useMutation } from "@apollo/client";

import { updateEmailPreferencesMutation } from "./gql";
import emails from "./emails";
import EmailPreferenceField from "./EmailPreferenceField";
import Snackbar from "../Snackbar";
import Form from "../forms/Form";
import Button from "../design/Button";

const buildInitialObject = omit(['__typename']);

const EditForm = ({ teamMember }) => {
  const [ update, { data, loading, error } ] = useMutation(updateEmailPreferencesMutation);

  const handleSubmit = useCallback(preferences => (
    update({
      variables: {
        teamMemberId: teamMember.id,
        preferences,
      }
    })
  ), [update, teamMember.id]);
  const success = !isNil(data) && isNil(path(['updateEmailPreferences','error'], data));
  const updateError = path(['updateEmailPreferences', 'error'], data);

  return (
    <React.Fragment>
      { (error || updateError) && <Snackbar type="error" message="An error occurred during form submission." /> }
      { success && <Snackbar type="success" message="Saved changes to your Nonprofit Profile." /> }
      <Form onSubmit={handleSubmit} initialInputObject={buildInitialObject(teamMember.emailPreferences)}>
        {Object.entries(emails).map(([id, email], i) => <EmailPreferenceField key={`preference-field-${i}`} id={id} email={email} />)}
        <Button type="submit" disabled={loading}>Save</Button>
      </Form>
    </React.Fragment>
  );
};

export default EditForm;
