import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { pathOr } from "ramda";

import AllTimeCard from "./AllTimeCard";
import InsightCard from "./InsightCard";
import DonationLevelInsightCard from "../DonationLevelInsightCard";
import { withAccessControl } from "../../lib/permissions";
import { insightStatsSummary } from "../../util/insights";
import { performanceTimelineInsightQuery } from "./gql";
import * as UI from "./ui";

const SweepstakesInsightsPage = () => {
  const { sweepstakesId } = useParams();
  const { data } = useQuery(performanceTimelineInsightQuery, { variables: { sweepstakesId } });

  const monthly = pathOr([], ["sweepstakes", "insights", "monthly", "data"], data);
  const weekly = pathOr([], ["sweepstakes", "insights", "weekly", "data"], data);
  const daily = pathOr([], ["sweepstakes", "insights", "daily", "data"], data);
  const donationLevelData = pathOr([], ["sweepstakes", "insights", "donationLevelBreakdown", "data"], data);

  const last7Days = daily.slice(-7);
  const last30Days = daily.slice(-30);

  return (
    <UI.Layout>
      <InsightCard heading="Last 7 Days" series={last7Days} {...insightStatsSummary(last7Days)} />
      <InsightCard heading="Last 30 Days" series={last30Days} {...insightStatsSummary(last30Days)} />
      <AllTimeCard heading="All Time" monthly={monthly} weekly={weekly} daily={daily} sweepstakesId={sweepstakesId} />
      <DonationLevelInsightCard data={donationLevelData} />
    </UI.Layout>
  );
};

export default withAccessControl({ resource: "insights" })(SweepstakesInsightsPage);
