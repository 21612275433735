import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { includes, pathOr, filter } from "ramda";

import MasterDetail from "../../lib/MasterDetail";
import { useSchedulePeriod } from "../../lib/schedule";
import { flashContestsQuery } from "./gql";
import Item from "./Item";
import Detail from "./Detail";
import NoSelection from "./NoSelection";

export const Context = React.createContext();

const FlashContestList = ({ sweepstakesId }) => {
  const [ searchInput, setSearchInput ] = useState(null);

  const period = useSchedulePeriod();
  const newItemEnabled = ["review", "open", "closing"].includes(period);

  const { loading, data } = useQuery(flashContestsQuery, {
    variables: {
      sweepstakesId,
    }
  });

  const flashContests = pathOr([], ["sweepstakes","flashContests"], data);
  const sweepstakes = pathOr([], ["sweepstakes"], data);

  const search = {
    auto: true,
    placeholder: "Search by name…",
    onSubmit: setSearchInput,
  };

  const searchFilter = flashContest => (
    searchInput.value ? includes(searchInput.value.toLowerCase(), flashContest.name.toLowerCase()) : true
  );

  const searchedFlashContests = searchInput ? filter(searchFilter, flashContests) : flashContests;
  const newItem = {
    label: "Add Flash Contest",
    resource: "flashContests",
    permission: "C",
    disabled: !newItemEnabled,
  };

  return (
    <MasterDetail
      items={searchedFlashContests}
      loading={loading}
      search={search}
      ListItemComponent={Item}
      DetailComponent={Detail}
      NoSelectionComponent={NoSelection}
      newItem={newItem}
      sweepstakes={sweepstakes}
    />
  );
}

export default FlashContestList;
