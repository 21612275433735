import { DateTime } from 'luxon';

export default class DateRange {
  constructor(startDate, endDate) {
    this.begin = DateTime.fromISO(startDate).startOf('day');
    this.end = DateTime.fromISO(endDate).endOf('day');
  }

  contains(date) {
    return date >= this.begin && date <= this.end;
  }
}
