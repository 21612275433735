import React from "react";
import { faBan, faCamera } from '@fortawesome/free-solid-svg-icons'

import * as UI from "./ui";

const DoNotRecord = () => (
  <UI.DoNotRecordLayout>
    <UI.CameraIcon icon={faCamera} />
    <UI.BanIcon icon={faBan} />
  </UI.DoNotRecordLayout>
);

export default DoNotRecord;
