import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ApolloProvider, ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";

const cache = new InMemoryCache();

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL}/`,
});

const AuthorizedApolloProvider = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const authLink = setContext(async (_, { headers }) => {
    if(!isAuthenticated) {
      return {};
    }

    const token = await getAccessTokenSilently();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
    connectToDevTools: process.env.NODE_ENV !== 'production',
  });

  return (
    <ApolloProvider client={apolloClient}>
      {children}
    </ApolloProvider>
  );
};

export default AuthorizedApolloProvider;
