import React from "react";
import { DateTime } from "luxon";
import { pipe, map, sort } from "ramda";

import SweepstakesListItem from "./SweepstakesListItem";
import * as UI from "./ui";

const CONFIRM_WINNER = "Confirm Your Winner";
const DRAW_WINNER = "Draw Your Winner";
const ACTIVE = "Active";
const COMPLETE = "Complete";
const UNDER_CONSTRUCTION = "Under Construction";

const SORT_ORDER = [ CONFIRM_WINNER, DRAW_WINNER, ACTIVE, UNDER_CONSTRUCTION, COMPLETE ];

const computedSweepstakesStatus = sweepstakes => {
  if(sweepstakes.prize.win?.confirmed) {
    return COMPLETE;
  }
  
  if(sweepstakes.prize.win) {
    return CONFIRM_WINNER;
  }

  if (DateTime.fromISO(sweepstakes.schedule.drawn[0]) < DateTime.now()) {
    return DRAW_WINNER;
  }

  if(sweepstakes.approved) {
    return ACTIVE;
  }

  return UNDER_CONSTRUCTION;
};

const addStatus = sweepstakes => ({ ...sweepstakes, status: computedSweepstakesStatus(sweepstakes) });

const sortSweepstakes = (a, b) => {
  const aSortOrder = SORT_ORDER.indexOf(a.status);
  const bSortOrder = SORT_ORDER.indexOf(b.status);

  if(aSortOrder === bSortOrder) {
    return a.beginsAt < b.beginsAt ? 1 : -1;
  }

  return aSortOrder < bSortOrder ? -1 : 1;
}

const groupAndSort = pipe(map(addStatus), sort(sortSweepstakes));

const SweepstakesList = ({ sweepstakes }) => (
  <UI.List>
    {groupAndSort(sweepstakes).map(s => <SweepstakesListItem key={s.id} sweepstakes={s} />)}
  </UI.List>
)

export default SweepstakesList;
