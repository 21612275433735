import styled from "styled-components";

export const Layout = styled.div`
  border-radius: 2px;
  bottom: 30px;
  color: white;
  display: flex;
  font-family: acumin-pro;
  left: 50%;
  padding: 20px;
  position: fixed;
  transform: translateX(-50%);
  width: calc(50% - 60px);
  min-width: calc(375px - 24px);
  align-items: center;
  transition: opacity 250ms ease-in-out;

  ${props => props.type === "error" && `
    background-color: #db3a34;
  `}

  ${props => props.type === "success" && `
    background-color: #77aa16;
  `}

  ${props => !props.visible && `
    opacity: 0;
  `}
`

export const Message = styled.span`
  line-height: 30px;
  flex: 1;
`

export const Close = styled.div`
  font-size: 1.5em;
  line-height: 30px;
  transform: translateY(-2px);
  cursor: pointer;
`
