import React from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import Button from "../design/Button";
import useField from "../../lib/useField";
import { isBlank } from "../../util/presence";

const NOOP = () => {};

const ClearFieldButton = ({id, label, onClick = NOOP, disabled, ...rest}) => {
  const { value, setValue } = useField(id);

  const handleClick = () => {
    setValue({ target: { value: ""}});
    onClick();
  }

  return (
    <Button
      type="button"
      icon={faTrash}
      onClick={handleClick}
      disabled={disabled || isBlank(value)}
      {...rest}
    >
      {label}
    </Button>
  );
};

export default ClearFieldButton;
