import { gql } from "@apollo/client";

export const setSlidesMutation = gql`
  mutation SetSlides($sweepstakesId: SweepstakesSlug!, $slides: [SlideInput!]!) {
    setSlides(sweepstakesId: $sweepstakesId, slides: $slides) {
      sweepstakes {
        id
        slides {
          id
          caption
          image {
            cloudName
            publicId
            version
          }
        }
        checklist {
          id
          slides
        }
      }
      error {
        code
        message
      }
    }
  }
`;
