import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Button from '../design/Button';

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px auto;
  gap: 12px;

  width: 90%;
  max-width: 800px;
`;

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 3px;
`;

export const Label = styled.label`
  color: #333;
  margin-bottom: 6px;
`;

export const Input = styled.input`
  flex: 4 0 260px;
  background: #ffffff;
  border: 1px solid #ccc;
  font-family: inherit;
  font-size: 0.875em;
  outline: none;
  border: none;
  padding: 8px 12px 6px 12px;
  border-radius: 3px;
`;

export const Submit = styled(Button).attrs({ type: "submit" })`
  flex: 1;
`;

export const Results = styled.div`
  max-height: 80vh;
  overflow-y: auto;
  width: 100%;
`;

export const ResultsSection = styled.ul`
  margin-bottom: 12px;
`;

export const ResultsHeading = styled.div`
  font-family: tondo, sans-serif;
  font-weight: bold;
  margin-bottom: 6px;
`;

export const Result = styled(Link)`
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #ffffff;
  padding: 6px 12px;
  border-radius: 3px;
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Title = styled.div`
  font-size: 0.875em;
  font-weight: bold;
  line-height: 1em;
  flex: 1;
  color: #333333;
`;

export const Subtitle = styled.div`
  color: #999999;
  font-size: 0.875em;
`;

