import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import AcceptanceForm from "./AcceptanceForm";
import SignPost from "../design/SignPost";
import Button from "../design/Button";

const AcceptWithoutAuth = ({ invitation, invitationToken }) => {
  const { loginWithRedirect, isLoading } = useAuth0();

  if(isLoading) {
    return null;
  }

  const login = () => {
    loginWithRedirect({ appState: { returnTo: window.location.pathname }});
  };

  return (
    <React.Fragment>
      <SignPost heading="Create your user account to accept.">
        <AcceptanceForm invitationToken={invitationToken} />
      </SignPost>
      <SignPost heading="Already have a user account?">
        <Button size="small" onClick={login}>Sign In</Button>
      </SignPost>
    </React.Fragment>
  );
};

export default AcceptWithoutAuth;
