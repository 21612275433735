import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { path } from "ramda";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";

import Button from "../../design/Button";
import QuickDonationScanner from "./QuickDonationScanner";
import NewForm from "./NewForm";
import { newEntrySetupQuery } from "./gql";
import * as UI from "./ui";

const New = ({ sweepstakes: { id }, guarded, onSelect, onCancel, setLocked }) => {
  const [ showScanner, setShowScanner ] = useState(false);
  const [ entryPrefill, setEntryPrefill ] = useState(null);
  const { data, loading, error } = useQuery(newEntrySetupQuery, {
    variables: {
      sweepstakesId: id,
    }
  });

  useEffect(() => {
    setLocked(true);
    return () => { setLocked(false) };
  }, [ setLocked ]);

  if(loading || error) {
    return null;
  }

  const handleQuickScan = entryData => {
    setEntryPrefill(entryData);
    setShowScanner(false);
  }

  const handleCancel = () => {
    showScanner ? setShowScanner(false) : onCancel();
  }

  const sweepstakes = path(['sweepstakes'], data);

  return (
    <UI.Layout>
      <UI.Header>
        <Button size="small" onClick={handleCancel}>❮ Cancel</Button>
        {!showScanner && (<UI.HeaderActions>
          <Button size="small" onClick={() => setShowScanner(!showScanner)} icon={faQrcode}>Donation Quickscan</Button>
        </UI.HeaderActions>
        )}
      </UI.Header>
      <UI.Body>
        <UI.Heading>
          <UI.Name>New Donation</UI.Name>
        </UI.Heading>
        { showScanner ? (
          <QuickDonationScanner onSuccess={handleQuickScan} />
        ) : (
          <NewForm sweepstakes={sweepstakes} guarded={guarded} onSelect={onSelect} entryPrefill={entryPrefill} />
        )}
      </UI.Body>
    </UI.Layout>
  );
};

export default New;
