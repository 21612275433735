import React from "react";
import { useParams } from "react-router-dom";

import InvoiceList from "../InvoiceList";

const InvoicesPage = () => {
  const { nonprofitId } = useParams();

  return <InvoiceList nonprofitId={nonprofitId} />;
}

export default InvoicesPage;
