import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import * as UI from "./ui";

const SnackbarContent = ({ type, message }) => {
  const [ visible, setVisible ] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setVisible(false), 4000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <UI.Layout type={type} visible={visible}>
      <UI.Message>{message}</UI.Message>
      <UI.Close onClick={() => setVisible(false)}>×</UI.Close>
    </UI.Layout>
  );
}

class Snackbar extends React.Component {
  constructor(props) {
    super(props);
    this.root = document.getElementById('snackbar-root');
    this.el = document.createElement('div');
  }

  componentDidMount() {
    this.root.appendChild(this.el);
  }

  componentWillUnmount() {
    this.root.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(<SnackbarContent {...this.props} />, this.el);
  }
}

export default Snackbar;
