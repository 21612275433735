import React, { useEffect, useContext, useCallback } from "react";
import { useMutation } from "@apollo/client";
import { isNil, path, prepend, evolve } from "ramda";

import { Context as SnackbarContext } from "../../../lib/Snackbar";
import { presence, presenceIf } from "../../../lib/validations";
import { isPresent } from "../../../util/presence";
import EntryForm from "../../EntryForm";
import { sweepstakesEntriesQuery } from "../gql";
import { createEntryMutation } from "./gql";
import { totalDonationsQuery } from "../../TotalDonations/gql";

const validations = {
  "firstName": presence,
  "lastName": presence,
  "address.line1": presence,
  "address.country": presence,
  "address.city": presence,
  "address.state": presenceIf(context => context["address.country"] === "US"),
  "address.postalCode": presenceIf(context => context["address.country"] === "US"),
  "phoneNumber": presence,
  "donationLevel": presence,
  "paymentMethod": presence,
};

const prependOnSuccess = sweepstakesId => (cache, results) => {
  const entry = path(['data', 'createEntry', 'entry'], results);

  if(isNil(entry)) {
    return;
  }

  const config = {
    query: sweepstakesEntriesQuery,
    variables: { id: sweepstakesId, filter: "PAID" },
  };

  const data = cache.readQuery(config);

  const evolution = {
    sweepstakes: {
      entries: prepend(entry),
    }
  };

  cache.writeQuery({
    ...config,
    data: evolve(evolution, data),
  });
};

const NewForm = ({ entryPrefill, guarded, sweepstakes, onSelect }) => {
  const [ create, { loading } ] = useMutation(createEntryMutation, {
    refetchQueries: [ totalDonationsQuery ],
    update: prependOnSuccess(sweepstakes.id)
  });
  const { addMessage } = useContext(SnackbarContext);

  useEffect(() => {
    if (guarded) {
      addMessage({ type: 'error', message: "The Sweepstakes has closed so you can not add new Donations." });
    }
  }, [ guarded, addMessage ]);

  const onSubmit = useCallback(inputObject => (
    create({
      variables: {
        sweepstakesId: sweepstakes.id,
        attributes: inputObject,
      }
    }).then(({ data }) => {
      const entry = path(['createEntry', 'entry'], data);

      if(isPresent(entry)) {
        onSelect(entry.id);
      } else {
        addMessage({ type: "error", message: "There was a problem creating this donation." });
      }
    })
  ), [ create, sweepstakes, onSelect, addMessage ])

  return (
    <EntryForm
      entry={entryPrefill}
      validations={validations}
      sweepstakes={sweepstakes}
      onSubmit={onSubmit}
      loading={loading}
      guarded={guarded}
    />
  );
}

export default NewForm;
