import { useContext } from "react";
import { propOr } from "ramda";

import Context from "./Context";
import { isPresent } from "../../util/presence";

const usePermissions = ({ resource, permission }) => {
  const permissions = useContext(Context);

  if(permissions === null) {
    return null;
  }

  const acl = propOr("", resource, permissions);

  return isPresent(permission) && acl.includes(permission);
};

export default usePermissions;
