import React from "react";
import { Image } from "cloudinary-react";

import * as UI from "./ui";

const Card = ({ item }) => (
  <UI.CardLayout>
    <Image {...item.image} width="auto" aspectRatio="16:9" fetchFormat="auto" style={{width: "100%"}} />
    <UI.Caption>{item.caption}</UI.Caption>
  </UI.CardLayout>
);

export default Card;
