import React from "react";
import { isNil } from "ramda";

import * as UI from "./ui";

const Detail = ({ item, mode, onSelect, onCancel, DetailComponent, NoSelectionComponent }) => {
  if(mode === "master-only") {
    return <UI.DetailLayout mode={mode} />
  }

  if(isNil(item) && NoSelectionComponent) {
    return (
      <UI.DetailLayout mode={mode}>
        <UI.DetailBody mode={mode}>
          <NoSelectionComponent />
        </UI.DetailBody>
      </UI.DetailLayout>
    );
  }

  const showCancel = mode === "detail-only";

  return (
    <UI.DetailLayout mode={mode}>
      <UI.DetailBody mode={mode}>
        <DetailComponent item={item} onSelect={onSelect} onCancel={onCancel} showCancel={showCancel} />
      </UI.DetailBody>
    </UI.DetailLayout>
  );
}

export default Detail;
