import React, { useCallback, useContext, useState } from "react";
import { last, pathOr } from "ramda";
import { useMutation } from "@apollo/client";
import { faRedo, faCheckDouble } from "@fortawesome/free-solid-svg-icons";

import { ordinalize } from "../../util/number";
import { Context as SnackbarContext } from "../../lib/Snackbar";
import { confirmAdditionalPrizeMutation, redrawAdditionalPrizeMutation } from "./gql";
import WinnerCard from "../WinnerCard";
import Button from "../design/Button";

import * as UI from "./ui";

const ConfirmWinnerButton = ({ additionalPrize, next }) => {
  const { addMessage } = useContext(SnackbarContext);
  const [confirmAdditionalPrize, { loading }] = useMutation(confirmAdditionalPrizeMutation, {
    variables: { id: additionalPrize.id },
  });

  const confirm = useCallback(() => {
    if(!window.confirm("Are you sure you want to confirm & post this winner? This cannot be undone.")) {
      return;
    }

    confirmAdditionalPrize()
      .then(({ data, error }) => {
        const mutationError = pathOr(null, ["confirmAdditionalPrize", "error"], data);

        if (error || mutationError !== null) {
          addMessage({ type: "error", message: mutationError.message });
          return;
        }

        next();
      });
  }, [confirmAdditionalPrize, addMessage, next]);

  return <Button type="submit" onClick={confirm} disabled={loading} icon={faCheckDouble} >Confirm Your Winner</Button>;
};

const RedrawWinnerButton = ({ additionalPrize, cancel }) => {
  const { addMessage } = useContext(SnackbarContext);
  const [redrawAdditionalPrize, { loading }] = useMutation(redrawAdditionalPrizeMutation, {
    variables: { id: additionalPrize.id },
  });

  const redraw = useCallback(() => {
    if(!window.confirm("Are you sure you want to redraw the winner? This cannot be undone.")) {
      return;
    }

    redrawAdditionalPrize()
      .then(({ data, error }) => {
        const mutationError = pathOr(null, ["redrawAdditionalPrize", "error"], data);

        if (error || mutationError !== null) {
          addMessage({ type: "error", message: mutationError.message });
          return;
        }

        cancel();
      });
  }, [redrawAdditionalPrize, addMessage, cancel]);

  return (
    <React.Fragment>
      <Button onClick={cancel} disabled={loading}>Cancel</Button>
      <Button intent="help" onClick={redraw} disabled={loading} icon={faRedo}>Redraw Your Winner</Button>
    </React.Fragment>
  );
};

const ConfirmAdditionalPrizeScreen = ({ sweepstakes, next }) => {
  const { additionalPrizes } = sweepstakes;
  const additionalPrize = last(additionalPrizes);
  const [displayRedraw, setDisplayRedraw] = useState(false);

  const ordinal = ordinalize(additionalPrizes.length + 1);

  if (additionalPrize.confirmedAt) {
    return null;
  }

  const controls = displayRedraw ? (
    <RedrawWinnerButton additionalPrize={additionalPrize} cancel={() => setDisplayRedraw(false)} />
  ) : (
    <ConfirmWinnerButton additionalPrize={additionalPrize} next={next} />
  );

  const redrawLink = <Button size="tiny" intent="help" onClick={() => setDisplayRedraw(true)} disabled={displayRedraw} icon={faRedo}>Redraw</Button>;

  return (
    <React.Fragment>
      <UI.StageContent>
        <UI.StageHeading>Confirm {ordinal} Prize Winner</UI.StageHeading>
        <WinnerCard prize={additionalPrize} title={`${ordinal} Prize: ${additionalPrize.name}`} />
        <UI.InstructionsLayout>
          <p>You can {redrawLink} if the name displayed is a person within your organization is ineligible to win.</p>
          <p>Otherwise, confirm your {ordinal} winner and your sweepstakes site will automatically update. Contact your winner to give them the good news!</p>
        </UI.InstructionsLayout>
      </UI.StageContent>
      <UI.Controls>
        {controls}
      </UI.Controls>
    </React.Fragment>
  );
};

export default ConfirmAdditionalPrizeScreen;
