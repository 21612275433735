import React, { useEffect } from "react";

import Button from "../../design/Button";
import NewForm from "./NewForm";
import * as UI from "./ui";

const New = ({ sweepstakes, guarded, onSelect, onCancel, setLocked }) => {
  useEffect(() => {
    setLocked(true);
    return () => { setLocked(false) };
  }, [ setLocked ]);

  return (
    <UI.Layout>
      <UI.Header>
        <Button size="small" onClick={onCancel}>❮ Cancel</Button>
      </UI.Header>
      <UI.Body>
        <UI.Heading>
          <UI.Name>New Flash Contest</UI.Name>
        </UI.Heading>
        <NewForm sweepstakes={sweepstakes} guarded={guarded} onSelect={onSelect} />
      </UI.Body>
    </UI.Layout>
  );
};

export default New;
