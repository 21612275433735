import React, { useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { append, isNil, isEmpty, evolve, path, pathOr, pick } from "ramda";

import { Context } from "../../../lib/Snackbar";
import { myRoleQuery, teamMembersQuery, inviteTeamMemberMutation } from "../gql";
import Formation from "../../forms/Form";
import TextField from "../../forms/TextField";
import SelectField from "../../forms/SelectField";
import Button from "../../design/Button";
import Snackbar from "../../Snackbar";
import CurrentRoleDescription from "./CurrentRoleDescription";
import { presence, isEmail } from "../../../lib/validations";

const appendOnSuccess = nonprofitId => (cache, results) => {
  const teamMember = path(['data', 'inviteTeamMember', 'teamMember'], results);

  if(isNil(teamMember)) {
    return;
  }

  const config = {
    query: teamMembersQuery,
    variables: {
      id: nonprofitId
    },
  };

  const data = cache.readQuery(config);

  const evolution = {
    nonprofit: {
      teamMembers: append(teamMember),
    }
  };

  cache.writeQuery({
    ...config,
    data: evolve(evolution, data),
  });
};

const validations = {
  firstName: presence,
  lastName: presence,
  email: isEmail,
  roleId: presence,
};

const NewForm = ({ nonprofit, onSelect }) => {
  const { addMessage } = useContext(Context);
  const [ inviteTeamMember, { data, loading, error } ] = useMutation(inviteTeamMemberMutation, {
    update: appendOnSuccess(nonprofit.id),
  });
  const [ fetchMyRole, { loading: myRoleLoading } ] = useLazyQuery(myRoleQuery, {
    fetchPolicy: 'network-only',
    variables: {
      id: nonprofit.id,
    }
  });

  const onSubmit = inputObject => (
    inviteTeamMember({
      variables: {
        nonprofitId: nonprofit.id,
        attributes: pick(['firstName', 'lastName', 'email'], inputObject),
        roleId: inputObject.roleId,
      },
    }).then(({ data }) => {
      const teamMember = path(['inviteTeamMember', 'teamMember'], data);

      if(teamMember) {
        addMessage({ message: "Your invitation was sent.", type: "success" });
        onSelect(teamMember.id);
      }
    }).catch(() => {
      fetchMyRole();
      addMessage({ message: "You do not have permission to manage your team.", type: "error" });
    })
  )

  const invitationError = pathOr([], ['inviteTeamMember', 'error'], data);

  return (
    <React.Fragment>
      { (error || !isEmpty(invitationError)) && <Snackbar type="error" message="An error occurred during form submission." /> }
      <Formation initialInputObject={{}} onSubmit={onSubmit} validations={validations}>
        <TextField id="firstName" label="First Name" />
        <TextField id="lastName" label="Last Name" />
        <TextField id="email" label="Email" />
        <SelectField id="roleId" label="Role" options={nonprofit.roles} allowBlank />
        <Button type="submit" disabled={myRoleLoading || loading}>Invite Team Member</Button>
        <CurrentRoleDescription id="roleId" roles={nonprofit.roles} />
      </Formation>
    </React.Fragment>
  );
};

export default NewForm;
