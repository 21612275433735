import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  gap: 12px;
  align-items: flex-start;
`;

export const QR = styled.div`
  width: 120px;
  height: 120px;
`;

export const ControlList = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 12px 6px;
`;

export const Input = styled.input`
  font-family: inherit;
  padding: 6px 12px;
  font-size: 0.875em;
  border: 1px solid #ddd;
  border-radius: 3px;
  min-width: 100%;
  background-color: rgba(0, 0, 0, 0.05);
`;
