/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { either, isEmpty, reject, isNil, mapObjIndexed, assoc } from "ramda";

const validate = (values, validations) =>
  reject(either(isNil, isEmpty),
    mapObjIndexed((validation, id) =>
      validation && validation(values[id], values), validations)
  )

const useFormState = (initialValues = {}, validations, skeleton, onChange) => {
  const [ values, setValues ] = useState(initialValues);
  const [ errors, setErrors ] = useState({});

  const reset = () => {
    setValues(initialValues);
  }

  // Update the Form's state if the initialValues change.
  useEffect(reset, [skeleton])

  useEffect(() => {
    onChange && onChange(values);
  }, [values, onChange]);

  const validateForm = () => { setErrors(validate(values, validations)) }
  useEffect(validateForm, [values, validations]);

  const setField = (id, targetAttribute = "value") => event => {
    const value = event.target[targetAttribute];
    setValues(assoc(id, value));
  }

  return { values, errors, setField, reset };
};

export default useFormState;
