import { gql } from "@apollo/client";

export const totalDonationsQuery = gql`
  query TotalDonations($sweepstakesId: SweepstakesSlug!) {
    sweepstakes(id: $sweepstakesId) {
      id
      totalDonations
    }
  }
`;
