import { DateTime } from "luxon";
import DateRange from "./DateRange";

export default class DateBlacklist {
  constructor(dates) {
    this.blacklist = dates.map(dateObj => {
      if (Array.isArray(dateObj)) {
        return new DateRange(dateObj[0], dateObj[1]);
      }
      return DateTime.fromISO(dateObj).startOf('day');
    });;
  }

  contains(date) {
    return !!this.blacklist.find(dateObj => {
      if (dateObj instanceof DateRange) {
        if (date instanceof DateRange) {
          return dateObj.contains(date.begin) || dateObj.contains(date.end);
        }

        return dateObj.contains(date);
      }

      if (date instanceof DateRange) {
        return date.contains(dateObj);
      }

      return dateObj.startOf('day') === date.startOf('day');
    });
  }
}
