import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@apollo/client";
import { useParams, Navigate } from "react-router-dom";

import LogoutPrompt from "./LogoutPrompt";
import SessionPrompt from "./SessionPrompt";
import CompleteForm from "./CompleteForm";
import { getAccountApplicationQuery } from "./gql";

const Complete = () => {
  const { id } = useParams();
  const { data, loading, error } = useQuery(getAccountApplicationQuery, {
    variables: { id }
  });
  const { isAuthenticated, user, isLoading } = useAuth0();

  if(isLoading || loading || error) {
    return null;
  }

  const { accountApplication } = data;

  if(!accountApplication) {
    return <Navigate to="/" />;
  }

  if (accountApplication.requiresSession && isAuthenticated && accountApplication.email !== user.email) {
    return <LogoutPrompt accountApplication={accountApplication} />
  }

  if (accountApplication.requiresSession && !isAuthenticated) {
    return <SessionPrompt accountApplication={accountApplication} />;
  }

  return (
    <CompleteForm accountApplication={accountApplication} user={user} />
  );
};

export default Complete;

