import React from "react";
import { useQuery } from "@apollo/client";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";

import AccessControlledLink from "../../lib/AccessControlledLink";
import { retroQuery } from "./gql";
import * as UI from "./ui";

const Winner = ({ sweepstakes }) => {
  const { data, loading, error } = useQuery(retroQuery, {
    variables: { id: sweepstakes.id }
  });

  if(loading || error) return null;

  const report = data?.sweepstakesRetrospectiveReport;

  const bullets = (
    <ul>
      <li>your raise by donation amounts</li>
      <li>proportions of new and returning donors</li>
      <li>repeat donors by number of donations</li>
      <li>your top donors by donation total and count</li>
      <li>your top performing promotions</li>
    </ul>
  );

  if(!report) {
    return (
      <UI.Section style={{ gridArea: "retro" }}>
        <UI.SectionHeading>Sweepstakes Retrospective</UI.SectionHeading>
        <UI.HintLayout>
          <UI.HintBody>
            <UI.HintHeading>We're generating your retrospective report now! Check back soon for insights on:</UI.HintHeading>
            {bullets}
          </UI.HintBody>
        </UI.HintLayout>
      </UI.Section>
    );
  }

  return (
    <UI.Section style={{ gridArea: "retro" }}>
      <UI.SectionHeading>Sweepstakes Retrospective</UI.SectionHeading>
      <UI.HintLayout>
        <UI.HintBody>
          <UI.HintHeading>We've created a retrospective report to help you understand how your campaign went! You'll find insights on:</UI.HintHeading>
          {bullets}
        </UI.HintBody>
      </UI.HintLayout>
      <AccessControlledLink to="./retro" icon={faFileAlt} resource="sweepstakesRetrospectiveReports" permission="R">View Your Report</AccessControlledLink>
    </UI.Section>
  );
};

export default Winner;
