import styled from "styled-components";

export const EmptyLayout = styled.div`
  flex: 1;
  padding: 24px;
  color: #333333;
`;

export const EmptyMessage = styled.h2`
  font-weight: bold;
`;

export const Paragraph = styled.p`
  font-size: 0.875em;
  margin: 0 0 24px 0;
`;

export const List = styled.ul`
  margin: 6px 0;
  list-style-type: none;
  padding-left: 12px;
`;

export const ListItem = styled.li`
`;
