import React from "react";
import { DateTime } from "luxon";

import DrawButton from "./DrawButton";
import RedrawButton from "./RedrawButton";
import ConfirmButton from "./ConfirmButton";

const shouldEnable = (flashContest, sweepstakes) => {
  if(flashContest.flashContestWin) {
    return false;
  }

  return DateTime.fromISO(flashContest.eligibleRange[1]) < DateTime.local();
}

const FlashContestDrawingTool = ({ flashContest, sweepstakes }) => {
  const enabled = shouldEnable(flashContest, sweepstakes);

  const hasWin = !!flashContest.flashContestWin;
  const confirmed = flashContest.flashContestWin?.confirmed;

  if(confirmed) {
    return null;
  }

  if(hasWin && !confirmed) {
    return (
      <React.Fragment>
        <RedrawButton flashContest={flashContest} />
        <ConfirmButton flashContest={flashContest} />
      </React.Fragment>
    );
  }

  return <DrawButton flashContest={flashContest} sweepstakes={sweepstakes} enabled={enabled} />;
};

export default FlashContestDrawingTool;
