import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import Button from "../../components/design/Button";
import * as UI from "./ui";

const Handle = () => (
  <UI.Handle>
    <UI.Grip />
    <UI.Grip />
    <UI.Grip />
    <UI.Grip />
    <UI.Grip />
  </UI.Handle>
);

const ListItem = ({ item, index, onSelect, onRemove, removeEnabled, itemLabel, ItemComponent }) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: item.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <UI.ItemLayout ref={setNodeRef} style={style} {...attributes} {...listeners} isDragging={isDragging}>
      <UI.Card isDragging={isDragging}>
        <UI.CardHeader>
          <UI.Index>{itemLabel} {index + 1}</UI.Index>
          <Handle />
          <Button size="tiny" onClick={() => onRemove(item)} icon={faTrash} disabled={!removeEnabled} />
        </UI.CardHeader>
        <UI.ItemContent onClick={() => onSelect(item.id)}>
          <ItemComponent item={item} />
        </UI.ItemContent>
      </UI.Card>
    </UI.ItemLayout>
  );
};

export default ListItem;
