import React from "react";
import { isEmpty, has } from "ramda";

import * as UI from "./ui";

const RESOURCE_NAMES = {
  nonprofit: "this Nonprofit",
  sweepstakes: "Sweepstakes",
  entries: "Entries",
  promoCodes: "Promo Codes",
  insights: "Insights",
  winners: "the Winner",
  team: "Team Members",
  paymentAccount: "the Payment Account"
};

const ACL = {
  C: "create",
  R: "view",
  U: "update",
  D: "remove",
  E: "export",
};

const toSentence = array => {
  if(array.length === 1) {
    return array[0];
  };

  if(array.length === 2) {
    return array.join(" and ");
  }

  return array.slice(0, array.length - 1).join(', ') + ", and " + array.slice(-1);
};

const PermissionSentence = ({ resource, permission, subject }) => {
  if(isEmpty(permission) || !has(resource, RESOURCE_NAMES)) {
    return null;
  }

  const abilities = [...permission].map(p => ACL[p]);
  const ability = toSentence(abilities);

  return (
    <UI.Item>
      {subject} can {ability} <UI.Resource>{RESOURCE_NAMES[resource]}</UI.Resource>.
    </UI.Item>
  );
}

export default PermissionSentence;
