import React, { useContext } from "react";
import { pathOr } from "ramda";
import { useMutation } from "@apollo/client";

import { Context as SnackbarContext } from "../../lib/Snackbar";
import { autoDrawWinnerMutation } from "./gql";
import Button from "../design/Button";

import * as UI from "./ui";

const DrawWinnerScreen = ({ sweepstakes, next }) => {
  const { addMessage } = useContext(SnackbarContext);
  const [drawWinner, { loading }] = useMutation(autoDrawWinnerMutation, {
    variables: { sweepstakesId: sweepstakes.id }
  });

  const draw = () => {
    drawWinner()
      .then(({ data, error }) => {
        const mutationError = pathOr(null, ["autoDrawWinner", "error"], data);

        if (error || mutationError !== null) {
          addMessage({ type: "error", message: mutationError.message });
          return;
        }

        next();
      });
  };

  return (
    <React.Fragment>
      <UI.StageContent>
        <UI.StageHeading>Draw Your Winner</UI.StageHeading>
        <UI.InstructionsLayout>
          <p>We use a random number generator to automatically pick the winning entry. The winning entry, winner’s name and location will be displayed.</p>
        </UI.InstructionsLayout>
      </UI.StageContent>
      <UI.Controls>
        <Button type="submit" onClick={draw} disabled={loading}>Draw the Grand Prize Winner</Button>
      </UI.Controls>
    </React.Fragment>
  )
};

export default DrawWinnerScreen;
