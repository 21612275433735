import { useCallback } from "react";
import { useNavigate } from "react-router-dom"
import { useHotkeys } from "react-hotkeys-hook";

const useSearchHotkeys = () => {
  const navigate = useNavigate();
  const navigateToSearch = useCallback(() => navigate("/search"), [navigate]);
  useHotkeys('meta+/, ctrl+/', navigateToSearch);
};

export default useSearchHotkeys;
