import React from "react";
import { map } from "ramda";
import { useQuery } from "@apollo/client";

import { formatAsDollars } from "../../util/money";
import { getDonationLevels } from "./queries";
import * as UI from "./ui";

const buildColumnDefs = promoCode => {
  const columns = [
    {
      name: "Donation Amount",
      attribute: ({ price }) => formatAsDollars(price),
    },
    {
      name: "# of Entries",
      attribute: ({ rewardTickets }) => rewardTickets === 1 ? '1 Entry' : `${rewardTickets} Entries`,
    },
  ];

  if(promoCode) {
    columns.push({
      name: "# of Promo Entries",
      attribute: ({ rewardTickets }) => {
        const promoTickets = Math.ceil((rewardTickets * promoCode.bonusPercent) / 100)
        return promoTickets && promoTickets === 1 ? '+1 Bonus' : `+${promoTickets} Bonus`;
      },
    })
  }

  return columns;
}

const DonationLevels = ({ sweepstakesId, promoCode }) => {
  const { data, loading, error } = useQuery(getDonationLevels, {
    variables: {
      sweepstakesId,
    }
  });

  if(loading || error) {
    return null;
  }

  const donationLevels = data && data.sweepstakes.donationLevels;

  const columnDefs = buildColumnDefs(promoCode);

  return (
    <UI.Table>
      <tbody>
        {donationLevels.map((donationLevel) => (
          <UI.TR key={donationLevel.id}>
            { map(column => <UI.TD key={column.name}>{column.attribute(donationLevel)}</UI.TD>, columnDefs) }
          </UI.TR>
        ))}
      </tbody>
    </UI.Table>
  );
};

export default DonationLevels;
