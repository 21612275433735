import React from "react";
import styled from "styled-components";

const Layout = styled.div`
  border: 1px solid rgba(255,255,255,0.75);
  height: 6px;
  min-width: 150px;
  border-radius: 2px;
`;

const Fill = styled.div`
  height: 100%;
  max-width: ${props => props.percent}%;
  background-color: rgba(255,255,255,0.75);
`;

const ProgressBar = ({ percent }) => percent ? (
  <Layout>
    <Fill percent={percent} />
  </Layout>
) : null;

export default ProgressBar;
