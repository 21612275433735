import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";

const ContentAwareContainer = styled.span`
  ${props => !props.$hasContent &&`
    opacity: 0.5;

    &:after {
      content: "${props.$replacement}";
    }
  `}
`;

const Redactable = ({ children, replacement = "Not Provided or Data Removal Requested" }) => {
  const ref = useRef(null);
  const [hasContent, setHasContent] = useState(true);

  useEffect(() => {
    setHasContent(ref.current && ref.current.innerText !== "");
  }, [ref, children]);

  return (
    <ContentAwareContainer ref={ref} $hasContent={hasContent} $replacement={replacement}>
      {children}
    </ContentAwareContainer>
  )
};

export default Redactable;
