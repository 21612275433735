import React from "react";
import { DateTime } from "luxon";

import ProgressGauge from "../design/ProgressGauge";

const DurationProgress = ({ sweepstakes }) => {
  if(sweepstakes.beginsAt === null || sweepstakes.endsAt === null) return null;

  const beginsAt = DateTime.fromISO(sweepstakes.beginsAt);       
  const endsAt = DateTime.fromISO(sweepstakes.endsAt);           
  const now = DateTime.local();                                  

  if(now > endsAt) return null;

  const totalDuration = endsAt.diff(beginsAt).as("milliseconds");
  const elapsedDuration = now.diff(beginsAt).as("milliseconds"); 
  const percentage = Math.round(100 * elapsedDuration / totalDuration);

  const daysLeft = Math.ceil(endsAt.diff(now).as("days"));
  const subheading = `${daysLeft} Days Remaining`;

  return <ProgressGauge heading="Progress" subheading={subheading} percentage={percentage} />
};

export default DurationProgress;
