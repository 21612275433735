import React, { useState } from "react";
import { useLazyQuery } from "@apollo/client";

import SearchForm from "./SearchForm";
import SearchResults from "./SearchResults";
import { searchQuery } from "./gql";
import * as UI from "./ui";

const buildResults = data => {
  const { sweepstakeses, nonprofits } = data;

  const sweepstakesResults = sweepstakeses.map(sweepstakes => ({
    id: sweepstakes.id,
    title: sweepstakes.prize.name,
    subtitle: sweepstakes.nonprofit.name,
    url: `/${sweepstakes.nonprofit.id}/sweepstakes/${sweepstakes.id}`,
  }));

  const nonprofitResults = nonprofits.map(nonprofit => ({
    id: nonprofit.id,
    title: nonprofit.name,
    subtitle: nonprofit.ein,
    url: `/${nonprofit.id}`,
  }));

  return { sweepstakes: sweepstakesResults, nonprofits: nonprofitResults };
}

const GlobalSearchPage = () => {
  const [ search, { data, loading, error }] = useLazyQuery(searchQuery);
  const [resultsVisible, setResultsVisible] = useState(false);

  const results = data ? buildResults(data) : [];

  const onSearch = ({ value }) => {
    if (value) {
      setResultsVisible(true);
      search({ variables: { search: value }});
    } else {
      setResultsVisible(false);
    }
  };

  return (
    <UI.Layout>
      <SearchForm onSearch={onSearch} />
      <SearchResults
        results={results}
        loading={loading}
        error={error}
        visible={resultsVisible}
      />
    </UI.Layout>
  );
}

export default GlobalSearchPage;
