import React, { useContext } from "react";
import { isNil } from "ramda"
import { useMutation } from "@apollo/client";

import { Context } from "../../../lib/Snackbar";
import Button from "../../design/Button";
import { resendInvitationMutation } from "../gql";

const ResendInvitationButton = ({ teamMember }) => {
  const { addMessage } = useContext(Context);
  const [ resendInvitation, { loading } ] = useMutation(resendInvitationMutation);

  const onClick = () => {
    resendInvitation({
      variables: {
        teamMemberId: teamMember.id,
      }
    }).then(({ data }) => {
      if(isNil(data.resendInvitation.error)) {
        addMessage({ message: "The invitation has been resent.", type: "success" });
      } else {
        addMessage({ message: "There was a problem resending this invitation.", type: "error" });
      }
    })
  }

  return (
    <Button size="small" type="submit" onClick={onClick} disabled={loading}>Resend Invitation</Button>
  );
}

export default ResendInvitationButton;
