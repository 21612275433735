import React from "react";
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import TotalDonations from "../TotalDonations";
import Link from "../design/Link";
import * as UI from "./ui";

const QuickLinks = ({ sweepstakes }) => (
  <UI.QuickLinks>
    <TotalDonations sweepstakesId={sweepstakes.id} />
    <Link size="tiny" icon={faExternalLinkAlt} href={sweepstakes.publicUrl}>Visit Site</Link>
  </UI.QuickLinks>
);

export default QuickLinks;
