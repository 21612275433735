import styled from "styled-components";

export const Total = styled.div`
  border-radius: 3px;
  display: inline-block;
  padding: 3px 12px;
  background-color: #77aa16;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  font-size: 0.875em;
  min-width: 96px;
`;
