import { gql } from "@apollo/client";

export const retroQuery = gql`
  query SweepstakesRetrospectiveReportQuery($id: SweepstakesSlug!) {
    sweepstakesRetrospectiveReport(id: $id) {
      id
      sequenceNumber

      sweepstakes {
        id
        prize {
          id
          name
        }
      }

      donationCount
      averageDonation
      donationTotal
      lifetimeDonationTotal

      donorCount
      newDonorPercentage
      newDonorCount
      returningDonorPercentage
      returningDonorCount
      repeatDonorCount
      repeatDonorPercentage

      successfulTicketCount
      manualTicketCount
      refundedTicketCount
      disputedTicketCount
      freeTicketCount
      totalTicketCount

      repeatDonorBreakdown {
        donorCount
        repeatedDonationCount
      }

      topDonorsByTotal {
        email
        count
        total
      }

      topDonorsByCount {
        email
        count
        total
      }

      donationLevelSubreports {
        count
        total

        donationLevel {
          id
          level
          price
          rewardTickets
        }
      }

      promoCodeSubreports {
        count
        total

        promoCode {
          id
          name
        }
      }
    }
  }
`;
