import React, { useCallback, useContext } from "react";
import { append, evolve, isNil, path, pathOr } from "ramda";
import { useMutation } from "@apollo/client";

import Form from "../forms/Form";
import TextField from "../forms/TextField";
import { ordinalize } from "../../util/number";
import { presence } from "../../lib/validations";
import { Context as SnackbarContext } from "../../lib/Snackbar"; import { drawAdditionalPrizeMutation, getWinnersQuery } from "./gql";
import Button from "../design/Button";

import * as UI from "./ui";

const appendOnSuccess = sweepstakesId => (cache, results) => {
  const additionalPrize = path(['data', 'drawAdditionalPrize', 'additionalPrize'], results);

  if(isNil(additionalPrize)) {
    return;
  }

  const config = {
    query: getWinnersQuery,
    variables: { sweepstakesId: sweepstakesId },
  };

  const data = cache.readQuery(config);

  const evolution = {
    sweepstakes: {
      additionalPrizes: append(additionalPrize),
    }
  };

  cache.writeQuery({
    ...config,
    data: evolve(evolution, data),
  });
};

const DrawAdditionalPrizeScreen = ({ sweepstakes, next }) => {
  const { additionalPrizes } = sweepstakes;
  const { addMessage } = useContext(SnackbarContext);
  const [drawAdditionalPrize, { loading }] = useMutation(drawAdditionalPrizeMutation, {
    variables: { sweepstakesId: sweepstakes.id },
    update: appendOnSuccess(sweepstakes.id),
  });

  const draw = useCallback((inputObject) => {
    drawAdditionalPrize({ variables: { attributes: inputObject } })
      .then(({ data, error }) => {
        const mutationError = pathOr(null, ["drawAdditionalPrize", "error"], data);

        if (error || mutationError !== null) {
          addMessage({ type: "error", message: mutationError.message });
          return;
        }

        next();
      });
  }, [drawAdditionalPrize, addMessage, next]);

  const ordinal = ordinalize(additionalPrizes.length + 2);

  return (
    <React.Fragment>
      <UI.StageContent>
        <UI.StageHeading>{ordinal} Prize Winner</UI.StageHeading>
        <UI.InstructionsLayout>
          <p>We use a random number generator to automatically pick the winning entry. The winning entry, winner’s name and location will be displayed.</p>
        </UI.InstructionsLayout>
        <Form id="additional-prize-form" initialInputObject={{ name: "" }} onSubmit={draw} validations={{ name: presence }}>
          <TextField id="name" label="Prize Name" autoFocus />
        </Form>
      </UI.StageContent>
      <UI.Controls>
        <Button type="submit" disabled={loading} form="additional-prize-form">Draw {ordinal} Prize Winner</Button>
      </UI.Controls>
    </React.Fragment>
  )
};

export default DrawAdditionalPrizeScreen;
