import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 24px;
  padding-bottom: 12px;
`;

export const StageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;

  @media screen and (max-width: 600px) {
    flex: 1;
  }

  form {
    align-self: normal;

    /* TODO: Remove this when field spacing is fixed */
    margin-right: -12px;
  }
`;

export const StageHeading = styled.h1`
  font-family: tondo, sans-serif;
  font-size: 1.25em;
  font-weight: bold;
`;

export const Controls = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  gap: 12px;
  height: 96px;
`;

export const InstructionsLayout = styled.div`
  display: flex;
  max-width: 400px;
  flex-direction: column;
  align-items: center;
  font-size: 0.9375em;
  padding: 0 12px;

  & strong {
    font-weight: bold;
  }

  & p:not(:last-child) {
    margin-bottom: 6px;
  }

  :not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const CopyPresentationUrl = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  padding: 5px 12px;
  background-color: #fff;
  color: #444;
  font-size: 0.875em;
  font-weight: 700;
  cursor: pointer;
  border-radius: 3px;
`;

export const RedrawLink = styled.span`
  cursor: pointer;
  color: #1d4f90;
  text-decoration: underline;
`;

export const DoNotRecordLayout = styled.div`
  position: relative;
  flex: 0 0 auto;
  height: 64px;
  width: 64px;
`;

export const BanIcon = styled(FontAwesomeIcon)`
  font-size: 4em;
  color: #db3a34;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const CameraIcon = styled(FontAwesomeIcon)`
  font-size: 2.25em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const GuardedDrawTime = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 2em;
  color: #333;
  font-weight: bold;
  margin-bottom: 24px;
`;

export const VideoLayout = styled.div`
  box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
  margin: 24px 0;

  @media screen and (min-width: 721px) {
    width: 720px;
    height: 405px;
  }

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 56.25vw;
  }
`;

export const AdditionalPrizeListLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 12px;
`;
