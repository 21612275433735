import { useAuth0 } from "@auth0/auth0-react";

const SignIn = () => {
  const { loginWithRedirect } = useAuth0();

  loginWithRedirect();

  return null;
};

export default SignIn;
