import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Layout = styled.div`
  max-width: 528px;
  padding: 24px;
  border-radius: 6px;
  background: #EDF6FB;
`;

export const Title = styled.div`
  font-family: tondo, sans-serif;
  font-weight: bold;
  margin-bottom: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
`;

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const MenuGroup = styled.div`
  width: 100%;
  min-width: 480px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  @media (max-width: 768px) {
    min-width: 240px;
    gap: 6px;
    &:not(:last-child) {
      margin-bottom: 0;
      margin-top: 24px;
    }
  }
`;

export const MenuLink = styled(NavLink)`
  border-radius: 6px;
  display: flex;
  background: #fff;
  padding: 12px;
  color: #444;
  font-weight: bold;
  font-size: 0.875em;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
`;

export const Icon = styled(FontAwesomeIcon)`
  margin-right: 12px;
  height: 24px;
  width: 24px;
`;

export const Label = styled.span`
  flex: 1;
  white-space: nowrap;
`;
