import React from "react";

import StatSummary from "../../design/StatSummary";
import StatBreakdown from "../../StatBreakdown";
import * as UI from "./ui";

const InsightCard = ({ heading, series, total, count, average, controls }) => {
  return (
    <UI.Layout>
      <UI.Header>
        <UI.Heading>{heading}</UI.Heading>
        <StatSummary total={total} count={count} average={average} />
      </UI.Header>
      <StatBreakdown series={series} />
      { controls && <UI.Controls>{controls}</UI.Controls> }
    </UI.Layout>
  );
};

export default InsightCard;
