import styled from "styled-components";

export const ItemLayout = styled.div`
  display: flex;
  align-items: center;
`;

export const PrimaryAttributes = styled.div`
  flex: 1;
`;

export const Name = styled.div`
  font-size: 1.25em;
  font-weight: bold;
`;

export const PrizeName = styled.div`
  font-size: 0.875em;
  color: #aaaaaa;
`;

export const SecondaryAttributes = styled.div`
`;

const totalColorColor = ({ status }) => {
  switch (status) {
    case "draft":
      return "#aaaaaa";
    case "open":
      return "#aaaaaa";
    case "paid":
      return "#77aa16";
    default:
      return "#db3a34";
  }
};

export const Total = styled.div`
  background-color: ${totalColorColor};
  color: #ffffff;
  display: inline-block;
  padding: 3px 6px;
  min-width: 96px;
  text-align: center;
  font-weight: bold;
`;

export const Status = styled.div`
  text-transform: uppercase;
  text-align: center;
  font-size: 0.75em;
  color: #aaaaaa;
`;
