import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useLocation } from "react-router-dom";

import * as UI from "./ui";

const MenuBody = ({ requestClose, children }) => {
  const [ closing, setClosing ] = useState(false);
  const location = useLocation();
  const [ initialLocation ] = useState(location);

  const close = () => {
    setClosing(true);
    setTimeout(() => {
      requestClose();
    }, 160);
  }

  useEffect(() => {
    if (location !== initialLocation) {
      close();
    }
  });

  const handleClick = e => {
    if (e.target !== e.currentTarget) {
      return;
    }

    close();
  };

  const body = (
    <UI.Layout onClick={handleClick} $closing={closing}>
      <UI.Content $closing={closing}>
        {children}
      </UI.Content>
    </UI.Layout>
  );

  return ReactDOM.createPortal(body, document.getElementById("menu-root"));
}

export default MenuBody;
