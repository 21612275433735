import React from "react";
import { useQuery } from "@apollo/client";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import WinnerCard from "../WinnerCard";
import AccessControlledLink from "../../lib/AccessControlledLink";
import { winnerQuery } from "./gql";
import * as UI from "./ui";

const Winner = ({ sweepstakes }) => {
  const { data, loading, error } = useQuery(winnerQuery, {
    variables: { id: sweepstakes.id }
  });

  if(loading || error) return null;

  const prize = data?.sweepstakes?.prize;

  return (
    <UI.Section style={{ gridArea: "winner" }}>
      <UI.SectionHeading>Winner Details</UI.SectionHeading>
      <WinnerCard title="The Grand Prize" prize={prize} grand />
      <AccessControlledLink to="./winner" icon={faArrowRight} size="small" resource="winners" permission="U">View Winner Details</AccessControlledLink>
    </UI.Section>
  );
};

export default Winner;
