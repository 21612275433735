import { gql } from "@apollo/client";

export const activePromoCodesQuery = gql`
  query ActivePromoCodes($sweepstakesId: SweepstakesSlug!) {
    sweepstakes(id: $sweepstakesId) {
      id
      activePromoCodes {
        id
        name
        insights {
          id
          all: performanceTimeline(resolution: ALL) {
            data {
              total
            }
          }
        }
      }
    }
  }
`;
