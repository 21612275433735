import styled from "styled-components";

export const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 12px;
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const Agenda = styled.div`
  overflow-y: auto;
  flex: 1 1 0;
`;

export const ExportSection = styled.div`
`;

export const Heading = styled.div`
  margin-right: 30px;
`;

export const Actions = styled.div`
`;
