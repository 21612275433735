import React from "react";
import { DateTime } from "luxon";

import useField from "../../../lib/useField";
import DateTimeField from "../../forms/DateTimeField";

const MINIMUM_DAYS_TO_ADD = [
  8, // If Monday, start on the following Tuesday
  8, // If Tuesday, start on the following Wednesday
  8, // If Wednesday, start on the following Thursday
  8, // If Thursday, start on the following Friday
  8, // If Friday, start on the following Saturday
  7, // If Saturday, start on the following Saturday
  6, // If Sunday, start on the following Saturday
];

const computeWinningTicketDrawnAt = endsAt => {
  if(!endsAt) {
    return undefined;
  }

  return endsAt.plus({ days: MINIMUM_DAYS_TO_ADD[endsAt.weekday - 1] }).startOf('day');
}

const WinningTicketDrawnAtField = ({ id, baseId, label, disabled, sweepstakes }) => {
  const { value: base } = useField(baseId);
  const endsAt = base ? DateTime.fromISO(base, { zone: sweepstakes.timezone }) : null;

  const minWinningTicketsDrawnAt = computeWinningTicketDrawnAt(endsAt);

  return (
    <DateTimeField
      id={id}
      label={label}
      zone={sweepstakes.timezone}
      minDate={minWinningTicketsDrawnAt?.toISODate()}
      setDefaultTime={value => value.set({ hour: 12 }).startOf('hour')}
      disabled={sweepstakes.approved}
    />
  );
}

export default WinningTicketDrawnAtField;
