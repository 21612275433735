import { gql } from "@apollo/client";

export const sweepstakesQuery = gql`
  query SweepstakesQuery($id: SweepstakesSlug!) {
    sweepstakes(id: $id) {
      id
      publicUrl
      approved
    }
  }
`;
