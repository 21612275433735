import React, { useEffect } from "react";
import { faFileDownload, faVideo } from "@fortawesome/free-solid-svg-icons";

import { ordinalize } from "../../util/number";
import Link from "../design/Link";

import WinnerCard from "../WinnerCard";
import * as UI from "./ui";

const OverviewScreen = ({ sweepstakes, clearResumeExpiry }) => {
  const { winnerPresentationUrl, prize, additionalPrizes } = sweepstakes;

  useEffect(() => {
    clearResumeExpiry();
  }, [clearResumeExpiry]);

  return (
    <React.Fragment>
      <UI.StageContent>
        <WinnerCard prize={prize} title="The Grand Prize" grand detailed />
        <UI.AdditionalPrizeListLayout>
          {additionalPrizes.map((additionalPrize, i) => (
            <WinnerCard key={additionalPrize.id} prize={additionalPrize} title={`${ordinalize(i + 2)} Prize: ${additionalPrize.name}`} detailed />
          ))}
        </UI.AdditionalPrizeListLayout>
        <UI.InstructionsLayout>
          <p>TapKat creates a customized Winner Presentation for your entrants to view. If you are drawing your winner at an event, you can connect your computer to a big screen TV and run the presentation for your attendees. You can also share the URL for your Winner Presentation on your social media, your nonprofit website, in emails, etc.</p>
        </UI.InstructionsLayout>
      </UI.StageContent>
      <UI.Controls>
        <Link href={winnerPresentationUrl} size="small" icon={faVideo}>View Winner Presentation</Link>
        <Link href="/downloads/winner_affidavit.pdf" size="small" icon={faFileDownload}>Download Winner Affidavit</Link>
      </UI.Controls>
    </React.Fragment>
  );
};

export default OverviewScreen;
