import React from "react";

import * as UI from "./ui";

const HINTS = [
  "Each promo code must be unique within this sweepstakes (e.g. if you used XMAS already, you can’t repeat it for this sweepstakes). You can use the same promo code concurrently for different sweepstakes (e.g. use XMAS for multiple active or new sweepstakes).",
  "Choose a percentage of extra entries to award (the number of entries for each donation level will automatically round up).",
  "Set an expiration date to create urgency or leave that field blank and the code will run until the end of your drawing. Promo codes expire at 11:59PM on the date you set.",
  "Note that promo codes will be saved in all uppercase, but they are not case sensitive in your payment form.",
  "Promo codes can be edited until the code has been redeemed. Once a code has been redeemed, you can no longer edit the code or the promo percentage. You can edit the expiration date in case you want to extend your offer. ",
  "An Auto-Apply URL is automatically generated when you enter the code. The promo code will be automatically applied on the donation checkout page when you use the Auto-Apply URL as the link to your sweepstakes. Use the “Try It” button to test it and the “Copy It” button to copy the URL and then paste it into your promotional link button. ",
];

const Hints = () => (
  <UI.Layout>
    <UI.Title>Getting Started</UI.Title>

    <UI.Paragraph>Keep the following in mind when setting up promo codes:</UI.Paragraph>
    <UI.HintList>
      { HINTS.map((hint, index) => <UI.Hint key={index}>{hint}</UI.Hint>) }
    </UI.HintList>
  </UI.Layout>
);

export default Hints;
