import styled from "styled-components";
import { FieldLayout } from "../forms/ui";

export const InlineFormLayout = styled.div`
  display: flex;
  align-items: center;
  margin-left: -6px;
  margin-right: -6px;

  ${FieldLayout} {
    margin-bottom: 0;
  }
`;

export const NoPromoCode = styled.span`
  color: #aaaaaa;
  margin-right: 24px;
  font-size: 0.875em;
`;
