import React from "react";
import { DateTime } from "luxon";

import { formatAsDollars } from "../../../util/money";
import * as UI from "./ui";

const Item = ({ item: invoice }) => (
  <UI.ItemLayout>
    <UI.PrimaryAttributes>
      <UI.Name>
        {DateTime.fromISO(invoice.createdAt).toLocaleString(DateTime.DATE_MED)} | {invoice.number}
      </UI.Name>
      <UI.PrizeName>{invoice.sweepstakes.prize.name}</UI.PrizeName>
    </UI.PrimaryAttributes>
    <UI.SecondaryAttributes>
      <UI.Total status={invoice.status}>{formatAsDollars(invoice.total)}</UI.Total>
      <UI.Status>{invoice.status}</UI.Status>
    </UI.SecondaryAttributes>
  </UI.ItemLayout>
);

export default Item;
