import React, { useRef, useEffect } from "react";

import Money from "../../components/design/Money";
import * as UI from "./ui";

const Row = ({ item, max, maxCount, columns, $anySelection, selected, onMouseEnter, onTouchStart, enableScroll }) => {
  const ref = useRef(null);

  useEffect(() => {
    if(selected && enableScroll) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [selected, enableScroll]);

  const rowProps = {
    onMouseEnter,
    onTouchStart,
    $selected: selected,
    $anySelection,
  };

  const elements = {
    label:   <UI.Label key="label" ref={ref} {...rowProps}>{item.label}</UI.Label>,
    total:   <UI.Total key="total" $fill={100 * item.total / max} {...rowProps}><Money amount={item.total} autoAlign /></UI.Total>,
    count:   <UI.Count key="count" $fill={100 * item.count / maxCount} {...rowProps}> {item.count} </UI.Count>,
    average: <UI.Average key="average" {...rowProps}><Money amount={item.average} autoAlign /></UI.Average>,
    percentage: <UI.Percentage key="percentage" $fill={item.percentage} {...rowProps}>{item.percentage}%</UI.Percentage>,
  }

  return columns.map(column => elements[column]);
};

export default Row;
