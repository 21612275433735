import { gql } from "@apollo/client";

export const sweepstakesCalendarQuery = gql`
  query($id: SweepstakesSlug!) {
    sweepstakes(id: $id) {
      id
      calendar {
        id
        events {
          date
          summary
          description
          actionRequired
        }
      }
    }
  }
`;
