/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useParams } from "react-router-dom";

import { withAccessControl } from "../../lib/permissions";
import EntryList from "../EntryList";

const EntriesPage = () => {
  const { sweepstakesId } = useParams();

  return <EntryList sweepstakesId={sweepstakesId} />;
};

export default withAccessControl({ resource: "entries" })(EntriesPage);
