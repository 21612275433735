import styled from "styled-components";

export const Layout = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  gap: 12px;
  padding: 12px 0;

  @media (max-width: 768px) {
    gap: 6px;
  }
`;

export const Header = styled.header`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
`;

export const Heading = styled.div`
  flex: 1;
  font-family: tondo, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Content = styled.div`
  overflow-y: auto;
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 1.25em;

  @media (max-width: 768px) {
    font-size: 1em;
  }

  @media (max-width: 600px) {
    font-size: .875em;
  }

  a {
    &:after {
      content: " › ";
      color: #333333;
    }

    @media (max-width: 600px) {
      display: none;
    }
  }
`;

export const Name = styled.div`
  font-size: .875em;
  color: #000000;

  @media (max-width: 768px) {
    font-size: .75em;
  }
`;
