import React, { useState } from "react";
import CountUp from "react-countup";
import { useQuery } from "@apollo/client";

import { totalDonationsQuery } from "./gql";
import * as UI from "./ui";

const TotalDonations = ({ sweepstakesId }) => {
  const [ totalDonations, setTotalDonations ] = useState({ start: 0, end: 0 });

  const { error } = useQuery(totalDonationsQuery, {
    variables: { sweepstakesId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    pollInterval: 60000,
    onCompleted: data => {
      const nextEnd = (data?.sweepstakes?.totalDonations || 0) / 100;
      setTotalDonations({ start: totalDonations.end, end: nextEnd });
    },
  });

  if(error) {
    return null;
  }

  return (
    <UI.Total>
      <CountUp {...totalDonations} prefix="$" decimals={2} duration={1} />
    </UI.Total>
  );
}

export default TotalDonations;
