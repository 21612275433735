import React from "react";
import { useQuery } from "@apollo/client";
import { pathOr } from "ramda";

import Money from "../design/Money";
import { activePromoCodesQuery } from "./gql";
import * as UI from "./ui";

const getTotal = pathOr(0, ['insights', 'all', 'data', 0, 'total']);

const ActivePromoCodes = ({ sweepstakes: { id }}) => {
  const { data, loading, error } = useQuery(activePromoCodesQuery, {
    variables: { sweepstakesId: id },
  });

  if(loading || error) return null;

  const promoCodes = data?.sweepstakes?.activePromoCodes || [];

  if(promoCodes.length === 0) {
    return <UI.Empty>There are no active promo codes yet!</UI.Empty>;
  };

  return (
    <UI.List>
      { promoCodes.map(promoCode => (
        <UI.ListItem key={promoCode.id}>
          <UI.PromoCode to="./promo-codes" state={{ selectedItemId: promoCode.id }}>
            <UI.Name>{promoCode.name}</UI.Name>
            <UI.Amount><Money amount={getTotal(promoCode)} /></UI.Amount>
          </UI.PromoCode>
        </UI.ListItem>
      ))}
    </UI.List>
  );
}

export default ActivePromoCodes;
