import { gql } from "@apollo/client";

export const newEntrySetupQuery = gql`
  query NewEntrySetup($sweepstakesId: SweepstakesSlug!) {
    sweepstakes(id: $sweepstakesId) {
      id
      timezone
      promoCodes {
        name
        bonusPercent
      }
      donationLevels {
        id
        level
        price
        rewardTickets
      }
    }
  }
`;

export const createEntryMutation = gql`
  mutation CreateEntry($sweepstakesId: SweepstakesSlug!, $attributes: EntryInput!) {
    createEntry(sweepstakesId: $sweepstakesId, attributes: $attributes) {
      entry {
        id
        createdAt
        status
        amount
        paymentInfo
        ticketCount
        notes

        firstName
        lastName
        phoneNumber
        email

        address {
          id
          line1
          line2
          city
          state
          postalCode
          country
        }

        ticketRanges {
          min
          max
        }

        promoCode {
          id
          name
        }
      }
      error {
        code
        message
      }
    }
  }
`;

export const updateEntryMutation = gql`
  mutation UpdateEntry($id: ID!, $attributes: EntryInput!) {
    updateEntry(id: $id, attributes: $attributes) {
      entry {
        id
        createdAt
        status
        amount
        paymentInfo
        ticketCount
        notes

        firstName
        lastName
        phoneNumber
        email

        address {
          id
          line1
          line2
          city
          state
          postalCode
          country
        }

        ticketRanges {
          min
          max
        }

        promoCode {
          id
          name
        }
      }
      error {
        code
        message
      }
    }
  }
`;

export const resendReceiptMutation = gql`
  mutation ResendReceiptMutation($id: ID!) {
    resendReceipt(id: $id) {
      error {
        code
        message
      }
    }
  }
`;

export const refundManualEntryMutation = gql`
  mutation RefundManualEntry($id: ID!) {
    refundManualEntry(id: $id) {
      entry {
        id
        status
      }
      error {
        code
        message
      }
    }
  }
`;

export const decryptDataMutation = gql`
  mutation DecryptData($encryptedData: String!) {
    decryptData(encryptedData: $encryptedData) {
      data
    }
  }
`;
