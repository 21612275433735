import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import ConnectButton from "../../ConnectStripe/ConnectButton";
import * as UI from "./ui";

const ConnectPaymentAccount = ({ sweepstakes: { nonprofit }, onStepFinish }) => {
  if(nonprofit.paymentAccountConnected) {
    return (
      <UI.SimpleStepInstructions>
        <UI.Header>
          <FontAwesomeIcon icon={faCheckCircle} style={{color: "green", marginRight: 8}} />
          Payment Account Connected
        </UI.Header>
        <p>Your payment account has been successfully connected to your TapKat account!<br />If you have any questions or need assistance, please contact <a href="mailto:support@tapkat.com">support</a>.</p>
      </UI.SimpleStepInstructions>
    );
  }

  return (
    <UI.SimpleStepInstructions>
      <div>
        <p style={{marginBottom: 24}}>Click the button below to set up and connect your secure Stripe credit card processing account to your TapKat account. You will be redirected to Stripe&apos;s website.</p>
        <ConnectButton nonprofit={nonprofit} />
      </div>
    </UI.SimpleStepInstructions>
  );
}

export default ConnectPaymentAccount;
