import React from "react";
import { DndContext, closestCenter, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";

import Item from "./Item";
import * as UI from "./ui";

const Collection = ({ items, handleDragEnd, onSelect, onRemove, removeEnabled, itemLabel, ItemComponent }) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      }
    }),
    useSensor(TouchSensor)
  );


  return (
    <DndContext
      autoScroll={false}
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        <UI.CollectionLayout>
          {items.map((item, index) => (
            <Item
              key={item.id}
              item={item}
              index={index}
              itemLabel={itemLabel}
              onSelect={onSelect}
              onRemove={onRemove}
              removeEnabled={removeEnabled}
              ItemComponent={ItemComponent}
            />
          ))}
        </UI.CollectionLayout>
      </SortableContext>
    </DndContext>
  );
}
export default Collection;
