import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const config = {
  app_id: process.env.REACT_APP_INTERCOM_APP_ID,
  hide_default_launcher: true,
};

const Intercom = () => {
  const { user } = useAuth0();

  useEffect(() => {
    window.Intercom('boot', {
      ...config,
      email: user.email,
      user_id: user.sub,
      name: user.name,
    });

    return () => { window.Intercom('shutdown') };
  }, [user]);

  return null;
};

export default Intercom;
