import React from "react";
import { faStripeS } from "@fortawesome/free-brands-svg-icons"

import AccessControlledButton from "../../lib/AccessControlledButton";
import useLocalStorage from "../../lib/useLocalStorage";
import { RETURN_PATH_KEY } from "./";

const ConnectButton = ({ nonprofit, ...rest }) => {
  const [ , setReturnPath ] = useLocalStorage(window.location.pathname, RETURN_PATH_KEY);

  const onClick = () => setReturnPath(window.location.pathname);
  const redirectUri = `${window.location.origin}/stripe`
  const connectionUri = `${nonprofit.paymentAccountConnectionUrl}&redirect_uri=${redirectUri}`;

  return (
    <AccessControlledButton
      as="a"
      href={connectionUri}
      type="submit" icon={faStripeS}
      onClick={onClick}
      disabled={nonprofit.paymentAccountConnected}
      resource="paymentAccount"
      permission="C"
      {...rest}
    >
      Connect with Stripe
    </AccessControlledButton>
  )
}

export default ConnectButton;
