import { gql } from "@apollo/client";

export const setSponsorsMutation = gql`
  mutation SetSponsors($sweepstakesId: SweepstakesSlug!, $sponsors: [SponsorInput!]!) {
    setSponsors(sweepstakesId: $sweepstakesId, sponsors: $sponsors) {
      sweepstakes {
        id
        sponsors {
          id
          website
          logo {
            cloudName
            publicId
            version
          }
        }
      }
      error {
        code
      }
    }
  }
`;
