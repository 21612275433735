import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { withAccessControl } from "../../lib/permissions";
import ExportButton from "../ExportButton";
import Money from "../design/Money";
import BarTable from "../../lib/BarTable";
import { retroQuery } from "./gql";
import * as UI from "./ui";

const SweepstakesRetrospectivePage = () => {
  const { sweepstakesId } = useParams();
  const { data, loading, error } = useQuery(retroQuery, {
    variables: {
      id: sweepstakesId,
    }
  });

  if(loading || error) {
    return null;
  }

  const report = data.sweepstakesRetrospectiveReport;

  const donationLevelSeries = report.donationLevelSubreports.map((subreport) => ({
    label: <Money amount={subreport.donationLevel.price} />,
    price: subreport.donationLevel.price,
    count: subreport.count,
    total: subreport.total,
  })).sort((a, b) => a.price - b.price);

  const promoCodeSeries = report.promoCodeSubreports.map((subreport) => ({
    label: subreport.promoCode.name,
    count: subreport.count,
    total: subreport.total,
  }));

  const topDonorsByTotalSeries = report.topDonorsByTotal.map((donor) => ({
    label: donor.email,
    count: donor.count,
    total: donor.total,
  }));

  const topDonorsByCountSeries = report.topDonorsByCount.map((donor) => ({
    label: donor.email,
    count: donor.count,
    total: donor.total,
  }));

  const repeatDonorSeries = report.repeatDonorBreakdown.map((subreport) => ({
    label: `${subreport.repeatedDonationCount}X Donors`,
    count: subreport.donorCount,
  }));

  const totalTicketCount = parseInt(report.totalTicketCount, 10);
  const donationTicketCount = parseInt(report.successfulTicketCount, 10) + parseInt(report.manualTicketCount, 10);
  const refundTicketCount = parseInt(report.refundedTicketCount, 10);
  const disputeTicketCount = parseInt(report.disputedTicketCount, 10);
  const freeTicketCount = parseInt(report.freeTicketCount, 10);

  const entryStatusSeries = [
    { label: "Donations",      percentage: Number(donationTicketCount / totalTicketCount * 100).toFixed(2) },
    { label: "AMoE",           percentage: Number(freeTicketCount / totalTicketCount * 100).toFixed(2) },
    { label: "Refunded",       percentage: Number(refundTicketCount / totalTicketCount * 100).toFixed(2) },
    { label: "Disputed",       percentage: Number(disputeTicketCount / totalTicketCount * 100).toFixed(2) },
  ];

  return (
    <UI.Layout>
      <UI.Header>
        <UI.HeaderContent>
          <UI.Title>Retrospective Report</UI.Title>
          <UI.Subtitle>Sweepstakes #{report.sequenceNumber} | {report.sweepstakes.prize.name}</UI.Subtitle>
        </UI.HeaderContent>
        <UI.HeaderActions>
          <ExportButton url={`/exports/${sweepstakesId}/retrospective.pdf`} resource="entries" contentType="application/pdf">
            1-Page Summary
          </ExportButton>
        </UI.HeaderActions>
      </UI.Header>
      <UI.Stat>
        <UI.Label>
          <UI.Name>Total Raise</UI.Name>
          <UI.Description>Total amount donated</UI.Description>
        </UI.Label>
        <UI.Value>
          <UI.Primary><Money amount={report.donationTotal} /></UI.Primary>
        </UI.Value>
      </UI.Stat>
      <UI.Stat>
        <UI.Label>
          <UI.Name>Number of Entries</UI.Name>
          <UI.Description>Total number of entries assigned in this sweepstakes</UI.Description>
        </UI.Label>
        <UI.Value>
          <UI.Primary>{Number(report.totalTicketCount).toLocaleString()}</UI.Primary>
        </UI.Value>
      </UI.Stat>
      { report.sequenceNumber > 1 && (
        <UI.Stat>
          <UI.Label>
            <UI.Name>Lifetime Total Raise</UI.Name>
            <UI.Description>Total amount donated to this and prior sweepstakes</UI.Description>
          </UI.Label>
          <UI.Value>
            <UI.Primary><Money amount={report.lifetimeDonationTotal} /></UI.Primary>
          </UI.Value>
        </UI.Stat>
      )}
      <UI.Stat>
        <UI.Label>
          <UI.Name>Number of Donations</UI.Name>
          <UI.Description>Total number of donations</UI.Description>
        </UI.Label>
        <UI.Value>
          <UI.Primary>{Number(report.donationCount).toLocaleString()}</UI.Primary>
        </UI.Value>
      </UI.Stat>
      <UI.Stat>
        <UI.Label>
          <UI.Name>Average Donation Amount</UI.Name>
          <UI.Description>Average donation amount</UI.Description>
        </UI.Label>
        <UI.Value>
          <UI.Primary><Money amount={report.averageDonation} /></UI.Primary>
        </UI.Value>
      </UI.Stat>
      <UI.Stat>
        <UI.Label>
          <UI.Name>Donation Amounts</UI.Name>
          <UI.Description>Total amount and count of donations at each amount</UI.Description>
        </UI.Label>
        <UI.Table>
          <BarTable series={donationLevelSeries} columns={["label", "total", "count"]} />
        </UI.Table>
      </UI.Stat>
      <UI.Stat>
        <UI.Label>
          <UI.Name>New Donors</UI.Name>
          <UI.Description>Donors who donated for the first time</UI.Description>
        </UI.Label>
        <UI.Value>
          <UI.Primary>{report.newDonorPercentage.toFixed(2)}%</UI.Primary>
          <UI.Secondary>{report.newDonorCount}</UI.Secondary>
        </UI.Value>
      </UI.Stat>
      <UI.Stat>
        <UI.Label>
          <UI.Name>Returning Donors</UI.Name>
          <UI.Description>Donors who donated to a prior sweepstakes and then donated to this one</UI.Description>
        </UI.Label>
        <UI.Value>
          <UI.Primary>{report.returningDonorPercentage.toFixed(2)}%</UI.Primary>
          <UI.Secondary>{report.returningDonorCount}</UI.Secondary>
        </UI.Value>
      </UI.Stat>
      <UI.Stat>
        <UI.Label>
          <UI.Name>Repeat Donors</UI.Name>
          <UI.Description>Donors who donated to this sweepstakes more than once</UI.Description>
        </UI.Label>
        <UI.Value>
          <UI.Primary>{report.repeatDonorPercentage.toFixed(2)}%</UI.Primary>
          <UI.Secondary>{report.repeatDonorCount}</UI.Secondary>
        </UI.Value>
      </UI.Stat>
      { repeatDonorSeries.length > 0 && (
        <UI.Stat>
          <UI.Label>
            <UI.Name>Repeat Donor Breakdown</UI.Name>
            <UI.Description>Number of donors who donated a certain number of times</UI.Description>
          </UI.Label>
          <UI.Table>
            <BarTable series={repeatDonorSeries} columns={["label", "count"]} />
          </UI.Table>
        </UI.Stat>
      )}
      <UI.Stat>
        <UI.Label>
          <UI.Name>Top Donors</UI.Name>
          <UI.Description>Donors with the highest total donated</UI.Description>
        </UI.Label>
        <UI.Table>
          <BarTable series={topDonorsByTotalSeries} columns={["label", "total", "count"]} />
        </UI.Table>
      </UI.Stat>
      <UI.Stat>
        <UI.Label>
          <UI.Name>Most Active Donors</UI.Name>
          <UI.Description>Donors with the highest number of donations</UI.Description>
        </UI.Label>
        <UI.Table>
          <BarTable series={topDonorsByCountSeries} columns={["label", "total", "count"]} />
        </UI.Table>
      </UI.Stat>
      <UI.Stat>
        <UI.Label>
          <UI.Name>Entry Status Breakdown</UI.Name>
          <UI.Description>Useful for understanding entry eligibility and the Alternative Method of Entry</UI.Description>
        </UI.Label>
        <UI.Table>
          <BarTable series={entryStatusSeries} columns={["label", "percentage"]} />
        </UI.Table>
      </UI.Stat>
      { promoCodeSeries.length > 0 && (
        <UI.Stat>
          <UI.Label>
            <UI.Name>Top Promo Codes</UI.Name>
            <UI.Description>Best performing promo codes by total amount and count of donations</UI.Description>
          </UI.Label>
          <UI.Table>
            <BarTable series={promoCodeSeries} columns={["label", "total", "count"]} />
          </UI.Table>
        </UI.Stat>
      )}
    </UI.Layout>
  );
}

export default withAccessControl({ resource: "sweepstakesRetrospectiveReports", permission: "R" })(SweepstakesRetrospectivePage);
