import React from "react";
import { props, identity } from "ramda";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import AccessControlledLink from "../../lib/AccessControlledLink";
import ProgressGauge from "../design/ProgressGauge";

const CHECKLIST_PROPS = [
  'sweepstakesDetails',
  'donationLevels',
  'slides',
  'copy',
  'schedule',
  'mailInAddress',
  'paymentAccount',
  'nonprofitBranding',
  'aboutNonprofit',
  'nonprofitContact',
];

const SetupProgress = ({ sweepstakes }) => {
  if(sweepstakes.approved) return null;

  const checklist = props(CHECKLIST_PROPS, sweepstakes.checklist);

  const completedSteps = Object.values(checklist).filter(identity).length;
  const totalSteps = Object.keys(checklist).length;
  const percentage = Math.round(100.0 * completedSteps / totalSteps)

  const subheading = `${completedSteps} of ${totalSteps} Steps Complete`;

  return (
    <React.Fragment>
      <ProgressGauge heading="Sweepstakes Setup" subheading={subheading} percentage={percentage} />
      <AccessControlledLink to="./site" icon={faArrowRight} size="small" resource="sweepstakes" permission="U">Setup Sweepstakes</AccessControlledLink>
    </React.Fragment>
  );
};

export default SetupProgress;
