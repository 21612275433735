import { gql } from "@apollo/client";

export const sweepstakesWidgetGuideQuery = gql`
  query($id: SweepstakesSlug!) {
    sweepstakes(id: $id) {
      id
      nonprofit {
        id
        profile {
          id
          color
        }
      }
    }
  }
`;
