import styled, { keyframes, css } from "styled-components";

export const Label = styled.div`
`;

const blurIn = keyframes`
  from {
    background-color: rgba(0,0,0,0);
    backdrop-filter: blur(0px);
  }

  to {
    background-color: rgba(0,0,0,0.25);
    backdrop-filter: blur(12px);
  }
`;

const blurOut = keyframes`
  from {
    background-color: rgba(0,0,0,0.25);
    backdrop-filter: blur(12px);
  }

  to {
    background-color: rgba(0,0,0,0);
    backdrop-filter: blur(0px);
  }
`;

export const Layout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  animation: ${blurIn} 150ms ease-in forwards;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props => props.$closing && css`
    animation: ${blurOut} 150ms ease-in forwards;
  `}
`;

const slideIn = keyframes`
  from {
    translate: 0 -48px;
    opacity: 0;
  }

  to {
    translate: 0 0;
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    translate: 0 0;
    opacity: 1;
  }

  to {
    translate: 0 48px;
    opacity: 0;
  }
}`;

const slideInMobile = keyframes`
  from {
    translate: 0 48px;
    opacity: 0;
  }

  to {
    translate: 0 0;
    opacity: 1;
  }
`;

const slideOutMobile = keyframes`
  from {
    translate: 0 0;
    opacity: 1;
  }

  to {
    translate: 0 -48px;
    opacity: 0;
  }
`;

export const Content = styled.div`
  @media (min-width: 769px) {
    animation: ${slideIn} 150ms ease-in-out forwards;

    ${props => props.$closing && css`
      animation: ${slideOut} 150ms ease-in-out forwards;
    `}
  }

  @media (max-width: 768px) {
    width: 100vw;
    align-self: flex-end;
    padding: 12px;
    animation: ${slideInMobile} 150ms ease-in-out forwards;

    ${props => props.$closing && css`
      animation: ${slideOutMobile} 150ms ease-in-out forwards;
    `}
  }
`;
