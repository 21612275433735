import { useState, useCallback } from "react";
import { equals, isNil } from "ramda";

import { isPresent } from "../util/presence";
import useLocalStorage from "./useLocalStorage";

const useDraftableState = (initialState, key) => {
  const [ draft, setDraft ] = useLocalStorage(initialState, key);

  const loadDraft = isPresent(key) && !isNil(draft) && !equals(draft, initialState);

  const [ state, setState ] = useState(loadDraft ? draft : initialState);

  const clearDraft = useCallback(() => setDraft(null), [ setDraft ]);

  const discardDraft = useCallback(() => {
    setState(initialState);
    setDraft(initialState);
  }, [ setState, setDraft, initialState ]);

  const setStateAndDraft = val => {
    // If clearDraft was called before modifying state
    // restore the state to localStorage
    if (isNil(draft)) {
      setDraft(state);
    }
    setDraft(val);
    return setState(val);
  }

  const draftControls = {
    loaded: loadDraft,
    discard: discardDraft,
    clear: clearDraft,
  };

  return [ state, setStateAndDraft, draftControls ];
};

export default useDraftableState;
