import React, { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import MenuBody from "./MenuBody";

const Hotkey = ({ hotkey, setActive }) => {
  useHotkeys(hotkey, () => setActive(true));

  return null;
};

const Menu = ({ label, children, hotkey }) => {
  const [ active, setActive ] = useState(false);

  useHotkeys("esc", () => setActive(false));

  return (
    <React.Fragment>
      { hotkey && <Hotkey hotkey={hotkey} setActive={setActive} /> }
      { React.cloneElement(label, { onClick: () => setActive(!active) }) }
      { active && (
        <MenuBody requestClose={() => setActive(false)}>
          {children}
        </MenuBody>
      )}
    </React.Fragment>
  );
}

export default Menu;
