import React, { useState, useMemo } from "react";

import ExportButton from "../ExportButton";
import RadioControl from "../design/RadioControl";
import InsightCard from "./InsightCard";
import { accumulateSeries, insightStatsSummary } from "../../util/insights";
import * as UI from "./ui";

const RESOLUTIONS = [
  { id: "monthly", name: "Monthly", export: "monthly", unit: "Month" },
  { id: "weekly",  name: "Weekly",  export: "weekly",  unit: "Week"  },
  { id: "daily",   name: "Daily",   export: "daily",   unit: "Day"   },
];

const DEFAULT_RESOLUTION = "weekly";
const DEFAULT_MODE = "perBucket";

const AllTimeCard = ({ monthly, weekly, daily, sweepstakesId }) => {
  const [ mode, setMode ] = useState(DEFAULT_MODE);
  const [ resolution, setResolution ] = useState(DEFAULT_RESOLUTION);

  const currentResolution = RESOLUTIONS.find(item => resolution === item.id)
  const MODES = [
    { id: "perBucket", name: `Per ${currentResolution.unit}` },
    { id: "runningTotal", name: `${currentResolution.name} Running Total` },
  ];

  const data = { monthly, weekly, daily }[resolution];
  const series = useMemo(() => mode === "runningTotal" ? accumulateSeries(data) : data, [data, mode]);
  const insightCardProps = useMemo(() => insightStatsSummary(data), [data]);

  const controls = (
    <UI.Controls>
      <RadioControl options={RESOLUTIONS} defaultOption={DEFAULT_RESOLUTION} onChange={setResolution} data-intercom-target="All-time Insights Resolution" />
      <RadioControl options={MODES} defaultOption={DEFAULT_MODE} onChange={setMode} data-intercom-target="All-time Insights Mode" />
      <ExportButton url={`/exports/${sweepstakesId}/performance_timeline_insight.csv?resolution=${currentResolution.export}`} resource="insights" size="tiny">
        {currentResolution.name} Data 
      </ExportButton>
    </UI.Controls>
  );

  return (
    <InsightCard heading="All Time" series={series} controls={controls} {...insightCardProps} />
  );
}

export default AllTimeCard;
