import React, { useState, useEffect } from "react";

import * as UI from "./ui";

const Message = ({ type, message }) => {
  const [ visible, setVisible ] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setVisible(false), 4000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <UI.Layout type={type} visible={visible}>
      <UI.Message>{message}</UI.Message>
      <UI.Close onClick={() => setVisible(false)}>×</UI.Close>
    </UI.Layout>
  )
}

export default Message;
