import React from "react";
import { useQuery } from "@apollo/client";

import { withAccessControl } from "../../lib/permissions";
import Money from "../design/Money";
import { getRecentDonationActivity } from "./gql";
import * as UI from "./ui";

const UnauthorizedComponent = () => <UI.Empty>Sorry, you do not have permission to see donations.</UI.Empty>;

const DonationActivityFeed = ({ sweepstakes: { id }}) => {
  const { data, loading, error } = useQuery(getRecentDonationActivity, {
    variables: { sweepstakesId: id },
  });

  if(loading || error) {
    return null;
  }

  const entries = data?.sweepstakes?.entries || [];

  if(entries.length === 0) {
    return <UI.Empty>There are no donations yet!</UI.Empty>;
  }

  return (
    <UI.EntryList>
      {entries.map(entry => (
        <UI.EntryListItem key={entry.id}>
          <UI.Entry to="./donations" state={{ selectedItemId: entry.id }}>
            <UI.Name>{entry.firstName} {entry.lastName}</UI.Name>
            <UI.Amount $status={entry.status} $amount={entry.amount}><Money amount={entry.amount} /></UI.Amount>
          </UI.Entry>
        </UI.EntryListItem>
      ))}
    </UI.EntryList>
  );
}

export default withAccessControl({ resource: "entries", UnauthorizedComponent })(DonationActivityFeed)
