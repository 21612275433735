import styled from "styled-components";

export const FieldGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FormFooter = styled.div`
  margin: 12px 6px;
`;
