import React from "react";
import { useQuery } from "@apollo/client";
import { pathOr } from "ramda";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import StatSummary from "../design/StatSummary";
import Link from "../design/Link";
import Slide from "./Slide";
import { activeFlashContestQuery } from "./gql";
import * as UI from "./ui";

const FlashContestOverview = ({ sweepstakes: { id }}) => {
  const { data, loading, error } = useQuery(activeFlashContestQuery, {
    variables: { sweepstakesId: id }
  });

  if(loading || error) {
    return null;
  }

  const flashContest = pathOr(null, ["sweepstakes", "activeFlashContest"], data);

  if(!flashContest) {
    return null;
  }

  const insightData = pathOr({}, ["insights", "all", "data", 0], flashContest );

  return (
    <UI.Section style={{ gridArea: "flash-contest" }}>
      <UI.SectionHeading>Active Flash Contest: {flashContest.name}</UI.SectionHeading>
      <UI.Subsection>
        { flashContest.image && <Slide image={flashContest.image} width={168} /> }
        <UI.SubsectionContent>
          <StatSummary {...insightData} />
          <Link to="./flash-contests" icon={faArrowRight} size="small">Manage Flash Contests</Link>
        </UI.SubsectionContent>
      </UI.Subsection>
    </UI.Section>
  );
}

export default FlashContestOverview;
