import { gql } from "@apollo/client";

export const promoCodeFragment = gql`
  fragment PromoCodeFragment on PromoCode {
    id
    name
    bonusPercent
    expiresAt
    notes
    autoApplyUrl
    inUse
    locked
  }
`

export const sweepstakesPromoCodesQuery = gql`
  query SweepstakesPromoCodesQuery($sweepstakesId: SweepstakesSlug!) {
    sweepstakes(id: $sweepstakesId) {
      id
      timezone
      promoCodes {
        ...PromoCodeFragment
      }
    }
  }
  ${promoCodeFragment}
`;

export const showPromoCodeQuery = gql`
  query ShowPromoCodeQuery($sweepstakesId: SweepstakesSlug!, $id: ID!) {
    sweepstakes(id: $sweepstakesId) {
      id
      timezone
      promoCode(id: $id) {
        ...PromoCodeFragment
        firstUsedAt
        insights {
          monthly: performanceTimeline(resolution: MONTHLY) {
            data {
              bucket
              total
              count
              average
            }
          }
          weekly: performanceTimeline(resolution: WEEKLY) {
            data {
              bucket
              total
              count
              average
            }
          }
          daily: performanceTimeline(resolution: DAILY) {
            data {
              bucket
              total
              count
              average
            }
          }
        }
      }
    }
  }
  ${promoCodeFragment}
`;

export const createPromoCodeMutation = gql`
  mutation CreatePromoCode($sweepstakesId: SweepstakesSlug!, $attributes: PromoCodeInput!) {
    createPromoCode(sweepstakesId: $sweepstakesId, attributes: $attributes) {
      promoCode {
        ...PromoCodeFragment,
      }
      error {
        code
        message
      }
    }
  }
  ${promoCodeFragment}
`;

export const updatePromoCodeMutation = gql`
  mutation updatePromoCode($sweepstakesId: SweepstakesSlug!, $id: ID!,$attributes: PromoCodeInput!) {
    updatePromoCode(sweepstakesId: $sweepstakesId, id: $id, attributes: $attributes) {
      promoCode {
        ...PromoCodeFragment,
      }
      error {
        code
        message
      }
    }
  }
  ${promoCodeFragment}
`;
