import { gql } from "@apollo/client";

export const nonprofitInvoiceQuery = gql`
  query nonprofitInvoiceQuery($nonprofitId: NonprofitSlug!) {
    nonprofit(id: $nonprofitId) {
      id
      invoices {
        id
        total
        createdAt
        sentAt
        dueAt
        paidAt
        status
        description
        number

        hostedInvoiceUrl
        invoicePdf
        downloadUrl

        sweepstakes {
          id
          prize {
            id
            name
          }
        }
      }
    }
  }
`;
