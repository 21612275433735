import styled from "styled-components";

export const Layout = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 48px 24px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 24px;
  flex-wrap: wrap;
  gap: 12px;
`;

export const HeaderContent = styled.div`
  font-family: tondo, sans-serif;
`;

export const HeaderActions = styled.div`
`;

export const Title = styled.div`
  font-size: 1.5em;
  font-weight: bold;
`;

export const Subtitle = styled.div`
`;

export const Stat = styled.div`
  display: flex;
  align-items: top;
  color: #222;
  x-background-color: #EBF9D1;
  padding: 12px 24px;
  border-radius: 12px;
  flex-wrap: wrap;
  gap: 12px;
`;

export const Label = styled.div`
  flex: 1;
`;

export const Value = styled.div`
  text-align: right;
`;

export const Name = styled.div`
  font-size: 1.125em;
  font-weight: bold;
  font-family: tondo, sans-serif;
`;

export const Description = styled.div`
  font-size: 0.875em;
`;

export const Primary = styled.div`
  font-size: 1.5em;
`;

export const Secondary = styled.div`
`;

export const Table = styled.div`
  flex: 1;
  min-width: 100%;
`;
