import React from "react";

import { isBlank } from "../../util/presence";
import * as UI from "./ui";

const ResultsSection = ({ title, results }) => {
  if (isBlank(results)) {
    return null;
  }

  return (
    <React.Fragment>
      <UI.ResultsHeading>{title}</UI.ResultsHeading>
      <UI.ResultsSection>
        {results.map(result => (
          <UI.Result key={result.id} to={result.url}>
            <UI.Title>{result.title}</UI.Title>
            <UI.Subtitle>{result.subtitle}</UI.Subtitle>
          </UI.Result>
        ))}
      </UI.ResultsSection>
    </React.Fragment>
  );
};

export default ResultsSection;
