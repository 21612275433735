import React from "react";

import * as UI from "./ui";

const Empty = () => (
  <UI.EmptyLayout>
    <UI.EmptyMessage>No Donations Found</UI.EmptyMessage>
    <UI.Paragraph>
      Hmmm… there are no donations that match those search parameters.
    </UI.Paragraph>
    <UI.Paragraph>
      Try searching by:
      <UI.List>
        <UI.ListItem>last name</UI.ListItem>
        <UI.ListItem>first and last name</UI.ListItem>
        <UI.ListItem>email address</UI.ListItem>
        <UI.ListItem>telephone number</UI.ListItem>
        <UI.ListItem>entry number, e.g. #1234</UI.ListItem>
      </UI.List>
    </UI.Paragraph>

    <UI.Paragraph>
      If you still can't find the donation you're looking for, or if you are looking for help with an AMoE (Alternative Method of Entry) entry, please contact our <a href="mailto:support@tapkat.com">support team</a>.
    </UI.Paragraph>
  </UI.EmptyLayout>
);

export default Empty;
