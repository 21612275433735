import styled from "styled-components";

export const Layout = styled.div`
  padding: 12px;
`;

export const Title = styled.h1`
  font-size: 1.25em;
  font-family: tondo, sans-serif;
`;

export const Heading = styled.h2`
  font-size: 1em;
  margin: 0;
`;

export const Paragraph = styled.p`
  font-size: 0.875em;
  margin-bottom: 12px;
`;

export const HintList = styled.ol`
  margin: 0 12px;
  padding-left: 0;
  list-style-type: disc;
`;

export const Hint = styled.li`
  padding-left: 6px;
  margin-bottom: 6px;
  font-size: 0.875em;
`;
