import React from 'react';

import Sparkline, { CellOverlay } from "../design/Sparkline";
import useDimensions from '../../lib/useDimensions';
import * as UI from "./ui";

const Chart = ({ series, selectedIndex, setSelectedIndex }) => {
  const [ ref, dimensions ] = useDimensions();

  const width = dimensions.width;
  const height = dimensions.height / 3;
  const sparkProps = { series, selectedIndex, height, width };

  return (
    <UI.Chart ref={ref} xmlns="http://www.w3.org/2000/svg" onMouseLeave={() => setSelectedIndex(null)}>
      <Sparkline {...sparkProps} attribute="total"   y={0}          />
      <Sparkline {...sparkProps} attribute="count"   y={height}     />
      <Sparkline {...sparkProps} attribute="average" y={height * 2} />
      <CellOverlay height={dimensions.height} width={width} length={series.length} onSelect={setSelectedIndex} />
    </UI.Chart>
  );
};

export default Chart;
