import React, { useContext } from "react";

import { combineValidations, presence, lengthOf } from "../../../../lib/validations";
import Formation from "../../../forms/Form";
import TextField from "../../../forms/TextField";
import ImageUploadField from "../../../forms/ImageUploadField";
import Button from "../../../design/Button";
import { Context } from "./";

const slidePreview = {
  aspectRatio: "16:9",
  width: "600",
  fetchFormat: "auto",
  style: {
    maxWidth: '600px',
    width: '100%',
  },
};

const CROP_OPTIONS = [
  {
    id: 'fill',
    name: 'Crop to Fit',
    strategy: {
      crop: 'fill',
      gravity: 'auto',
      background: null,
    },
  },
  {
    id: 'lpad',
    name: 'No Crop',
    strategy: {
      crop: 'lpad',
      gravity: null,
      background: 'auto',
    }
  },
];

const validations = {
  caption: combineValidations([presence, lengthOf({ maximum: 180 })]),
};

const Form = ({ initialInputObject, onCancel, onSubmit, loading }) => {
  const { sweepstakes } = useContext(Context);

  return (
    <Formation initialInputObject={initialInputObject} onSubmit={onSubmit} validations={validations}>
      <ImageUploadField
        id="image"
        label="Slide Image"
        owner={sweepstakes.id}
        directory="slides"
        previewProps={slidePreview}
        crops={CROP_OPTIONS}
      />
      <TextField id="caption" label="Caption" />
      <Button size="small" onClick={onCancel}>Cancel</Button>
      <Button size="small" type="submit" disabled={loading}>Done</Button>
    </Formation>
  );
}

export default Form;
