import axios from "axios";
import { nth } from "ramda";
import { download } from "../../util/file";

const exportDonations = (url, token, contentType) => (
  axios({
    url,
    baseURL: `${process.env.REACT_APP_API_URL}/`,
    method: "get",
    responseType: "blob",
    headers: {
      "Content-Type": contentType,
      "Authorization":  `Bearer ${token}`,
    }
  })
);

const getCSVFileName = headers =>
  nth(
    1,
    /filename[^;=\n]*=['"](.*?\2|[^;\n]*)['"]/.exec(
      headers["content-disposition"]
    )
  );

const handleResponse = response =>
  download(response.data, getCSVFileName(response.headers));

export const downloadExport = (url, token) => exportDonations(url, token).then(handleResponse);
