import styled from "styled-components";
import { Link } from "react-router-dom";

export const EntryList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const EntryListItem = styled.li`
`;

export const Entry = styled(Link)`
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #ffffff;
  padding: 6px 12px;
  border-radius: 3px;
`;

export const Name = styled.div`
  font-size: 0.875em;
  font-weight: bold;
  line-height: 1em;
  flex: 1;
  color: #333333;
`;

export const PromoCode = styled.div`
  padding: 3px 6px;
  background-color: #1d4f90;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  border-radius: 3px;
  font-size: 0.625em;
`;

const amountBackgroundColor = ({ $status, $amount }) => {
  if(["DISPUTED", "REFUNDED"].includes($status)) {
    return "#db3a34";
  }

  return $amount > 0 ? "#77aa16" : "#aaaaaa";
};

export const Amount = styled.div`
  display: inline-block;
  padding: 3px 6px;
  background-color: ${amountBackgroundColor};
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  border-radius: 3px;
  font-size: 0.875em;
  width: 84px;
`;

export const Empty = styled.div`
  font-size: 0.875em;
  text-align: center;
  padding: 24px 12px;
  background-color: #ffffff;
  border-radius: 3px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
