import React, { useRef, useCallback } from "react";
import styled from "styled-components";
import ReactQRCode from "react-qr-code";

const Element = styled(ReactQRCode)`
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

const ExternalEventTarget = styled.div`
  display: none;
`;

const QRCode = ({ fileName, value }, ref) => {
  const el = useRef(null);

  const onImageDownload = useCallback(() => {
    const svg = el.current;
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = `${fileName}.png`;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };

    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  }, [el, fileName]);

  return (
    <React.Fragment>
      <ExternalEventTarget ref={ref} onClick={onImageDownload} />
      <Element
        size={3600}
        onClick={onImageDownload}
        ref={el}
        value={value}
      />
    </React.Fragment>
  );
};

export default React.forwardRef(QRCode);
