import React, { useState } from "react";
import {
  all,
  assoc,
  assocPath,
  is,
  isEmpty,
  join,
  pipe,
  reduce,
  toPairs,
  values as objValues,
} from "ramda";

import FormContext from "../../lib/FormContext";
import useFormState from "../../lib/useFormState";

import * as UI from "./ui";

const SEPARATOR = "."

const tryParsingValue = value => {
  try {
    const parsed = JSON.parse(value);

    if(is(Object, parsed) && !Array.isArray(parsed)) {
      return parsed;
    }

    return value;
  }
  catch (e) {
    return value;
  }
}

const buildSubmissionObject = pipe(
  toPairs,
  reduce((acc, [id, value]) => assocPath(id.split("."), tryParsingValue(value), acc), {}),
);

const squash = (root = {}, namespace) => reduce((acc, [key, value]) => {
  const newKey = namespace ? join(SEPARATOR, [namespace, key]) : key

  if(is(Object, value) && !Array.isArray(value)) {
    return squash(acc, newKey)(toPairs(value))
  } else {
    return assoc(newKey, value, acc)
  }
}, root);

export const buildInputObject = obj => squash()(toPairs(obj))

const Form = ({initialInputObject, validations, skeleton, onSubmit, onChange, children, ...rest}) => {
  const { values, errors, setField } = useFormState(buildInputObject(initialInputObject), validations, skeleton, onChange);
  const [ touched, setTouched ] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setTouched(true);

    if(all(isEmpty, objValues(errors))) {
      const maybePromise = onSubmit(buildSubmissionObject(values));

      if(maybePromise) {
        maybePromise.then(() => {
          setTouched(false)
        });
      }
    }
  }

  return (
    <FormContext.Provider value={{values, setField, skeleton, formTouched: touched, errors}}>
      <UI.Form onSubmit={handleSubmit} {...rest}>
        {children}
      </UI.Form>
    </FormContext.Provider>
  );
};

export default Form;
