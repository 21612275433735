import React from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import Menu from "../../lib/Menu";
import SweepstakesForm from "../SweepstakesForm";
import AccessControlledButton from "../../lib/AccessControlledButton";
import * as UI from "./ui";

const Header = ({ hasSweepstakes }) => {
  const control = (
    <AccessControlledButton resource="sweepstakes" permission="C" type="submit" size={hasSweepstakes? "small" : "medium"} icon={faPlus}>
      { hasSweepstakes ? "Create Your Next Sweepstakes" : "Create Your First Sweepstakes" }
    </AccessControlledButton>
  );

  return (
    <UI.Header $fullscreen={!hasSweepstakes}>
      <Menu label={control}>
        <UI.NewSweepstakesForm>
          <UI.NewSweepstakesHeading>Create a Sweepstakes
          </UI.NewSweepstakesHeading>
          <UI.NewSweepstakesFormHint>Let’s get you started on your new TapKat sweepstakes! Enter the name of your prize here.</UI.NewSweepstakesFormHint>
          <SweepstakesForm />
        </UI.NewSweepstakesForm>
      </Menu>
    </UI.Header>
  );
}

export default Header;
