import React from "react";

import Show from "./Show";

const Detail = ({ item: invoice, onCancel, showCancel }) => {
  if(!invoice) {
    return null;
  }

  return (
    <Show invoice={invoice} onCancel={onCancel} showCancel={showCancel} />
  );
}

export default Detail;
