import styled from "styled-components";

export const Layout = styled.div`
  max-height: 40vh;
  overflow-y: auto;
`;

export const List = styled.div`
  min-width: 300px;
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
`;
