import React from "react";

import { useMasterDetailContext } from "../../../lib/MasterDetail";
import * as UI from "./ui";

export const Hints = ({ sweepstakes }) => (
  <UI.Layout>
    <UI.Title>Getting Started</UI.Title>

    <UI.Paragraph>
      The list view on the left displays the most recent donations.
    </UI.Paragraph>

    <UI.Heading>Search for a particular record by entering:</UI.Heading>
    <UI.List>
      <UI.ListItem>last name</UI.ListItem>
      <UI.ListItem>first and last name</UI.ListItem>
      <UI.ListItem>email address</UI.ListItem>
      <UI.ListItem>telephone number</UI.ListItem>
      <UI.ListItem>entry number, e.g. #1234</UI.ListItem>
    </UI.List>
  </UI.Layout>
);

const NoSelection = () => {
  const { sweepstakes } = useMasterDetailContext();

  return <Hints sweepstakes={sweepstakes} />
};

export default NoSelection;
