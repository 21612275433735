import React from "react";
import { Image } from "cloudinary-react";

import * as UI from "./ui";

const Card = ({ item }) => (
  <UI.CardLayout>
    <Image {...item.logo} crop="pad" width="auto" aspectRatio="16:9" style={{ width: "100%" }} />
    <UI.Website>{item.website}</UI.Website>
  </UI.CardLayout>
);

export default Card;
