import React from "react";
import { faLock } from "@fortawesome/free-solid-svg-icons";

import Button from "../../components/design/Button";
import { usePermissions } from "../permissions";

const AccessControlledButton = ({ resource, permission, ...rest }) => {
  const accessEnabled = usePermissions({ resource, permission });

  const icon = !accessEnabled ? faLock : rest.icon;
  const disabled = rest.disabled || !accessEnabled;

  return <Button {...rest} disabled={disabled} icon={icon} />
};

export default AccessControlledButton;
