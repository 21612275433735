import React from "react";
import { DateTime } from "luxon";
import { useQuery } from "@apollo/client";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { pathOr } from "ramda";

import Link from "../design/Link";
import { marketingScheduleQuery } from "./gql";
import * as UI from "./ui";

const MarketingSchedule = ({ sweepstakes: { id } }) => {
  const { data, loading, error } = useQuery(marketingScheduleQuery, {
    variables: {
      sweepstakesId: id,
    }
  });

  if(loading || error) {
    return null;
  }

  const events = pathOr([], ['sweepstakes', 'calendar', 'events'], data);
  const nextEvent = events.find(event => {
    const diff = DateTime.fromISO(event.date).diffNow('days').days;
    return diff > 0 && diff <= 3;
  });


  if(!nextEvent) {
    return null;
  }

  return (
    <UI.Section style={{ gridArea: "marketing" }}>
      <UI.SectionHeading>Email Smart Schedule</UI.SectionHeading>
        <UI.HintLayout>
          <UI.HintBody>
            <UI.HintHeading>{nextEvent.summary}</UI.HintHeading>
            <UI.HintText>{nextEvent.description}</UI.HintText>
            <Link to="./marketing" icon={faExternalLinkAlt} size="small" style={{ alignSelf: 'flex-end'}}>View Smart Schedule</Link>
          </UI.HintBody>
        </UI.HintLayout>
    </UI.Section>
  );
}

export default MarketingSchedule;
