import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { isNil, evolve, map, pipe, pick } from "ramda";

import CollectionEditor from "../../../../lib/CollectionEditor";
import { Context as SnackbarContext } from "../../../../lib/Snackbar";
import Card from "./Card";
import EditForm from "./EditForm";
import { setSlidesMutation } from "./gql";
import * as UI from "./ui";

export const Context = React.createContext(); 

const buildPayload = map(
  pipe(
    pick(['caption', 'image']),
    evolve({
      image: pick(['cloudName', 'publicId', 'version', 'crop', 'gravity', 'background']),
    }),
  )
);

const SlideEditor = ({ sweepstakes, onStepFinish }) => {
  const { addMessage } = useContext(SnackbarContext);
  const { slides } = sweepstakes;
  const [ setSlides ] = useMutation(setSlidesMutation);

  const handleSubmit = slides => {
    const payload = buildPayload(slides);
    setSlides({
      variables: {
        sweepstakesId: sweepstakes.id,
        slides: payload,
      }
    }).then(({ data }) => {
      if(isNil(data.setSlides.error)) {
        addMessage({ message: "Your slides have been updated.", type: "success" });
        onStepFinish();
        return;
      }
      addMessage({ message: data.setSlides.error.message, type: "error" });
    });
  };

  return (
    <UI.Layout>
      <UI.Instructions>
        <p>Add up to <strong>12 prize images</strong> (.jpg or .png) to your slide show. Click the “Add New Slide” button and upload, or drag and drop your image into the box. The image will be sized and cropped automatically. Write a caption for the slide. <strong>Click the “Done” button to save each slide</strong>. Change the order by dragging a slide into position. <strong>Click the “Publish Changes” button to save your images to your TapKat site.</strong></p>
        <p><strong>Note:</strong> You can update your slides with new photos or captions at any point during the sweepstakes.</p>
      </UI.Instructions>
      <Context.Provider value={{ sweepstakes }}>
        <CollectionEditor
          items={slides}
          onSubmit={handleSubmit}
          newItemLabel="Add New Slide"
          submitLabel="Publish Changes"
          draftNotice="You have unpublished changes."
          discardDraftLabel="Discard Changes"
          itemLabel="Slide"
          minItems={1}
          maxItems={12}
          draftKey={`SlideEditor:${sweepstakes.id}`}
          EditorComponent={EditForm}
          ItemComponent={Card}
        />
      </Context.Provider>
    </UI.Layout>
  );
};

export default SlideEditor;
