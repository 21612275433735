import styled from "styled-components";
import { Link } from "react-router-dom";

export const Layout = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  gap: 12px;

  @media (max-width: 768px) {
    gap: 6px;
  }
`;

export const Header = styled.header`
  position: sticky;
  top: 0;
  background-color: #edf6fb;
  display: flex;
  align-items: center;
  gap: 6px 12px;
  padding: 12px 0;
  z-index: 1;
  flex-wrap: wrap;
`;

export const Heading = styled.div`
  flex: 2;
  font-family: tondo, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 180px;
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 1.25em;

  @media (max-width: 768px) {
    font-size: 1em;
  }

  @media (max-width: 600px) {
    font-size: .875em;
  }

  a {
    &:after {
      content: " › ";
      color: #333333;
    }
  }
`;

export const Name = styled(Link)`
  font-size: .875em;
  color: #000000;

  @media (max-width: 768px) {
    font-size: .75em;
  }
`;

export const QuickLinks = styled.div`
  display: flex;
  gap: 6px;
  flex: 1;
  justify-content: end;

  @media (max-width: 768px) {
    > * {
      flex: 1;
      text-align: center;
    }
  }
`;

export const Content = styled.div`
  padding-bottom: 12px;
`;
