import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  padding: 0 6px;
`;

export const Heading = styled.h1`
  font-family: tondo;
  font-weight: bold;
  color: #333333;
  white-space: nowrap;
  flex: 1;

  @media (min-width: 1024px) {
    font-size: 1.25em;
  }
`;

export const Controls = styled.div`
`;
